import React from 'react';
import './index.css';
import { NormalText, Spacer, Title } from '../common';
import { IonIcon } from '@ionic/react';
import { chevronDownOutline, closeOutline } from 'ionicons/icons';

export default class BottomAlert extends React.Component {
  constructor(props) {
    super(props);
  }

  closeAlert(e) {
    const { onClose } = this.props;
    e.stopPropagation();
    if (onClose) {
      onClose();
    }
  }

  render() {
    const {
      children,
      className,
      title,
      description,
      smallTitle,
      showPullDownArrow,
      showXButton
    } = this.props;

    return (
      <div
        className={`bottom-alert ${className || ''}`}
        onClick={(e) => this.closeAlert(e)}
      >
        <div className={'bottom-alert-content'} onClick={(e) => e.stopPropagation()}>
          {showPullDownArrow ? (
            <IonIcon className='close-alert-button' size='large' icon={chevronDownOutline} onClick={(e) => this.closeAlert(e)} />
          ) : null}
          {showXButton ? (
            <IonIcon className='close-alert-button' size='large' icon={closeOutline} onClick={(e) => this.closeAlert(e)} />
          ) : null}
          {title ? (
            <div>
              <Title>{title}</Title>
            </div>
          ) : null}
          {smallTitle ? (
            <div>
              <NormalText className='bold'>{smallTitle}</NormalText>
            </div>
          ) : null}
          {title || smallTitle ? (
            <Spacer size={1} />
          ) : null}

          {description ? (
            <>
              <NormalText>{description}</NormalText>
              <Spacer size={1} />
            </>
          ) : null}
          {children || <p>No content</p>}
        </div>
      </div>
    );
  }
}
