import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { IonAlert, IonButton, IonCheckbox, IonItem, IonList } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { checkSubscriptionPin, getSubscriptions } from '../../store/subscription/actions';
import { setSelectedSubscription, setSubscriptionReferCode } from '../../store/orders/actions';
import { forwardTo } from '../../lib/utils';
import { NormalText, Spacer, Subtitle, Title } from '../../components/common';
import { getConfig } from '../../appConfig';
import { checkReferCode, showToast } from '../../store/actions';
import Mobiscroll from '../../components/mobiscroll';
import { useDispatch } from "react-redux";
import { NUMBERS_OF_PEOPLE } from '../../store/constants';

const { SelectOption } = Mobiscroll;

const ChooseSubscriptionType = ({
  __,
  subscriptions,
  activeSubscription,
  getSubscriptions
}) => {
  useEffect(() => {
    getSubscriptions();
  }, []);
  const dispatch = useDispatch()
  const [isMultiPeopleMembership, setIsMultiPeopleMembership] = useState(false);
  const [showHowMenyPeople, setShowHowMenyPeople] = useState(false);
  const [numberOfPeople, setNumberOfPeople] = useState(null);
  const [showEnterPinAlert, setShowEnterPinAlert] = useState(false);
  const [pinIsValidAlert, setPinIsValidAlert] = useState(false);
  const [pinIsInvalidAlert, setPinIsInvalidAlert] = useState(false);
  if (activeSubscription && subscriptions.find(subscription => subscription.id === activeSubscription.id)) {
    forwardTo('/my-membership');
  }

  const formatDataForSelect = ( subscriptions = [] ) => {
    let numberOfPeopleItems = [];
    subscriptions.forEach((subscription,index)=>{
      const numberOfUsers = subscription.number_of_users;
      if(
        subscription.is_multi_user_subscription && 
        numberOfUsers > 1 && 
        subscriptions.indexOf(subscription) === index
      ){
        numberOfPeopleItems.push(
          {
            text: `${numberOfUsers} ${__('People')}`,
            value: numberOfUsers,
            disabled: false,
            html: `<span class=''>${numberOfUsers} ${__('People')}</span>`
          }
        );
      }
    });

    return numberOfPeopleItems.sort((a, b) => a.value - b.value);;
  }

  const submitPin = (pin) =>{
    if( pin !== '' ){
      setPinIsValidAlert(false)
      dispatch( checkSubscriptionPin(pin, showPinStatusAlert) );
    }else{
      dispatch(showToast(__('Please enter valid Subscription PIN!'), 'warning'))
    }
  }

  const showPinStatusAlert = (status) => {
    if(status){
      setPinIsValidAlert(true);
    }else{
      setPinIsInvalidAlert(true);
    }
  }
  const dataForSelect = formatDataForSelect(subscriptions) || [];
  return (
    <Loading transparent>
      <Layout color='transparent' scrollY={false} headerTitle={__('MembershipChooser')} hideSecondToolbar={true} showHamburger={!showHowMenyPeople}>
        <div className='absolute-content scrollable-y membership-chooser'>
          {!showHowMenyPeople ?
            (
              <>
                <div>
                  <div  className="centered">
                    <div className='coffee-cup-image'></div>
                    <Spacer size={1}/>
                    <Title className='title'>{__('Subscriptions')}</Title>
                    <NormalText >
                      {__('Which type subscription would you')}
                    </NormalText>
                  </div>
                  <div className='choose-subscription-type-list'>
                    <IonList>
                      <Spacer size={1}/>
                      <IonItem lines="none">
                        <IonCheckbox color="primary" slot="start" checked={!isMultiPeopleMembership} onClick={() => setIsMultiPeopleMembership(false)} />
                        <div className='subscription-information'>
                          <Subtitle>{__('Individual subscriptions')}</Subtitle>
                        </div>
                      </IonItem>
                      <Spacer size={1}/>
                      <IonItem lines="none">
                        <IonCheckbox color="primary" slot="start" checked={isMultiPeopleMembership} onClick={() => setIsMultiPeopleMembership(true)} />
                        <div className='subscription-information'>
                          <Subtitle>{__('Multi People Subscription')}</Subtitle>
                        </div>
                      </IonItem>
                      <Spacer size={1}/>
                      
                        <IonButton
                          expand="block"
                          fill="clear"
                          color="secondary"
                          className="link underlined centered"
                          onClick={() =>
                            setShowEnterPinAlert(true)
                          }
                        >
                        <Subtitle>{__('Or enter share Pin')}</Subtitle>
                      </IonButton>
                      
                    </IonList>

                  </div>
                </div>
                <div>
                  <IonButton
                    onClick={() => {
                      if(isMultiPeopleMembership){
                        setShowHowMenyPeople(true);
                      }else{
                        dispatch({ type: NUMBERS_OF_PEOPLE, value: 1 });
                        forwardTo('/membership');
                      }
                    }}
                    expand="block"
                    color='primary'
                    className={'checkout-btn'}
                  >
                    {__('Continue')}
                  </IonButton>
                </div>

              </>
            ) : (
              <>
                <div>
                  <div  className="centered">
                    <div className='users-image'></div>
                    <Spacer size={1}/>
                    <Title className='title'>{__('Subscriptions')}</Title>
                    <NormalText >
                      {__('Which type subscription would you')}
                    </NormalText>
                  </div>
                  <Spacer size={1}/>
                  <SelectOption
                    display="inline"
                    onSet={(e, inst) => {
                      if (inst && inst.getVal()) {
                        setNumberOfPeople(inst.getVal());
                      } else {
                        setNumberOfPeople(dataForSelect[0]?.value);
                      }
                    }}
                    onInit={() =>{ 
                      if(!numberOfPeople){
                        setNumberOfPeople(dataForSelect[0]?.value);
                      }            
                    }}
                    value={dataForSelect[0]?.value}
                    data={dataForSelect}
                    label="Location"
                    inputStyle="box"
                    setText={__('OK')}
                    cancelText={__('Cancel')}
                  />
                </div>
                <IonButton
                  onClick={() => {
                    dispatch({ type: NUMBERS_OF_PEOPLE, value: numberOfPeople });
                    forwardTo('/membership');
                  }}
                  expand="block"
                  color='primary'
                  className={'checkout-btn uppercase'}
                >
                  {__('Continue')}
                </IonButton>
              </>
            )
          }
        </div>
        <IonAlert
          isOpen={showEnterPinAlert}
          onDidDismiss={() => setShowEnterPinAlert(false)}
          header={__('Enter Subscription PIN')}
          message={__('If you have been invited to join a shared subscription, enter the PIN below:')}
          cssClass= 'enter-pin-alert'
          inputs={[
            {
              placeholder: 'Enter PIN'
            }
          ]}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary'
            },
            {
              text: __('Submit'),
              role: 'submit',
              cssClass: 'secondary',
              disabled: true,
              handler: (pin) =>{
                submitPin( pin['0'] || '' );
              }
            },
          ]}
        />
        <IonAlert
          isOpen={pinIsValidAlert}
          header={__('Success')}
          message={__('Your PIN has been accepted. You will find your shared vouchers in your voucher wallet.')}
          buttons={[
            {
              text: __('Take me there'),
              role: 'submit',
              cssClass: 'secondary',
              handler: () => {
                forwardTo('/vouchers')
              }
            },
          ]}
        />
        <IonAlert
          isOpen={pinIsInvalidAlert}
          onDidDismiss={() => setPinIsInvalidAlert(false)}
          header={__('PIN Error')}
          message={__('The PIN is not recognised or your account in not authorised to use it.')}
          buttons={[
            {
              text: __('OK'),
              role: 'cancel',
              cssClass: 'secondary'
            }
          ]}
        />
      </Layout>
    </Loading>
  );
};

const mapStateToProps = (store) => ({
  subscriptions: store.subscription.subscriptions,
  selectedSubscription: store.orders.selectedSubscription,
  activeSubscription: store.profile.profile.active_subscription,
  profile: store.profile.profile
});

const mapDispatchToProps = {
  setSelectedSubscription,
  getSubscriptions,
  checkReferCode,
  setSubscriptionReferCode
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(ChooseSubscriptionType));