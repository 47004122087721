import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NormalText, Spacer, Subtitle, Title } from '../../components/common';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import NoData from '../../components/noData';
import { IonButton, IonCheckbox, IonLabel, IonItem, IonIcon } from '@ionic/react';
import { checkmarkCircle } from 'ionicons/icons';
import {  createSubscription, createSubscriptionNative } from '../../store/subscription/actions';
import { Capacitor } from '@capacitor/core';
import './index.css';
import { forwardTo } from '../../lib/utils';

const isWeb = () => Capacitor.getPlatform() === 'web';
class TermsSubsciptionPackage extends Component {
  constructor(props) {
    super(props);
    this.state = { checked: false };
  }

  handleAcceptTerms = () => this.setState({ checked: !this.state.checked });

  handleContinue = () => {
    const subscriptionIdx = this.props.selectedSubscription?.idx;
    if (subscriptionIdx < 0 || subscriptionIdx > this.props.subscriptions) {
      this.props.showToast(this.props.__('Error cannot find membership'));
      return;
    }
    const numberOfPeople = this.props.selectedSubscription.numberOfPeople || 1;
    const homeLocationId = this.props.selectedSubscription.homeLocationId || 0;
    if(!isWeb()){
      this.props.createSubscriptionNative({
        id: this.props.selectedSubscription.id,
        existingCardToken: this.state.selectedCard,
        referCode: this.props.subscriptionReferCode,
        numberOfPeople,
        homeLocationId
      });
    }else {
      this.props.createSubscription({
        id: this.props.selectedSubscription.id,
        existingCardToken: this.state.selectedCard,
        referCode: this.props.subscriptionReferCode,
        numberOfPeople,
        homeLocationId
      });
    }
    // forwardTo('/checkout-membership');
  };

  backHandler = () => {
      forwardTo('/membership',{fromTerms: true});
  };

  render() {
    const { __, subscription, subscriptionReferCode } = this.props;
    const terms = subscription ? subscription.terms : null;
    const subscriptionReferPoints = subscription?.subscriber_referral_points
    return (
      <Loading>
        <Layout backHandler={this.backHandler} hideSecondToolbar color="transparent" headerTitle={__('Terms & Conditions')}>
          <div className="absolute-content flex-row-wrapper">
            <div className="scrollable-y">
              {
                subscriptionReferCode && 
                <>
                  <div className='success-background' >
                    <IonIcon slot="icon-only" icon={checkmarkCircle} color='success' />
                    <div className='reward-spacer'></div>
                    <div>
                      <Subtitle>
                        {__('Referral Code Accepted')}
                      </Subtitle>
                      <NormalText>{__('You will receive a bonus of')}</NormalText>
                      <NormalText>{` ${subscriptionReferPoints} `}</NormalText>
                      <NormalText>{__('loyalty points after becoming a subscriber')}</NormalText>
                    </div>
                  </div>
                  <Spacer size={1} />
                </>
              }
              <Title>{__('Accept terms')}</Title>
              <Spacer size={1} />
              {terms ? (
                <div className="terms-wrapper" dangerouslySetInnerHTML={{ __html: terms }} />
              ) : (
                <NoData />
              )}
            </div>
            <div className="flex-min">
              <IonItem lines="none">
                <IonCheckbox
                  className='subscription-terms-checkbox'
                  slot="start"
                  checked={this.state.checked}
                  onClick={this.handleAcceptTerms}
                />
                <IonLabel className="strong-text">{__('I accept the terms & conditions')}</IonLabel>
              </IonItem>
              <IonButton
                color="secondary"
                disabled={!this.state.checked}
                onClick={this.handleContinue}
                expand="block"
                className={'checkout-btn'}
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  return {
    subscription: state.subscription.subscriptions[state.orders.selectedSubscription?.idx],
    subscriptionReferCode : state.orders?.subscriptionReferCode,
    clientSecret: state.orders.clientSecret,
    selectedSubscription: state.orders.selectedSubscription,
    subscriptions: state.subscription.subscriptions
  };
};
const dispatchToProps = {
  createSubscription,
  createSubscriptionNative
};
export default connect(stateToProps, dispatchToProps)(withTranslation(TermsSubsciptionPackage));

