import React from 'react';
import { withRouter } from 'react-router';
import { IonModal, IonItem, IonButton, IonIcon } from '@ionic/react';
import { connect } from 'react-redux';
import { withTranslation } from '../../lib/translate';
import { validateProfileData } from '../../lib/utils';
import { NormalText, Spacer, Subtitle, Title } from '../common';
import { setModal, updateProfile, validateEmail } from '../../store/actions';
import ValidateInput from '../validateInput';
import Layout from '../layout';
import './index.css';

class ValidateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: this.props.profile.first_name || '',
      last_name: this.props.profile.last_name || '',
      email: this.props.profile.email || '',
      birthday: this.props.profile.birthday ? this.props.profile.birthday : '',
      mobile: this.props.profile.mobile ? this.props.profile.mobile : '',
      // email_verified: false
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.first_name !== prevProps.profile.first_name) {
      this.setState({ first_name: this.props.profile.first_name });
    }
    if (this.props.profile.last_name !== prevProps.profile.last_name) {
      this.setState({ last_name: this.props.profile.last_name });
    }
    if (this.props.profile.email !== prevProps.profile.email) {
      this.setState({ email: this.props.profile.email });
    }
    if (this.props.profile.mobile !== prevProps.profile.mobile) {
      this.setState({ mobile: this.props.profile.mobile });
    }
    if (this.props.profile.birthday !== prevProps.profile.birthday) {
      this.setState({ birthday: this.props.profile.birthday ? this.props.profile.birthday : '' });
    }
    if (this.props.profile.email_verified !== prevProps.profile.email_verified) {
      this.setState({ email_verified: this.props.profile.email_verified });
    }
  }

  handleSave() {
    const { dispatch } = this.props;
    const { first_name, last_name, birthday, email, mobile, email_verified } = this.state;
    const profile = {
      first_name: first_name,
      last_name: last_name,
      birthday: birthday,
      email: email,
      mobile: mobile,
      email_verified: email_verified,
    };
    dispatch(updateProfile(profile, true));
    dispatch(setModal('isVerfiedModalOpen', false));
  }

  handleInput = (key, val) => {
    this.setState({ [key]: val });
  };

  handleValidateEmail = () => {
    const { dispatch } = this.props;
    const { first_name, last_name, birthday, email, mobile, email_verified } = this.state;
    const profile = {
      first_name: first_name,
      last_name: last_name,
      birthday: birthday,
      email: email,
      mobile: mobile,
      email_verified: email_verified,
    };
    dispatch(validateEmail(profile));
  };
  unfocusInputs = () => {
    document.querySelectorAll('.validate-modal ion-item').forEach(el => el.classList.remove('item-has-focus'))
  }
  render() {
    const { __, isVerfiedModalOpen, dispatch, profile } = this.props;
    const { first_name, last_name, birthday, email, mobile, email_verified } = this.state;
    const dateFormat = 'dd/mm/yy';
    const validatedData = validateProfileData({ ...profile, ...this.state });
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    return (

      <IonModal
        cssClass="validate-modal"
        isOpen={isVerfiedModalOpen}
        onDidDismiss={() => dispatch(setModal('isVerfiedModalOpen', false))}
      >
        <Layout
          color='transparent'
          headerTitle="Contact Details"
          hideSecondToolbar={true}
          showHamburger={false}
          backHandler={() => dispatch(setModal('isVerfiedModalOpen', false))}
        >
          <div className='validate-account-title'>
            <Title className="uppercase">{__('Your Account')}</Title>
            <Subtitle  >
              {__('To verify your account, complete the highlighted details below:')}
            </Subtitle>
          </div>
          <Spacer size={2} />
          <div>
            <div className="input-field-container">
              <NormalText>{__('First name')}</NormalText>
              <IonItem lines="none" className="input-field-wrapper">
                <ValidateInput
                  type="text"
                  onIonChange={(e) => this.handleInput('first_name', e.target.value)}
                  name="first_name"
                  validatedData={validatedData}
                  value={first_name}
                  placeholder={__('Enter your first name')}
                />
              </IonItem>
            </div>
            <div className="input-field-container">
              <NormalText>{__('Last name')}</NormalText>
              <IonItem lines="none" className="input-field-wrapper">
                <ValidateInput
                  type="text"
                  onIonChange={(e) => this.handleInput('last_name', e.target.value)}
                  name="last_name"
                  validatedData={validatedData}
                  value={last_name}
                  placeholder={__('Enter your last name')}
                />
              </IonItem>
            </div>
            {/* <div className="input-field-container">
              <NormalText>{__('Date of Birth')}</NormalText>
              <IonItem lines="none" className={`input-field-wrapper ${(profile.birthday !== null && profile.birthday !== '') ? 'disabled-wrapper' : ''}`} onClick={() => this.unfocusInputs()}>
                <ValidateInput
                  type="date"
                  onIonChange={(e) => this.handleInput('birthday', e.target.value)}
                  name="birthday"
                  dateFormat={dateFormat}
                  validatedData={validatedData}
                  value={birthday}
                  max={yesterday}
                />
              </IonItem>
            </div> */}
            <div className="input-field-container">
              <NormalText>{__('Email address')}</NormalText>
              <IonItem lines="none" className="input-field-wrapper">
                <ValidateInput
                  type="email"
                  onIonChange={(e) => this.handleInput('email', e.target.value)}
                  name="email"
                  validatedData={validatedData}
                  value={email}
                  color={!validatedData.email_verified ? 'danger' : ''}
                  placeholder={__('Enter your email address')}
                />
              </IonItem>
            </div>
            {!validatedData.email_verified ? (
              <NormalText
                className='danger-color underlined small-text clickable'
                onClick={() => this.handleValidateEmail()}
              >
                {__('Resend Validation Email')}
              </NormalText>
            ) : null}

          </div>
          <IonButton
            expand="block"
            color="primary"
            className="bottom-button"
            onClick={() => this.handleSave()}
          >
            {__('Save Changes')}
          </IonButton>
        </Layout>
        
      </IonModal>

    );
  }
}

const stateToProps = (store) => {
  const { isVerfiedModalOpen, profile } = store.profile;
  return {
    isVerfiedModalOpen,
    profile,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(ValidateModal)));
