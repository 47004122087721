import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonBadge,
  IonButton,
  IonIcon,
} from '@ionic/react';
import Layout from '../../components/layout';
import PromoBar from '../../components/promoBar';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { isDefined, isEmptyObject, forwardTo, getSingleDeliveryOption, changeStatusBarStyle } from '../../lib/utils';
import { getConfig } from '../../appConfig';
import { BigTitle, NormalText, Spacer, Title } from '../../components/common';
import dashboardImage from '../../assets/images/logo-color.svg'
import { getSubscriptions, setDeliveryOption } from '../../store/actions';
import Loading from '../../components/spinner';
import infoBlack from '../../assets/images/info-black.svg'
const { hasOrdering, hasLoyalty, hasCampaignManager } = getConfig().appType;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleDelivery: getSingleDeliveryOption(),
    };
  }

  async componentDidMount() {
    const { profile } = this.props;
    if (isDefined(profile) && !isEmptyObject(profile)) {
      this.props.dispatch(getSubscriptions());
    }
    await changeStatusBarStyle(true);
  }

  async componentWillUnmount(){
    await changeStatusBarStyle(false);
  }

  setDeliveryOption = (option) => {
    this.props.dispatch(setDeliveryOption(option));
    forwardTo(option.route);
  };

  render() {
    const { __, screenName, vouchers, subscriptions, activeSubscription, orderHistory, profile } = this.props;
    const { singleDelivery } = this.state;
    const delivery = isDefined(getConfig().delivery) ? getConfig().delivery : [];
    const membershipLocationId = getConfig()?.membership?.membershipLocationId;
    const filteredOrderHystory = (orderHistory || []).filter((order) => order?.restaurant_id != membershipLocationId);
    let voucherRes = (vouchers || []).filter((data) => {
      if (data.type === 1) {
        return data;
      }
      return false;
    });
    return (
      <Loading>
        <Layout hideSecondToolbar={true} color="black">
          <div className="absolute-content">
            <Spacer size={2} />
            <div className="flex-min">
              <BigTitle className='white-text' color='white'>{screenName}</BigTitle>
            </div>
            <Spacer size={2} />
            <div>
              {hasOrdering && !isEmptyObject(profile) ?
                delivery.map((d, index) => (
                  <div>
                    {!d.isRemoved && (
                      <IonButton
                        className='delivery-button'
                        key={`option${index}`}
                        onClick={() => this.setDeliveryOption(d)}
                        color='gray'
                      >
                        {__(d.label)}
                      </IonButton>
                    )}
                  </div>
                )
                ) : hasOrdering ? (
                  <div >
                    <IonButton
                      className='delivery-button'
                      onClick={() => forwardTo('/delivery-options')}
                      color='gray'
                    >
                      {__('Start new order')}
                    </IonButton>
                    <div className='flex-col-wrapper'>
                      <IonButton
                        className='delivery-button'
                        onClick={() => forwardTo('/login')}
                        color='white'
                      >
                        {__('Login')}
                      </IonButton>
                      <IonButton
                        className='delivery-button'
                        onClick={() => forwardTo('/register')}
                        color='gray'
                      >
                        {__('Register')}
                      </IonButton>
                    </div>

                  </div>
                ) : null}
              {hasCampaignManager && voucherRes && voucherRes.length > 0 ? (
                <IonButton
                  className='delivery-button ion-text-wrap'
                  onClick={() => forwardTo('/vouchers', { openVoucherModal: vouchers[0].id })}
                  color='white'
                >
                  <IonIcon size='large' slot="start" icon={infoBlack}></IonIcon>
                  {`${__('New Voucher:')} ${voucherRes[0].reward.small_print}`}
                </IonButton>
              ) : null}
              {hasOrdering && !isEmptyObject(profile) && filteredOrderHystory && filteredOrderHystory.length > 0 ? (
                <IonButton
                  className='delivery-button ion-text-wrap'
                  onClick={() => forwardTo('/history', { showOrders: true })}
                  color='white'
                >
                  <IonIcon size='large' slot="start" icon={infoBlack}></IonIcon>
                  {`${__('Reorder A Previous Order')}`}
                </IonButton>
              ) : null}
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { profile, vouchers } = state.profile;
  let screenName = 'Welcome';
  let subscriptions = state.subscription.subscriptions;
  let activeSubscription = state.profile.profile.active_subscription;
  if (
    (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) ||
    profile.last_name ||
    (profile.first_name && profile.last_name)
  ) {
    screenName = `Hey ${profile.first_name}`;
  }
  return {
    screenName,
    vouchers: vouchers || [],
    subscriptions: subscriptions || [],
    activeSubscription: activeSubscription,
    profile,
    orderHistory: state.orders.orderHistory || [],
  };
};

export default connect(stateToProps)(withTranslation(Dashboard));
