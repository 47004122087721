import axios from 'axios';
import { isDefined } from './utils';
import { getConfig } from '../appConfig';
import packageInfo from '../../package.json';

let axiosInstance;

const baseURL = getConfig().api.baseURL;
const wordPressBaseURL = getConfig().api.wordPressBaseURL;
const nativeWallet = getConfig().api.nativeWallet;
const addToGoogleWallet = `${nativeWallet}/google-wallet`;
const addToAppleWallet = `${nativeWallet}/apple-wallet`;
const endpoints = {
  login: '/api-token-auth', //post
  register: '/me/register', //post
  getProfile: '/me', //get
  changePassword: '/me', //put
  changeProfile: '/me', //put
  resetPassword: '/me/reset_password', //put
  deleteCard: '/delete_payment_card', //put
  sendFeedback: '/feedback/create', //post,
  firebaseToken: '/firebase-token', //post,
  appVersion: '/appversion', //get,
  social: '/social', //get,
  restaurants: '/restaurants/all', //get
  terms: '/terms', //get,
  privacyPolicy: '/privacy', //get
  faq: '/faq', //get
  rewards: '/reward_milestones', //get
  history: '/transactions/history', //get
  ikentooMenu: '/ikentoo_menu/', //get
  ikentooMenusForLocation: '/ikentoo_menus/', //get
  getPaymentCards: '/orders/list_payment_cards', //post
  addPaymentCard: '/orders/create_payment_card', //post
  removePaymentCard: '/orders/delete_payment_card', //post
  createOrder: '/order/create', //post
  orderHistory: '/me/orders', //get
  validateEmail: '/me/validate_email', //get
  sendVoucherCode: '/voucher/code', //put,
  referFriend: '/refer_friend', //post
  checkReferCode: '/check_refer_code', //post
  getVouchers: '/me/vouchers', //get
  publicStripeKey: '/public_stripe_key', //get
  frontEndAppConfig: '/front_end_app_config', //get
  getTranslations: '/translation_json', //get
  addDeliveryAdress: '/me/add_new_address', //put
  getRestaurantSnoozeData: '/ikentoo_menu/get_snooze_data', //get
  postCodeCheck: '/order/postcode_check', //post
  getNearestLocation: '/order/get_nearest_restaurant_location', //put
  getDeliveryRangeType: '/get_delivery_range_type', //get
  locationCodeCheck: '/order/location_code_check', // post
  addPickupPoint: '/me/add_pickup_point', // put,
  getOrderProduction: '/order_production_length',
  removeDeliveryAddress: '/orders/remove_delivery_address', //post
  cancelOrder: '/orders/cancel_order', // post
  getDefaultMenuId: '/get_default_menu_id', //get
  getDefaultMenu: '/get_default_menu', //get
  saveJudoPayCard: '/orders/save_judo_pay_cards', //post
  checkDiscount: '/order/check_discount', //post
  getLastOrder: '/orders/get_last_order', // get
  paymentInfo: '/update_payment_info',
  subscriptionPackages: '/subscription/packages', // get
  createSubscription: '/subscription/create_stripe_subscription', // post
  createSubscriptionNative: '/subscription/create_stripe_subscriptio_native', // post
  cancelSubscription: '/subscription/cancel', // put
  payMembership: '/subscription/memberships', // post
  removeProfile: '/me/delete_user', // post
  checkSubscriptionPin: '/subscription/check_subscription_pin', //post
  inviteUserToSubscription: '/subscription/invite_user_to_subscription', //post
  resendSubscriptionInviteEmail: '/subscription/resend_subscription_invite_email',
  createStripeOrder: '/order/create_stripe_order', //post
  updateStripeOrder: '/order/update_stripe_order', //post
  createStripeOrderNative: '/order/create_stripe_order_native',
  confirmPaymentIntentNative: '/order/confirm_payment_intent_native',
  confirmTrialSubscription: '/subscription/confirm_trial_subscription', // post
};

const createAxiosInstance = (token) => {
  const headers = isDefined(token)
    ? {
      Authorization: 'JWT ' + token,
    }
    : {};
  axiosInstance = axios.create({
    headers,
    timeout: 50000,
  });
  return axiosInstance;
};

export const isFieldValid = (fieldName, errors = {}) => !isDefined(errors[fieldName]);

export const getErrorMessage = (fieldName, errors = {}) =>
  !isFieldValid(fieldName, errors) ? errors[fieldName] : '';

const api = {
  login: async (username, password) => {
    const response = await createAxiosInstance().post(baseURL + endpoints.login, {
      username,
      password,
      version: packageInfo.version,
    });
    axiosInstance = createAxiosInstance(response.data.token);
    return response;
  },
  register: async (userData) => {
    const response = await createAxiosInstance().post(baseURL + endpoints.register, {...userData, version: packageInfo.version});
    axiosInstance = createAxiosInstance(response.data.token);
    return response;
  },
  logout: () => {
    // eslint-disable-next-line no-console
    console.log('logout');
  },
  resetPassword: async (email) =>
    await axiosInstance.put(baseURL + endpoints.resetPassword, { email, version: packageInfo.version, }),
  getProfile: async () => await axiosInstance.get(baseURL + endpoints.getProfile + `?version=${packageInfo.version}`),
  updateProfile: async (data = {}) =>
    await axiosInstance.put(baseURL + endpoints.changeProfile, { ...data, version: packageInfo.version,}),
  sendFirebaseToken: async (data) =>
    await axiosInstance.post(baseURL + endpoints.firebaseToken, { ...data, version: packageInfo.version,}),
  getAppVersion: () =>
    axiosInstance.get(baseURL + endpoints.appVersion + `?version=${packageInfo.version}`).then((res) => res.data.data?.min_front_end_version),
  getSocialLinks: () =>
    axiosInstance.get(baseURL + endpoints.social + `?version=${packageInfo.version}`).then((res) => res.data.social_link_json),
  sendFeedback: async (data = {}) =>
    await axiosInstance.post(baseURL + endpoints.sendFeedback, { ...data, version: packageInfo.version,}),
  getRestaurants: () =>
    axiosInstance.get(baseURL + endpoints.restaurants + `?version=${packageInfo.version}`).then((res) => res.data.data),
  getTerms: (locale = 'en') =>
    axios
      .create()
      .get(wordPressBaseURL + '/' + locale + '/terms_conditions/' + getConfig().envs.APP_DOMAIN + `?version=${packageInfo.version}`, {
        responseType: 'text',
      })
      .then((res) => res.data),
  getPrivacyPolicy: (locale = 'en') =>
    axios
      .create()
      .get(wordPressBaseURL + '/' + locale + '/privacy/' + getConfig().envs.APP_DOMAIN + `?version=${packageInfo.version}`, {
        responseType: 'text',
      })
      .then((res) => res.data),
  getFaq: (locale = 'en') =>
    axios
      .create()
      .get(wordPressBaseURL + '/' + locale + '/faqs/' + getConfig().envs.APP_DOMAIN + `?version=${packageInfo.version}`, {
        responseType: 'text',
      })
      .then((res) => res.data),
  getRewards: () => axiosInstance.get(baseURL + endpoints.rewards + `?version=${packageInfo.version}`).then((res) => res.data.data),
  getIkenooMenu: (menuId, businessLocationId) =>
    axiosInstance
      .get(baseURL + endpoints.ikentooMenu + menuId + '/location/' + businessLocationId + `?version=${packageInfo.version}`)
      .then((res) => res.data.data),
  getIkentooMenusForLocation: (businessLocationId) =>
    axiosInstance
      .get(baseURL + endpoints.ikentooMenusForLocation + 'location/' + businessLocationId + `?version=${packageInfo.version}`)
      .then((res) => res.data.data),
  getHistory: () => axiosInstance.get(baseURL + endpoints.history + `?version=${packageInfo.version}`).then((res) => res.data),
  getPaymentCards: () =>
    axiosInstance.post(baseURL + endpoints.getPaymentCards, { version: packageInfo.version }).then((res) => res.data.data),
  addPaymentCard: (cardData) =>
    axiosInstance.post(baseURL + endpoints.addPaymentCard , { ...cardData, version: packageInfo.version }).then((res) => res.data.data),
  removePaymentCard: (cardData) =>
    axiosInstance.post(baseURL + endpoints.removePaymentCard, { ...cardData, version: packageInfo.version }).then((res) => res.data),
  createOrder: (orderData) =>
    axiosInstance.post(baseURL + endpoints.createOrder, { ...orderData, version: packageInfo.version }).then((res) => res.data),
  getOrderHistory: () =>
    axiosInstance.get(baseURL + endpoints.orderHistory + `?version=${packageInfo.version}`).then((res) => res.data),
  sendCode: async (data) =>
    await axiosInstance.put(baseURL + endpoints.sendVoucherCode, { ...data, version: packageInfo.version,}).then((res) => res.data),
  sendRefer: async (data) =>
    await axiosInstance.post(baseURL + endpoints.referFriend, { ...data, version: packageInfo.version,}).then((res) => res.data),
  checkReferCode: async (data) =>
    await axiosInstance.post(baseURL + endpoints.checkReferCode, { ...data, version: packageInfo.version,}).then((res) => res.data),
  getVouchers: async () =>
    await axiosInstance.get(baseURL + endpoints.getVouchers + `?version=${packageInfo.version}`).then((res) => res.data),
  validateEmail: async () => await axiosInstance.get(baseURL + endpoints.validateEmail + `?version=${packageInfo.version}`),
  getPublicStripeKey: () =>
    axiosInstance
      .get(baseURL + endpoints.publicStripeKey + `?version=${packageInfo.version}`)
      .then((res) => res.data.data.public_stripe_key),
  getFrontEndAppConfig: () =>
    axiosInstance
      .get(baseURL + endpoints.frontEndAppConfig + `?version=${packageInfo.version}`)
      .then((res) => res.data.data.front_end_app_config),
  // getTranslations: () => axiosInstance.get(baseURL + endpoints.getTranslations).then(res => res.data.data.translation_json),
  getTranslations: () =>
    axiosInstance
      .get(`${wordPressBaseURL}/en/dictionary/${getConfig().envs.APP_DOMAIN}` + `?version=${packageInfo.version}`)
      .then((res) => res.data),
  addDeliveryAdress: (data) => axiosInstance.put(baseURL + endpoints.addDeliveryAdress, { ...data, version: packageInfo.version,}),
  getRestaurantSnoozeData: () =>
    axiosInstance.get(baseURL + endpoints.getRestaurantSnoozeData + `?version=${packageInfo.version}`).then((res) => res.data.data),
  postCodeCheck: (data) => axiosInstance.post(baseURL + endpoints.postCodeCheck, { ...data, version: packageInfo.version,}),
  getNearestLocation: (data) => axiosInstance.put(baseURL + endpoints.getNearestLocation, { ...data, version: packageInfo.version,}),
  getDeliveryRangeType: () =>
    axiosInstance
      .get(baseURL + endpoints.getDeliveryRangeType + `?version=${packageInfo.version}`)
      .then((res) => res.data.data.delivery_range_type),
  locationCodeCheck: (data) => axiosInstance.post(baseURL + endpoints.locationCodeCheck, { ...data, version: packageInfo.version,}),
  addPickupPoint: (data) => axiosInstance.put(baseURL + endpoints.addPickupPoint, { ...data, version: packageInfo.version,}),
  getOrderProduction: () =>
    axiosInstance
      .get(baseURL + endpoints.getOrderProduction + `?version=${packageInfo.version}`)
      .then((res) => res.data.data.order_production_mins),
  removeDeliveryAddress: (postalCode) =>
    axiosInstance
      .post(baseURL + endpoints.removeDeliveryAddress, { ...postalCode, version: packageInfo.version })
      .then((res) => res.data),
  cancelOrder: (data) =>
    axiosInstance.post(baseURL + endpoints.cancelOrder, { ...data, version: packageInfo.version,}).then((res) => res.data.data),
  getDefaultMenuId: () =>
    axiosInstance
      .get(baseURL + endpoints.getDefaultMenuId + `?version=${packageInfo.version}`)
      .then((res) => res.data.data.default_menu_id),
  getDefaultMenu: (menuId) =>
    axiosInstance
      .get(baseURL + endpoints.getDefaultMenu + '/' + menuId + `?version=${packageInfo.version}`)
      .then((res) => res.data.data),
  saveJudoPayCard: (cardData) =>
    axiosInstance.post(baseURL + endpoints.saveJudoPayCard, { ...cardData, version: packageInfo.version }).then((res) => res.data.data),
  checkDiscount: async (data) => await axiosInstance.post(baseURL + endpoints.checkDiscount, { ...data, version: packageInfo.version,}),
  getLastOrder: (id) =>
    axiosInstance.get(baseURL + endpoints.getLastOrder + '/' + id + `?version=${packageInfo.version}`).then((res) => res.data.data),
  updatePaymentInfo: async (paymentInfo = {}) =>
    await axiosInstance
      .post(baseURL + endpoints.paymentInfo, { ...paymentInfo, version: packageInfo.version })
      .then((res) => res.data.data),
  getSubscriptions: async () => await axiosInstance.get(baseURL + endpoints.subscriptionPackages + `?version=${packageInfo.version}`),
  createSubscription: async (data) => await axiosInstance.post(baseURL + endpoints.createSubscription, { ...data, version: packageInfo.version,}),
  createSubscriptionNative: async (data) => await axiosInstance.post(baseURL + endpoints.createSubscriptionNative, { ...data, version: packageInfo.version,}), 
  cancelUserSubscription: (data) => axiosInstance.put(baseURL + endpoints.cancelSubscription, { ...data, version: packageInfo.version,}),
  payMembership: (membership) => axiosInstance.post(baseURL + endpoints.payMembership, { ...membership, version: packageInfo.version }),
  checkSubscriptionPin: (data) => axiosInstance.post(baseURL + endpoints.checkSubscriptionPin, { ...data, version: packageInfo.version,}),
  inviteUserToSubscription: (data) => axiosInstance.post(baseURL + endpoints.inviteUserToSubscription, { ...data, version: packageInfo.version,}),
  resendSubscriptionInviteEmail: (data) => axiosInstance.post(baseURL + endpoints.resendSubscriptionInviteEmail, { ...data, version: packageInfo.version,}),
  removeProfile: async (data) => await axiosInstance.post(baseURL + endpoints.removeProfile, { ...data, version: packageInfo.version,}),
  createStripeOrder: async (orderData) => axiosInstance.post(baseURL + endpoints.createStripeOrder, { ...orderData, version: packageInfo.version }).then((res) => res.data),
  updateStripeOrder: async (orderId) => axiosInstance.post(baseURL + endpoints.updateStripeOrder, { orderId, version: packageInfo.version }).then((res) => res.data),
  createStripeOrderNative: async (orderData) =>
    axiosInstance
      .post(baseURL + endpoints.createStripeOrderNative, { ...orderData, version: packageInfo.version })
      .then((res) => res.data),
  confirmPaymentIntentNative: async (data) =>
    axiosInstance
      .post(baseURL + endpoints.confirmPaymentIntentNative, { ...data, version: packageInfo.version,})
      .then((res) => res.data),
  confirmTrialSubscription: async (data) => await axiosInstance.post(baseURL + endpoints.confirmTrialSubscription, { ...data, version: packageInfo.version } ),
  addToGoogleWallet: async (data) => await axiosInstance.post(addToGoogleWallet, { ...data, appversion: packageInfo.version,}).then((res) => res.data),
  addToAppleWallet: async (data) => await axiosInstance.post(addToAppleWallet , { ...data, appversion: packageInfo.version }, { responseType: 'blob' }).then((res) => res.data),
};

api.createAxiosInstance = createAxiosInstance;

export default { ...api };
