import React from 'react';
import { withRouter } from 'react-router';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonBadge,
  IonMenuToggle,
} from '@ionic/react';
import { scan, basket, arrowBack } from 'ionicons/icons';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import Icon from '../icon';
import { SmallText, Subtitle } from '../common';
import { forwardTo, getRouteClassName, isWebConfig, goBack } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { getConfig } from '../../appConfig';
import menuButton from '../../assets/images/menu-button.svg';
import './index.css';

const StaticHeader = ({ __, history ,backHandler, navConfig, hasLoyaltyPoints, profile, showHamburger, hideRightButton}) => {
  const currentPath = history.location.pathname;
  const config = getConfig();
  const authPages = config.general.authRoutes.indexOf(currentPath) !== -1;
  const pagesWithBackButton = config.general.routesWithBackButton.indexOf(currentPath) !== -1;
  const hideStaticHeader = config.theme.routesWithoutStaticHeader.indexOf(currentPath) !== -1;
  const routeClassName = getRouteClassName(currentPath,navConfig);
  const routesWithDarkBg = config.general.routesWithDarkBg.indexOf(currentPath) !== -1;
  
  return (
    <>
      {hideStaticHeader ? null : config.theme.showHeaderOnAuthRoutes || !authPages ? (
        <IonHeader className={'static ' + routeClassName}>
          <IonToolbar className={`primary-toolbar ${routesWithDarkBg ? 'toolbar-dark': ''}`}>
            <IonButtons slot="start">
              {(!authPages && !pagesWithBackButton) || showHamburger ? (
                !isWebConfig() ? (
                  <IonMenuToggle>
                    <IonIcon color={routesWithDarkBg ? 'white' : 'dark'} className="menu-button" icon={menuButton}/>
                  </IonMenuToggle>
                ) : null
              ) : (
                <IonButton className="header-back-button" onClick={() => backHandler ? backHandler() : goBack()}>
                  <Icon color={routesWithDarkBg ? 'white' : 'dark'} icon={arrowBack} size='large' />
                </IonButton>
              )}
            </IonButtons>
            <IonButtons color="primary">
              <IonButton
                className="image-button"
                color="primary-shade"
                onClick={() => forwardTo('/dashboard')}
              />
            </IonButtons>
            <IonButtons className={hideRightButton? "invisible" : ""} slot="end">
              {Basket.itemsCount() > 0 ? (
                <IonButton
                  disabled={Basket.itemsCount() > 0 ? false : true}
                  color={routesWithDarkBg ? 'white' : 'dark'}
                  className="basket-button"
                  onClick={() => forwardTo('/order-summary')}
                >
                  <div>
                    <div className="basket-icon-wrapper">
                      <IonBadge
                        slot="end"
                        color={routesWithDarkBg ? 'white' : 'dark'}
                        className={Basket.itemsCount() >= 10 ? 'badge' : 'badge-small'}
                      >
                        {Basket.itemsCountAll()}
                      </IonBadge>
                      <Icon icon={basket} color={routesWithDarkBg ? 'white' : 'dark'} classname="icon" />
                      
                    </div>
                    <SmallText color={routesWithDarkBg ? 'white' : 'primary'}>{Basket._getTotal()}</SmallText>
                  </div>
                </IonButton>
              ) : !authPages && !isWebConfig() ? (
                config.appType.hasLoyalty ? (
                  hasLoyaltyPoints && profile.available_balance  ?
                    <div onClick={() => forwardTo('/loyalty')} className='header-points'>
                      <Subtitle color={routesWithDarkBg ? 'white' : 'dark'} className='bold'>{profile.available_balance}</Subtitle>
                      <SmallText color={routesWithDarkBg ? 'white' : 'dark'}>{__('pts')}</SmallText>
                    </div>
                    :
                    <IonButton
                      color={routesWithDarkBg ? 'white' : 'dark'}
                      slot="end"
                      className="basket-button"
                      button
                      clear
                      onClick={() => forwardTo('/loyalty', { tab: 'scan' })}
                    >
                    <IonIcon color={routesWithDarkBg ? 'white' : 'dark'} slot="icon-only" icon={scan} />
                  </IonButton>
                ) : null
              ) : null}
            </IonButtons>
          </IonToolbar>
          <IonToolbar color="transparent" />
        </IonHeader>
      ) : null}
    </>
  );
};

const stateToProps = (store) => {
  const { orders } = store;
  const { navConfig} = store.common;
  const { profile } = store.profile
  return {
    basketUpdated: orders.basketUpdated,
    navConfig,
    profile
  };
};

export default connect(stateToProps)(withRouter(withTranslation(StaticHeader)));
