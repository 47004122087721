import React, { Component } from 'react';
import { IonList, IonListHeader, IonItem, IonLabel } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { isDefined, forwardTo } from '../../lib/utils';
import Basket from '../../lib/basket';
import { connect } from 'react-redux';
import NoData from '../../components/noData';
import { SmallText, StrongText, Title } from '../../components/common';

class orderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calc: false,
    };
    this.ionList = null;
    this.orderListItems = null;
  }
  componentDidMount() {
    setTimeout(() => {
      const item = document.querySelector('.order-list-items');
      const itemList = document.querySelector('.order-list-items > ion-list');
      if (item && itemList) {
        this.ionList = itemList;
        // this.orderListItems = item
        if (this.ionList && this.orderListItems) {
          this.setState({ calc: true });
        }
        item.scrollTop = this.props.scrollTopPosition;
        // item.scrollBy({ top: 1, behavior: 'smooth' })
      }
    }, 500);
  }

  itemList = (__, subCategoryItems, i) => {
    const { profile } = this.props;

    return (subCategoryItems || []).map((item, index) => {
      if (item.sku === undefined || !Basket.isProductEnabled(item)) {
        return <span key={item.sku + '_disabled'} />;
      }
      let image =
        item.itemRichData && item.itemRichData.squareImageUrl
          ? item.itemRichData.squareImageUrl
          : '';
      if (image && image.indexOf('http://') !== -1) {
        image = image.replace(/http:\/\//g, 'https://');
      }
      return (
        <IonItem
          lines="full"
          style={{ paddingTop: i === 0 ? 0 : 20 }}
          key={item.sku + '_' + index + i}
          onClick={() => forwardTo('/item-details', item)}
        >
          <div tabIndex="-1" className="order-list-item">
            <div className="item-content">
              <StrongText className='roboto-slab item-name'>{__(Basket.getProductName(item, profile))}</StrongText>
              <SmallText
                className='block'
                dangerouslySetInnerHTML={{
                  __html: Basket.getProductDescription(item, profile).replace(/(<([^>]+)>)/gi, ''),
                }}
              />
              <StrongText className="roboto-slab item-price">
                <StrongText className='roboto-slab item-price'>{Basket.formatPrice(item.productPrice)}</StrongText>
                {item.originalPrice ? (
                  <>
                    <StrongText className="roboto-slab original-price">&#163;{item.originalPrice}</StrongText>
                  </>
                ) : null}
              </StrongText>
            </div>
            {image && image !== '' ? (
              <div className="item-image-wrapper">
                <div className="item-image" style={{ backgroundImage: 'url(' + image + ')' }} />
              </div>
            ) : null}

          </div>
        </IonItem>
      );
    });
  };

  drawCategoryItems = (
    __,
    category,
    items,
    breadCrumb = null,
    i,
    isGroup = false,
    allGroup = false,
  ) => {
    let name = category.name ? breadCrumb + category.name : breadCrumb + '';
    let drawSubCategory = isGroup ? (
      <div ref={this.props.menuRefs[`${name}_${i}`]}>
        <IonListHeader className={allGroup ? 'order-sublist-header' : 'order-sublist-header empty'}>
          <Title>{name}</Title>
        </IonListHeader>
        {this.subCategoriesList(__, category, breadCrumb + category.name)}
      </div>
    ) : (
      <>{items}</>
    );

    return <div key={category.type + '_' + name + i}>{drawSubCategory}</div>;
  };

  breadCrumb = (name) => {
    let breadCrumb = name !== '' ? name + ' | ' : name;
    return breadCrumb;
  };

  subCategoriesList = (__, category, name) => {
    if (!isDefined(category)) {
      return <NoData />;
    }
    const categoryItems = category.menuEntry || category.menuEntryGroups || null;
    let items = this.itemList(__, categoryItems);
    let breadCrumb = this.breadCrumb(name);
    if (categoryItems) {
      return categoryItems
        .sort((a, b) => (a.type > b.type ? -1 : 1))
        .map((subCategory, index) => {
          if (subCategory['@type'] === 'group') {
            items = this.itemList(__, subCategory.menuEntry, index);
            const allGroup = subCategory.menuEntry.some((item) => item.type !== 'group');
            return this.drawCategoryItems(
              __,
              subCategory,
              items,
              breadCrumb,
              index,
              true,
              allGroup,
            );
          } else {
            items = this.itemList(__, [subCategory], index);
            return this.drawCategoryItems(__, subCategory, items, name, index, false);
          }
        });
    }
    return this.drawCategoryItems(__, category, items, name, 0);
  };

  calcHeight = () => {
    const { category } = this.props;
    let height = 0;
    if (this.ionList && this.orderListItems && isDefined(category)) {
      let orderListHeight = this.orderListItems.clientHeight;
      let allSublist = document.querySelectorAll('.order-sublist-holder');
      if (allSublist) {
        let lastSubList = allSublist[allSublist.length - 1];
        if (lastSubList && orderListHeight) {
          height = orderListHeight - lastSubList.clientHeight;
        }
      }
    }

    return height;
  };

  render() {
    const { __, category } = this.props;
    const { calc } = this.state;
    return (
      <div
        className="order-list-items"
        id="order-list-items"
        onScroll={(e) => this.props.selectCategoryOnScroll(e)}
        ref={(ref) => (this.orderListItems = ref)}
      >
        <IonList lines="none" ref={(ref) => (this.ionList = ref)}>
          {this.subCategoriesList(__, category, '')}
        </IonList>
        {calc ? <div style={{ height: this.calcHeight() }}></div> : null}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.profile,
    restaurantsUpdated: store.restaurants.restaurantsUpdated,
    basketUpdated: store.orders.basketUpdated,
  };
};

export default connect(mapStateToProps)(withTranslation(orderList));
