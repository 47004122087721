import React from 'react';
import { connect } from 'react-redux';
import {
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonButton,
} from '@ionic/react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { setDeliveryOption } from '../../store/actions';
import { forwardTo, getSingleDeliveryOption, isDefined, changeStatusBarStyle } from '../../lib/utils';
import { getConfig } from '../../appConfig';
import Basket from '../../lib/basket';
import api from '../../lib/api';
import { BigTitle, NormalText, SmallText, Spacer, StrongText, Title } from '../../components/common';
import './index.css';

class DeliveryOptions extends React.Component {
  state = {
    option: null,
  };

  setDeliveryOption = (option) => {
    this.props.dispatch(setDeliveryOption(option));
    forwardTo(option.route);
  };

  checkForSingleDeliveryOption = () => {
    const singleDeliveryOption = getSingleDeliveryOption();
    if (singleDeliveryOption) {
      this.setDeliveryOption(singleDeliveryOption);
    }
  };

  async componentDidMount() {
    const { defaultMenuId } = this.props;
    await changeStatusBarStyle(true);
    Basket.reset();
    this.checkForSingleDeliveryOption();
    api.getDefaultMenu(defaultMenuId).then((res) => {
      this.props.dispatch({
        type: 'SET_RESTAURANT_PROP',
        key: 'defaultMenu',
        value: { ...res, menuName: `${res.menuName} Default` },
        merge: true,
      });
    });
  }

  componentDidUpdate() {
    this.checkForSingleDeliveryOption();
  }

  async componentWillUnmount(){
    await changeStatusBarStyle(false);
  }

  render() {
    const { __ } = this.props;
    const delivery = isDefined(getConfig().delivery) ? getConfig().delivery : [];
    return (
      <Layout hideSecondToolbar={true} color="transparent">
        <div className="absolute-content">
          <Spacer size={2}/>
          <div className="flex-min">
            <BigTitle className='white-text' color='white'>{__('Place Order')}</BigTitle>
          </div>
          <Spacer size={2}/>
          {delivery.map((d, index) => (
            <div>
              {!d.isRemoved && (
                <IonButton
                  className='delivery-button'
                  key={`option${index}`}
                  onClick={() => this.setDeliveryOption(d)}
                  color='gray'
                >
                  {__(d.label)}
                </IonButton>
              )}
            </div>
          ))}
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state) => ({
  deliveryOption: state.orders.deliveryOption,
  defaultMenuId: state.common.defaultMenuId,
});

export default connect(stateToProps)(withTranslation(DeliveryOptions));
