import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IonButton, IonItem, IonInput, IonList, IonText } from '@ionic/react';
// import appConfig from '../../appConfig'
import Layout from '../../components/layout';
import { FieldError, Title, Spacer, NormalText } from '../../components/common';
import { validateForm, forwardTo } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { updateProfile } from '../../store/actions';
import Basket from '../../lib/basket';
import { Capacitor } from '@capacitor/core';
import './index.css';

const { getMobile, setMobile } = Basket;
const isWeb = () => Capacitor.getPlatform() === 'web';

class ContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: this.props.profile.first_name || '',
      last_name: this.props.profile.last_name || '',
      mobile: getMobile() || this.props.profile.mobile || '',
      formErrors: {},
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.formConfig = {
      first_name: { type: 'first_name', required: true },
      mobile: { type: 'tel', required: true },
    };
    // this.triggerInputFile = this.triggerInputFile.bind(this)
    // this.inputRef = React.createRef()
    // this.onChangeFile = this.onChangeFile.bind(this)
  }

  componentDidMount() {
    if (this.state.first_name !== '' && this.state.mobile !== '') {
      // forwardTo('/checkout', { skipContactDetails: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.first_name !== prevProps.profile.first_name) {
      this.setState({ first_name: this.props.profile.first_name });
    }
    if (this.props.profile.last_name !== prevProps.profile.last_name) {
      this.setState({ last_name: this.props.profile.last_name });
    }
    if (this.props.profile.mobile !== prevProps.profile.mobile) {
      this.setState({
        mobile: this.props.profile.mobile ? this.props.profile.mobile : getMobile(),
      });
    }
  }

  handleInput = (key, val) => {
    if (key === 'mobile') {
      setMobile(val);
    }
    this.setState({ [key]: val });
  };

  handleSave() {
    const { cards } = this.props;
    let formErrors = validateForm(this.formConfig, this.state);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { first_name, last_name, mobile } = this.state;
      const profile = {
        first_name: first_name,
        last_name: last_name,
        mobile: mobile ? mobile : getMobile(),
      };
      if (mobile) {
        this.props.dispatch(updateProfile(profile, true));
        if (cards && cards.length === 0 && isWeb()) {
          forwardTo('/card-add');
        } else {
          forwardTo('/checkout');
        }
      } else {
        forwardTo('/checkout');
      }
    }
  }

  render() {
    const { __ } = this.props;
    const { first_name, last_name, mobile, formErrors } = this.state;

    return (
      <Layout hideSecondToolbar={true} color='transparent' headerTitle="Contact Details">
        <div className="absolute-content flex-row-wrapper">
          <div className="scrollable-y checkout">
            <Title>{__('Confirm Contact Details')}</Title>

            <NormalText color="primary" className="">
              {__('To ensure we can contact you if needed, please confirm your contact number')}
            </NormalText>
            <Spacer size={3} />
            <IonList>
              <IonItem lines="none">
                <div className="inline-input inline-input--bordered">
                  <IonText>{__('First Name')}</IonText>
                  <IonInput
                    onIonChange={(e) => this.handleInput('first_name', e.target.value)}
                    /*clearInput*/ required={true}
                    type="text"
                    pattern="text"
                    inputmode="text"
                    value={first_name}
                  ></IonInput>
                </div>
                {formErrors.first_name ? (
                  <FieldError className="field-error" value={__(formErrors.first_name)} />
                ) : null}
              </IonItem>
              <IonItem lines="none">
                {/* <IonLabel position="floating">{ __('Last Name')}</IonLabel>
								<IonInput onIonChange={ e => this.handleInput('last_name', e.target.value) } clearInput required={ true } type="text" pattern="text" inputmode="text" value={ last_name }>
								</IonInput> */}
                <div className="inline-input inline-input--bordered">
                  <IonText>{__('Last Name')}</IonText>
                  <IonInput
                    onIonChange={(e) => this.handleInput('last_name', e.target.value)}
                    /*clearInput*/ required={true}
                    type="text"
                    pattern="text"
                    inputmode="text"
                    value={last_name}
                  ></IonInput>
                </div>
              </IonItem>
              <IonItem lines="none">
                {/* <IonLabel position="floating">{ __('Mobile Number')}</IonLabel>
								<IonInput onIonChange={ e => this.handleInput('mobile', e.target.value) } clearInput required={ true } type="tel" pattern="tel" inputmode="tel" value={ mobile }>
								</IonInput> */}
                <div className="inline-input inline-input--bordered">
                  <IonText>{__('Phone')}</IonText>
                  <IonInput
                    onIonChange={(e) => this.handleInput('mobile', e.target.value)}
                    /*clearInput*/ required={true}
                    type="tel"
                    pattern="tel"
                    inputmode="tel"
                    value={mobile}
                  ></IonInput>
                </div>
                {formErrors.mobile ? (
                  <FieldError className="field-error" value={__(formErrors.mobile)} />
                ) : null}
              </IonItem>
            </IonList>
          </div>
          <div className="flex-min">
            <IonButton onClick={this.handleSave} color="secondary" expand="block">
              {__('Save & Continue')}
            </IonButton>
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { auth, profile } = state.profile;
  const { cards } = state.orders;
  return {
    auth,
    profile,
    cards,
  };
};

export default connect(stateToProps)(withTranslation(ContactDetails));
