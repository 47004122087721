import React from 'react';
import { IonButton, IonInput, IonToggle, IonItem, IonLabel, IonList, IonAlert } from '@ionic/react';
import { connect } from 'react-redux';
// import { getConfig } from '../../appConfig'
import Layout from '../../components/layout';
import PasswordInput from '../../components/passwordInput';
import { forwardTo, getDefaultRoute } from '../../lib/utils';
import { registerRequest, setModal, setSysLocale, setRegisterForm, updateProfile } from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import { beforeShowTimePicker, beforeCloseTimePicker } from '../../store/actions';
import {
  FieldError,
  Title,
  SmallText,
  Spacer,
  NormalText,
  StrongText,
} from '../../components/common';
import { validateForm } from '../../lib/utils';
import Loading from '../../components/spinner';
import './index.css';
import Mobiscroll from '../../components/mobiscroll';
import { getConfig } from '../../appConfig';
import moment from 'moment';
import { Device } from '@capacitor/device';

const { DatePicker } = Mobiscroll;

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
      showAdditionalDetails: false,
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.navToTerms = this.navToTerms.bind(this);
    this.formConfig = {
      email: { type: 'email', required: true },
      password: { type: 'password', required: true },
      first_name: { type: 'text', required: false },
      last_name: { type: 'text', required: false },
      mobile: { type: 'tel', required: false },
      birthday: { type: 'text', required: false },
      accepted_terms_and_conditions: { type: 'toggle', toggle: true },
    };
  }

  handleInput(key, val) {
    const { dispatch } = this.props;
    dispatch(setRegisterForm(key, val));
    if (key === 'accepted_terms_and_conditions') {
      let formErrors = { ...this.state.formErrors };
      formErrors.accepted_terms_and_conditions = undefined;
      this.setState({ formErrors });
    }
  }

  validateForm() {
    const { registerFormData } = this.props;
    let formErrors = validateForm(this.formConfig, registerFormData);
    this.setState({ formErrors });
    return Object.keys(formErrors).length === 0;
  }

  handleRegister() {
    const { protectedReferrer } = this.props;
    this.props.dispatch(registerRequest({ referrer: protectedReferrer }));
  }
  componentDidMount() {
    const { loggedIn } = this.props.auth;
    Device.getLanguageCode().then((res) => {
      const sysLocale = res.value.substr(0, 2);
      if (sysLocale) {
        this.props.dispatch(setSysLocale(sysLocale));
      }
    });
    if (loggedIn) {
      const defaultRoute = getDefaultRoute(this.props.navConfig);
      //forwardTo(defaultRoute.path);
    } else {
      const search = this.props.location.search;
      const referralCode = new URLSearchParams(search).get('referral_code');
      if (referralCode) {
        this.handleInput('referral_code', referralCode.toUpperCase())
      }
    }
  }

  returnToLogin = (history) => history.goBack();

  navToTerms = () => forwardTo('/terms');

  render() {
    const { hasReferCode } = getConfig().flags;
    const { __, isRegisterModalOpen, registerFormData } = this.props;
    const email = registerFormData.email;
    const password = registerFormData.password;
    const first_name = registerFormData.first_name;
    const last_name = registerFormData.last_name;
    const mobile = registerFormData.mobile;
    const birthday = registerFormData.birthday;
    const referral_code = registerFormData.referral_code;
    const accepted_terms_and_conditions = registerFormData.accepted_terms_and_conditions;
    const is_subscribed = registerFormData.is_subscribed;
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    const { loggedIn } = this.props.auth;
    const defaultDateValue = moment().subtract(18, 'years');

    return (
      <Loading transparent>
        <Layout
          hideSecondToolbar={true}
          hideBack={true}
          headerTitle={__('Register')}
          color="transparent"
          contentClassName="register-background"
        >
          {!loggedIn ? (
            <div className="absolute-content flex-row-wrapper">
              <Title className="uppercase">{__('Let’s register')}</Title>
              <Spacer size={1} />
              <IonList>
                <IonLabel color="primary" position="stacked">
                  {__('Email address')}
                </IonLabel>
                <IonInput
                  placeholder={__('Enter your email address')}
                  onIonChange={(e) => this.handleInput('email', e.target.value)}
                  onIonBlur={(e) => {
                    const usernameInput = document.querySelector('input[type="email"]:-webkit-autofill');
                    if (usernameInput) {
                      this.handleInput('email', usernameInput.value);
                    }
                    this.handleInput('email', e.target.value);
                  }}
                  required={true}
                  type="email"
                  pattern="email"
                  inputmode="email"
                  value={email}
                ></IonInput>
                <FieldError className="field-error" value={__(this.state.formErrors.email)} />
                <IonLabel color="primary" position="stacked">
                  {__('Mobile Number')}
                </IonLabel>
                <IonInput
                  placeholder={__('Enter your mobile number')}
                  onIonChange={(e) => this.handleInput('mobile', e.target.value)}
                  required={true}
                  type="tel"
                  pattern="tel"
                  inputmode="tel"
                  value={mobile}
                ></IonInput>
                <FieldError className="field-error" value={__(this.state.formErrors.mobile)} />
                <IonLabel color="primary" position="stacked">
                  {__('Password')}
                </IonLabel>
                <PasswordInput
                  placeholder={__('Enter your password')}
                  onIonChange={(e) => this.handleInput('password', e.target.value)}
                  value={password}
                />
                <FieldError className="field-error" value={__(this.state.formErrors.password)} />
                {hasReferCode &&
                  <>
                    <IonLabel color="primary" position="stacked">
                      {__('Referral Code (optional)')}
                    </IonLabel>
                    <IonInput
                      placeholder={__('Enter Referral Code')}
                      onIonChange={(e) => {
                        let refCode = e.target.value || '';
                        this.handleInput('referral_code', refCode.toUpperCase())
                      }}
                      required={false}
                      type="text"
                      pattern="text"
                      inputmode="text"
                      value={referral_code}
                    ></IonInput>
                  </>
                }
              </IonList>
              <Spacer />
              <div className="box-holder box-holder--register">
                <IonList>
                  <IonItem lines='none'>
                    <div tabIndex="-1"></div>
                    <IonToggle color="secondary" slot="start" checked={accepted_terms_and_conditions} onIonChange={e => this.handleInput('accepted_terms_and_conditions', e.detail.checked)} />
                    <IonLabel className="ion-text-wrap">
                      <NormalText color="primary">{__('By signing up you agree to') + ' '} <span className='underlined' onClick={() => forwardTo('/terms')}>{__('our T&Cs')}</span> {__('and')} <span className='underlined' onClick={() => forwardTo('/privacy')}>{__('privacy policy')}</span></NormalText>
                    </IonLabel>
                    <FieldError className="field-error" value={__(this.state.formErrors.accepted_terms_and_conditions)} />

                  </IonItem>
                  <IonItem lines='none'>
                    <IonToggle color="secondary" slot="start" checked={is_subscribed} onIonChange={e => this.handleInput('is_subscribed', e.detail.checked)} />
                    <IonLabel className="ion-text-wrap">
                      <NormalText color="primary">{__("I'd like to receive email updates that contain news, offers and promotions.")}</NormalText>
                    </IonLabel>
                  </IonItem>
                </IonList>
              </div>
              <Spacer size={2} />
              <div className="flex-min">
                <IonButton expand="block" color="secondary" onClick={() => {
                  if (this.validateForm()) this.handleRegister()
                }}>
                  {__('Continue')}
                </IonButton>
              </div>
            </div>
          ) : (
            <div className="absolute-content flex-row-wrapper">
              <Title className="uppercase">{__('Personal Details')}</Title>
              <Spacer size={1} />
              <IonList>
                <IonItem>
                  <IonLabel color="primary" position="stacked">
                    {__('First Name')}
                  </IonLabel>
                  <IonInput
                    placeholder={__('Enter your name')}
                    onIonChange={(e) => this.handleInput('first_name', e.target.value)}
                    required={true}
                    type="text"
                    pattern="text"
                    inputmode="text"
                    value={first_name}
                    labelPlacement="stacked"
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel color="primary" position="stacked">
                    {__('Last Name')}
                  </IonLabel>
                  <IonInput
                    placeholder={__('Enter your last name')}
                    onIonChange={(e) => this.handleInput('last_name', e.target.value)}
                    required={true}
                    type="text"
                    pattern="text"
                    inputmode="text"
                    value={last_name}
                    labelPlacement="stacked"
                  ></IonInput>
                </IonItem>
                {/* <IonItem>
                  <IonLabel color="primary" position="stacked">
                    {__('Mobile Number')}
                  </IonLabel>
                  <IonInput
                    placeholder={__('Enter your mobile number')}
                    onIonChange={(e) => this.handleInput('mobile', e.target.value)}
                    required={true}
                    type="tel"
                    pattern="tel"
                    inputmode="tel"
                    value={mobile}
                  ></IonInput>
                </IonItem> */}
                <IonItem className="date-picker-wrapper">
                  <IonLabel color="primary" position="stacked">
                    {__('Date of Birth')}
                  </IonLabel>
                  <DatePicker
                    className="data-picker-input"
                    display="bottom"
                    max={yesterday}
                    setText={__('Done')}
                    cancelText={__('Cancel')}
                    value={birthday}
                    onSet={(e, a) => this.handleInput('birthday', a.element.value)}
                    dateFormat="dd/mm/yy"
                    defaultValue={defaultDateValue}
                    placeholder={__('xx/xx/xxxx')}
                  />
                </IonItem>
                {/* <div className="date-picker-wrapper">
                  <IonLabel color="primary">
                    {__('Date Birth')}
                  </IonLabel>
                  <DatePicker
                    className="data-picker-input"
                    display="bottom"
                    max={yesterday}
                    setText={__('Done')}
                    cancelText={__('Cancel')}
                    value={birthday}
                    onSet={(e, a) => this.handleInput('birthday', a.element.value)}
                    dateFormat="dd/mm/yy"
                  />
                </div> */}
              </IonList>
              <div className="box-holder box-holder--register">
               
              </div>
              <Spacer size={2} />
              <div className="flex-min">
                <IonButton
                  expand="block"
                  fill="clear"
                  className="link underlined"
                  onClick={() => forwardTo(getDefaultRoute(this.props.navConfig).path)}
                >
                  {__('Skip and update later')}
                </IonButton>
                <IonButton expand="block" color="secondary" onClick={() => {
                  const profile = {
                    first_name: first_name,
                    last_name: last_name,
                    mobile: mobile,
                    birthday: birthday,
                    is_subscribed,
                  };
                  this.props.dispatch(updateProfile(profile));
                  forwardTo(getDefaultRoute(this.props.navConfig).path);
                }}>
                  {__('Continue')}
                </IonButton>
                
              </div>
            </div>
          )}
        </Layout>
        <IonAlert
          isOpen={isRegisterModalOpen}
          onDidDismiss={() => this.props.dispatch(setModal('isRegisterModalOpen', false))}
          header={__('Success')}
          message={__('Register processed. Please check your mail')}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.props.dispatch(setModal(('isRegisterModalOpen', false))),
            },
          ]}
        />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, isRegisterModalOpen, registerFormData, protectedReferrer } = state.profile;
  const { navConfig } = state.common;
  return {
    auth,
    isRegisterModalOpen,
    registerFormData,
    protectedReferrer,
    navConfig
  };
};

export default connect(stateToProps)(withTranslation(Register));
