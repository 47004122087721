import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Capacitor } from '@capacitor/core';
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonContent,
  IonList,
  IonItem,
  IonMenuToggle,
  IonIcon,
  IonLabel,
  IonFooter,
  IonAlert,
  IonButtons,
  IonButton,
} from '@ionic/react';
import { getSocials, logout, setCommonModal } from '../../store/actions';
import { getDefaultRoute, forwardTo, padNumber, openExternalLink, isAppVersionValid } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { Spacer, SmallText, Hr, NormalText } from '../../components/common';
import packageJson from '../../../package.json';
import { chevronBack } from 'ionicons/icons';
import defaultImg from '../../assets/images/gray-avatar.png';
import logOutIcon from '../../assets/images/logout-icon.svg'
import myAccountIcon from '../../assets/images/icons/user.svg';
import { parseSocialItem } from '../../screens/social';
import SmallDrawer from './smallDrawer';
import './index.css';
import { getConfig } from '../../appConfig';
import Account from '../../screens/account';
import { Device } from '@capacitor/device';

const NavItem = withRouter(({ history, item, hideIcon, hideArrow, handleLogout, className, loggedIn, __ }) => {
  const shortLabel =
    item.label === 'Terms & Conditions'
      ? 'T&Cs'
      : item.label === 'Faq & Support'
        ? 'Faqs & Support'
        : item.label;
  const selected = history.location.pathname === item.path;
  if (item.protected && !loggedIn) {
    return (<></>);
  }
  return (
    <IonMenuToggle key={item.path} auto-hide="false">
      <IonItem
        className={
          'nav-item' + (selected ? ' okx-nav-selected' : '') + (className ? ' ' + className : '')
        }
        color="tertiary"
        button={hideArrow ? false : true}
        detail={hideArrow ? false : true}
        onClick={() =>
          item.fn === 'logout'
            ? handleLogout()
            : item.isLink
              ? openExternalLink(item.path)
              : forwardTo(item.path, item.state)
        }
      >
        <div tabIndex="-1"></div>
        {hideIcon ? null : <IonIcon className={`nav-icon ${item.iconWithDot ? "nav-icon-dotted" : ""}`} slot="start" icon={item.icon} />}
        <IonLabel color="white" className="nav-label">
          {__(shortLabel)}
        </IonLabel>
      </IonItem>
    </IonMenuToggle>
  );
});

class Drawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appDeviceVersion: '',
      menuVisible: true,
      logBoxOpen: false,
      profile_image_url: this.props.profile.profile_image_url || defaultImg,
    };
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout() {
    this.props.dispatch(logout());
  }

  async componentDidMount() {
    const info = await Device.getInfo();
    const appDeviceVersion = info.appVersion;
    this.setState({ appDeviceVersion: appDeviceVersion }, () => {
      this.checkVerison();
    });
    this.props.dispatch(getSocials());
  }

  checkVerison = () => {
    // web version checking
    if (!isAppVersionValid(this.props.appVersion, packageJson.version)) {
      this.props.dispatch(setCommonModal('updateAppModal', true));
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.appVersion !== prevProps.appVersion) {
      this.checkVerison();
    }

    if (this.props.profile.profile_image_url !== prevProps.profile.profile_image_url) {
      if (this.props.profile.profile_image_url) {
        this.setState({ profile_image_url: this.props.profile.profile_image_url });
      } else {
        this.setState({ profile_image_url: defaultImg });
      }
    }
  }

  toggleMenu = () => {
    this.setState({ menuVisible: !this.state.menuVisible }, () => {
      let drawer = this.state.menuVisible
        ? '--okx-drawer-max-width'
        : '--okx-small-drawer-max-width';
      document.documentElement.style.setProperty('--okx-drawer-width', `var(${drawer})`);
    });
  };

  toggleLogBox = () => {
    const { auth } = this.props;
    const { loggedIn } = auth;
    if (loggedIn) {
      this.handleLogout();
    } else {
      forwardTo('/login');
    }
  };

  render() {
    const { auth, __, navConfig, social } = this.props;
    const { menuVisible } = this.state;
    const { loggedIn } = auth;
    const defaultRoute = getDefaultRoute(navConfig);
    const myAccount = { label: 'My Account', path: '/account', component: Account, icon: myAccountIcon, protected: true , notInDrawer: true };
    return (
      <IonMenu className="drawer-menu" side="start" type="overlay" contentId="main">
        {menuVisible ? (
          <>
            <IonHeader>
              <IonToolbar className="nav-logo" color="tertiary" onClick={() => forwardTo(defaultRoute.path)}>
                <IonIcon
                  icon={chevronBack}
                  className="collapse-drawer-icon"
                  onClick={() => this.toggleMenu()}
                />
                <IonButtons slot="end">
                  <IonMenuToggle>
                    <IonButton button clear>
                      <IonIcon slot="icon-only" icon="close" />
                    </IonButton>
                  </IonMenuToggle>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent color="tertiary">
              <IonList className='nav-items-list' lines="none">
                {navConfig.routes
                  .filter((route) => !!route?.path && !route.notInDrawer)
                  .map((item) => (
                    <NavItem __={__} key={item.path} item={item} loggedIn={loggedIn} />
                ))}
                {getConfig().sidebarLinks?.map((links, index) => (
                  <>
                    {links.items.map((item, i) => (
                      <IonMenuToggle key={i} auto-hide="false">
                        <IonItem
                        button
                        detail={true}
                        onClick={() => openExternalLink(item.link)}
                        className={'nav-item'}
                        color="tertiary"
                        >
                        <span
                          className="nav-icon"
                          dangerouslySetInnerHTML={{
                            __html: item.icon,
                          }}
                        ></span>
                        <IonLabel color="white" className="nav-label">
                          {__(item.label)}
                        </IonLabel>
                      </IonItem>

                      </IonMenuToggle>
                      
                    ))}
                  </>
                ))}
                <NavItem __={__} key={myAccount.path} item={myAccount} loggedIn={loggedIn} />
              </IonList>
            </IonContent>
            <IonFooter>
              <div className='social-icons-wrapper'>
                {Object.keys(social || {}).map((key, index) => {
                  const value = social[key];
                  const { link, icon } = parseSocialItem(key, value);
                  return (
                    <IonIcon
                      onClick={() => openExternalLink(link)}
                      key={'social-icon-' + index}
                      className='social-icon'
                      icon={icon}
                    />
                  );
                })
                }
              </div>
              <Spacer size={1} />

              <Hr className='drawer-line' thickness="1px" margin="0px" size='150%' />
              <div className='drawer-footer'>
                <IonMenuToggle className='clickable'>
                  <IonIcon className={loggedIn ? 'rotate' : ''} icon={logOutIcon} />
                  <IonLabel color="white" className="nav-label" onClick={this.toggleLogBox}>
                    {__(loggedIn ? 'Log Out' : 'Log In')}
                  </IonLabel>
                </IonMenuToggle>
                <IonMenuToggle className="terms-items clickable" lines="none" auto-hide="false">
                  {navConfig.additionalRoutes
                    .filter((route) => !!route.path)
                    .map((item, i) => (
                      <SmallText color="white" className="nav-label clickable" onClick={() => forwardTo(item.path, item.state)}>
                        {__(item.label === 'Terms & Conditions' ? 'T&Cs' : item.label)}
                      </SmallText>
                    ))}
                </IonMenuToggle>
              </div>
            </IonFooter>
          </>
        ) : (
          <SmallDrawer toggleMenu={this.toggleMenu} />
        )}
      </IonMenu>
    );
  }
}

const stateToProps = (state) => {
  const { auth, profile } = state.profile;
  const { appVersion, navConfig, social } = state.common;
  return {
    auth,
    appVersion,
    profile,
    navConfig,
    social
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Drawer)));
