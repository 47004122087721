import {
  call,
  delay,
  put,
  select,
  takeEvery
} from 'redux-saga/effects';
import api from '../../lib/api';
import {
  GET_PROFILE,
  GET_VOUCHERS,
  SET_ACTIVE_SUBSCRIPTION,
  SET_PROFILE_PROP
} from '../profile/constants';
import {
  CANCEL_USER_SUBSCRIPTION,
  FETCH_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTIONS_OK,
  PAY_MEMBERSHIP,
  REACTIVATE_USER_SUBSCRIPTION,
  PAY_MEMBERSHIP_OK,
  PAY_MEMBERSHIP_FAILED,
  CREATE_SUBSCRIPTION,
  CHECK_SUBSCRIPTION_PIN,
  INVITE_USER,
  RESEND_SUBSCRIPTION_INVITE_EMAIL,
  CREATE_SUBSCRIPTION_NATIVE,
} from './constants';
import {
  forwardTo
} from '../../lib/utils';
import {
  showToast
} from '../common/actions';
import { getConfig } from '../../appConfig';
import { LOADING } from '../constants';
import { translateSaga } from '../common/sagas';
import asyncStorage from '../../lib/asyncStorage';
import LibStripe from '../../lib/stripe';

const getSubscriptionsSaga = function* () {
  const subscriptions = yield call(api.getSubscriptions);
  yield put({ type: GET_PROFILE });
  yield put({
    type: FETCH_SUBSCRIPTIONS_OK,
    key: 'subscriptions',
    value: subscriptions
  });
};

const createSubscriptionSaga = function* (action) {
  try {
    yield put({ type: LOADING, loading: true});
    const frontEndAppConfig = yield call(api.getFrontEndAppConfig);
    const result = yield call(api.createSubscription, {
      data: action.value,
      location: frontEndAppConfig.membership.membershipLocationId
    });

    window.open(result.data.checkout.url, '_self');
  

  } catch (error) {
    yield put({ type: LOADING, loading: false });
    yield put(showToast(error.response.data.status, 'danger'));
  }


};

const cancelSubscriptionSaga = function* () {
  const { profile } = yield select();
  profile.profile.active_subscription = {
    ...profile.profile.active_subscription,
    cancellation_pending: true,
  };
  const result = yield call(api.cancelUserSubscription);
  if (result.status === 201) {
    yield put({ type: SET_PROFILE_PROP, key: 'profile', value: profile.profile });
  }
};

const reactivateSubscriptionSaga = function* () {
  const { profile } = yield select();
  profile.profile.active_subscription = {
    ...profile.profile.active_subscription,
    cancellation_pending: false,
  };
  const result = yield call(api.cancelUserSubscription, {
    cancel: false
  });
  if (result.status === 201) {
    yield put({
      type: SET_PROFILE_PROP,
      key: 'profile',
      value: profile.profile
    });
  }
};

const payMembershipSaga = function* (action) {
  try {
    const data = yield call(api.payMembership, action.value);
    yield put({
      type: PAY_MEMBERSHIP_OK,
      data
    });
    yield put({
      type: GET_PROFILE
    });
    yield call(forwardTo, '/membership-completed', {
      completedOrder: true
    });
  } catch (error) {
    yield put({
      type: PAY_MEMBERSHIP_FAILED,
      error
    });
  }
};

const checkSubscriptionPinSaga = function* (action) {
  const { profile } = yield select();
  const { subscriptionPin } = action;
  try{
    yield put({ type: LOADING, loading: true });
    if( profile.profile ){
      const result = yield call(api.checkSubscriptionPin, {
        subscription_code: subscriptionPin,
        user_id: profile.profile.id
      });
      yield put({ type: LOADING, loading: false });
      if(result.data.error && result.data.show_error){
        yield put(showToast(yield call(translateSaga, result.data.error), 'warning'));
      } else if (result.status === 200) {
        yield delay(500);
        yield put({ type: GET_VOUCHERS });
        yield call(action.cb, true);
      } else{
        yield call(action.cb, false);
      }
    }else{
      yield put({ type: LOADING, loading: false });
      yield put(showToast(yield call(translateSaga, 'Please login!'), 'warning'));
    }
  }catch{
    yield put({ type: LOADING, loading: false });
    yield call(action.cb, false);
  }
};

const inviteUserToSubscriptionSaga = function* (action) {
  const { profile } = yield select();
  const { value } = action;
  try{
    yield put({ type: LOADING, loading: true });
    if( profile.profile ){
      const result = yield call(api.inviteUserToSubscription , {
        user_id: profile.profile.id,
        invited_user_email: value,
        remove_user: action.remove_user || false
      });
      if(result.data && result.data.error){
        yield put({ type: LOADING, loading: false });
        yield put(showToast(yield call(translateSaga, result.data.error), 'warning'));
      }
      else if(result.status === 200 && result.data && result.data.message){
        yield put({ type: SET_ACTIVE_SUBSCRIPTION, value: result.data.active_subscription });
        let newProfile = profile.profile;
        newProfile = {...newProfile, active_subscription: result.data.active_subscription}
        yield call(asyncStorage.setItem, 'profile', JSON.stringify(newProfile));
        yield put({ type: LOADING, loading: false });
        yield put(showToast(yield call(translateSaga, result.data.message), 'success'));
      }
    }else{
      yield put({ type: LOADING, loading: false });
      yield put(showToast(yield call(translateSaga, 'Please login!'), 'warning'));
    }
  }catch{
    yield put({ type: LOADING, loading: false });
    yield call(action.cb, false);
  }
};

const resendSubscriptionInviteEmailSaga = function* (action) {
  const { profile } = yield select();
  const { value } = action;
  try{
    if( profile.profile ){
      const result = yield call(api.resendSubscriptionInviteEmail, {
        user_id: profile.profile.id,
        invited_user_email: value
      });
      if (result.data.error) {
        yield put(showToast(yield call(translateSaga, result.data.error), 'warning'));
      }else if(result.data.message){
        yield put(showToast(yield call(translateSaga, result.data.message), 'success'));
      }
    }else{
      yield put(showToast(yield call(translateSaga, 'Please login!'), 'warning'));
    }
  }catch{
    yield call(action.cb, false);
  }
};

const createSubscriptionNativeSaga = function* (action) {
  try {
    yield put({ type: LOADING, loading: true });
    const frontEndAppConfig = yield call(api.getFrontEndAppConfig);
    const result = yield call(api.createSubscriptionNative, {
      data: action.value,
      location: frontEndAppConfig.membership.membershipLocationId
    });
    if (!result || result.data?.error || result.error) {
      yield put(showToast('Error creating subscription', 'warning'));
    } else {
      try {
        const nativePayment = getConfig().nativePayment || {};
        const merchantDisplayName = nativePayment.merchantDisplayName || "";
        const merchantCountryCode = nativePayment.merchantCountryCode || 'GB';
        const withZipCode = nativePayment.withZipCode || false;
        const enableGooglePay = nativePayment.enableGooglePay || false;
        const googlePayIsTesting = nativePayment.googlePayIsTesting || false;
        const enableApplePay = nativePayment.enableApplePay || false;
        const applePayMerchantId = nativePayment.applePayMerchantId || '';
        const stripeInstance = LibStripe .getStripeInstance();
        const customerId = result.data?.customer_id;
        const customerEphemeralKeySecret = result.data?.ephemeral_key;
        const paymentIntentClientSecret = result.data?.intent_client_secret;
        const setupIntentClientSecret = result.data?.setup_intent_client_secret;
        const haveFreeTrial = result.data?.have_free_trial;
        const paymentSheetConfig= {
          GooglePayIsTesting: googlePayIsTesting,
          merchantDisplayName,
          merchantCountryCode,
          withZipCode,
          enableGooglePay,
          enableApplePay,
          applePayMerchantId,
          customerId,
          customerEphemeralKeySecret
        };
        if(haveFreeTrial){
          paymentSheetConfig.setupIntentClientSecret = setupIntentClientSecret;
            yield stripeInstance.createPaymentSheet(paymentSheetConfig);
            const { paymentResult } = yield stripeInstance.presentPaymentSheet();
            if (paymentResult === 'paymentSheetCompleted') {
              const confirmStatus = yield call(api.confirmTrialSubscription, {
                data: action.value,
                location: config?.front_end_app_config?.general?.membershipLocationId || 0,
                setup_intent_client_secret: setupIntentClientSecret,
                setup_intent_id: setup_intent_id
              });
              if(confirmStatus && confirmStatus?.data?.subscription_is_created){
                forwardTo('/my-membership');
              } else {
                forwardTo('/membership');
                return null;
              }
            } else {
              forwardTo('/membership');
              return null;
            }
        }else{
          paymentSheetConfig.paymentIntentClientSecret = paymentIntentClientSecret;
        }
        yield stripeInstance.createPaymentSheet(paymentSheetConfig);
        const { paymentResult } = yield stripeInstance.presentPaymentSheet();
        if (paymentResult === 'paymentSheetCompleted') {
          forwardTo('/membership');
        }
      } catch (ex) {
        console.log('Error creating native subscription', ex)
      }
    }
  } catch (error) {
    if(error?.response?.data?.status){
      yield put(showToast(error.response.data.status, 'danger'));
    }else {
      yield put(showToast('Error creating subscription', 'danger'));
    }
  }
  yield put({ type: LOADING, loading: false });
};

export default function* subscriptionSagas() {
  yield takeEvery(FETCH_SUBSCRIPTIONS, getSubscriptionsSaga);
  yield takeEvery(CANCEL_USER_SUBSCRIPTION, cancelSubscriptionSaga);
  yield takeEvery(REACTIVATE_USER_SUBSCRIPTION, reactivateSubscriptionSaga);
  yield takeEvery(PAY_MEMBERSHIP, payMembershipSaga);
  yield takeEvery(CREATE_SUBSCRIPTION, createSubscriptionSaga);
  yield takeEvery(CHECK_SUBSCRIPTION_PIN, checkSubscriptionPinSaga);
  yield takeEvery(INVITE_USER, inviteUserToSubscriptionSaga);
  yield takeEvery(RESEND_SUBSCRIPTION_INVITE_EMAIL, resendSubscriptionInviteEmailSaga);
  yield takeEvery(CREATE_SUBSCRIPTION_NATIVE, createSubscriptionNativeSaga);
}