import React from 'react';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { Title, SmallText, Spacer, NormalText, StrongText, Subtitle } from '../../components/common';
import { IonRow, IonCol, IonButton, IonAlert, IonGrid } from '@ionic/react';
import Box, { BoxHeader } from '../../components/box';
import OrderContent from '../../components/orderContent';
import { createNewBasketInstance } from '../../lib/basket';
import Basket from '../../lib/basket';
import { checkCancelOrder, setOrdersProp } from '../../store/orders/actions';
import moment from '../../lib/moment';
import { forwardTo, visaEndingDrawText } from '../../lib/utils';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import AmountPaid from '../../components/amountPaid';
import './index.css';

class HistoryDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCancel: false,
      orderId: null,
      restaurantId: null,
    };
  }

  componentDidMount() {
    if(!this.props.location.state){
      forwardTo('/history');
    }else{
      const { location } = this.props;
      const orderArr = location?.state?.order;
      const now = moment();
      let cutoffTime = orderArr ? orderArr.cutoff_time : now;
      let cutoffTimeMoment = new moment(cutoffTime, 'YYYY-MM-DD HH:mm:ss');
      const utcOffset = cutoffTimeMoment.utcOffset();
      cutoffTimeMoment = cutoffTimeMoment.add('minutes', utcOffset);
      if (now.isBefore(cutoffTimeMoment)) {
        if (orderArr.status !== 'REFUNDED') {
          this.setState({
            isCancel: true,
            orderId: orderArr.id,
            restaurantId: orderArr.restaurant_id,
          });
        }
      }
    }
  }

  backHandler = () => {
    this.setState({ orderId: null, isCancel: false, restaurantId: null });
    forwardTo('/dashboard');
    forwardTo('/history', { tab: 'order' });
  };

  formatPaymentString = (str) => {
    let splitedStr = str.split(' ').splice(2).join(' ');
    return splitedStr;
  };

  cancelOrder = () => {
    const { orderId, isCancel, restaurantId } = this.state;
    if (isCancel) {
      this.props.dispatch(checkCancelOrder(orderId, restaurantId));
      this.setState({ orderId: null, isCancel: false, restaurantId: null });
    }
  };

  handleCancelOrderModal = (flag) => {
    this.props.dispatch(setOrdersProp('cancelOrderModal', flag));
  };

  drawContentTitle = (__, deliveryOption, id, orderType) => {
    if (orderType === 'Outpost Drop-Off') {
      return (
        <Title>
          {__('Drop Point')} {__('Order')} #{id}
        </Title>
      );
    } else if (orderType === 'Delivery') {
      return (
        <Title>
            {__('Delivery')} {__('Order')} #{id}
        </Title>
      );
    } else if (orderType === 'Table') {
      return (
        <Title>
            {__('Order To Table')} #{id}
        </Title>
      );
    } else {
      return (
        <Title>
          { __('Click And Collect') } {__('Order')} #{id}
        </Title>
      );
    }
  };

  drawContent = (__, order, orderType, basketInstance, orderCompletePage) => {
    let paymentMetod = basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage);
    const isOldLayoutOfConfirmationScreens = getConfig().appType.oldLayoutConfirmationScreens;
    if (orderType === 'Click & Collect' || orderType === 'table') {
      return (
        <>
          <IonRow className="bordered-bottom" >
            <IonCol size={'4'}>
              <SmallText size="small" className="bold">
                {`${__('Collect from')}:`}
              </SmallText>
            </IonCol>
            <IonCol>
              <SmallText size="small">
                {order.restaurant_name}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow className="bordered-bottom" >
            <IonCol size={'4'}>
              <SmallText size="small" className="bold">
                {`${__('Collect at')}:`}
              </SmallText>
            </IonCol>
            <IonCol>
              <SmallText size="small" >
                {basketInstance.formatOrderTime(true, 'h:mm a')}
              </SmallText>
            </IonCol>
          </IonRow>

          <IonRow className="bordered-bottom" >
            <IonCol size={'4'}>
              <SmallText size="small" className="bold">
                {`${__('Order placed')}:`}
              </SmallText>
            </IonCol>
            <IonCol>
              <SmallText size="small" >
                {moment(order.created_at).format(
                  /* 'ddd DD MMMM YYYY [at] LT' */ 'DD MMM YYYY [at] h:mm a',
                )}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size={'4'}>
              <SmallText size="small" className="bold">
                {`${__('Payment')}:`}
              </SmallText>
            </IonCol>
            <IonCol>
              <SmallText size="small" >
                {visaEndingDrawText(paymentMetod)}
              </SmallText>
            </IonCol>
          </IonRow>
        </>
      );
    } else if (orderType === 'Delivery') {
      return (
        <>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR">
              <NormalText tag="strong" className="bold">
                {__('Delivery from')}:
              </NormalText>
            </IonCol>
            <IonCol className="righted">
              <NormalText color="primary" className="thiner-text">
                {order.delivery_address.addressLine1}, {order.delivery_address.place},{' '}
                {order.delivery_address.postalCode}
              </NormalText>
            </IonCol>
          </IonRow>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR">
              <NormalText tag="strong" className="bold">
                {__('Order placed')}:
              </NormalText>
            </IonCol>
            <IonCol className="righted">
              <NormalText color="primary" className="thiner-text">
                {moment(order.created_at).format('D MMM YYYY [at] h:mm a')}
              </NormalText>
            </IonCol>
          </IonRow>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR">
              <NormalText tag="strong" className="bold">
                {__('Delivery at')}:
              </NormalText>
            </IonCol>
            <IonCol className="righted">
              <NormalText color="primary" className="thiner-text">
                {basketInstance.formatOrderTime(true)}
              </NormalText>
            </IonCol>
          </IonRow>
          {
            /*order && order.order_value > 0 ||*/ orderType !== 'Click & Collect' ? (
              <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
                <IonCol size="5" className="paddLR">
                  <NormalText tag="strong" className="bold">
                    {__('Payment')}:
                  </NormalText>
                </IonCol>
                <IonCol className="righted">
                  <NormalText color="primary" className="thiner-text">
                    {visaEndingDrawText(paymentMetod)}
                  </NormalText>
                </IonCol>
              </IonRow>
            ) : null
          }
        </>
      );
    } else if (orderType === 'Outpost Drop-Off') {
      return (
        <>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <NormalText tag="strong" className="bold">
                {__('Drop-off at')}:
              </NormalText>
            </IonCol>
            <IonCol className="righted">
              <NormalText color="primary" className="thiner-text">
                {order.restaurant_name}
              </NormalText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <NormalText tag="strong" className="bold">
                {__('Order Placed')}:
              </NormalText>
            </IonCol>
            <IonCol className="righted">
              <NormalText color="primary" className="thiner-text">
                {moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}
              </NormalText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <NormalText tag="strong" className="bold">
                {__('Drop-off Time')}:
              </NormalText>
            </IonCol>
            <IonCol className="righted">
              <NormalText color="primary" className="thiner-text">
                {basketInstance.formatOrderTime(true)}
              </NormalText>
            </IonCol>
          </IonRow>
          {order && order.order_value > 0 ? (
            <IonRow>
              <IonCol size="5" className="paddLR">
                <NormalText tag="strong" className="bold">
                  {__('Payment')}:
                </NormalText>
              </IonCol>
              <IonCol className="righted">
                <NormalText color="primary" className="thiner-text">
                  {visaEndingDrawText(paymentMetod)}
                </NormalText>
              </IonCol>
            </IonRow>
          ) : null}
        </>
      );
    } else if (orderType === 'Table') {
      return (
        <>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR mr-5">
              <NormalText tag="strong">{__('Ordered at')}:</NormalText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <NormalText>{order.restaurant_name}</NormalText>
            </IonCol>
          </IonRow>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'block' : ''}`}>
            <IonCol size="5" className="paddLR mr-5">
              <NormalText tag="strong">{__('Table number')}:</NormalText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <NormalText>{order.table_name}</NormalText>
            </IonCol>
          </IonRow>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR mr-5">
              <NormalText tag="strong">{__('Order Placed')}:</NormalText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <NormalText>{moment(order.created_at).format('D MMM YYYY [at] h:mm a')}</NormalText>
            </IonCol>
          </IonRow>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR mr-5">
              <NormalText tag="strong">{__('Payment')}:</NormalText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <NormalText>{visaEndingDrawText(paymentMetod)}</NormalText>
            </IonCol>
          </IonRow>
        </>
      );
    }
  };

  render() {
    if(!this.props.location.state){
      return null;
    }
    const { __, location, cards } = this.props;
    const order = location.state.order;
    const { id, status } = order;
    const basketInstance = createNewBasketInstance();
    basketInstance.recreateOrder(order);
    const orderType = basketInstance.getOrderType();
    const orderCompletePage = false;
    let paymentMetod = basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage);
    const orderTypeTitle =
      basketInstance.getOrderType() === 'Click & Collect'
        ? 'Collection'
        : basketInstance.getOrderType();
    const hasCancelOrder = getConfig().appType.hasCancelOrder;
    const isOldLayoutOfConfirmationScreens = getConfig().appType.oldLayoutConfirmationScreens;
    return (
      <Loading>
        <Layout hideSecondToolbar={true} color='transparent' headerTitle={__('History')} backHandler={this.backHandler}>
          <div className="absolute-content history-details">
            <div className="scrollable-y">
              {!isOldLayoutOfConfirmationScreens ? (
                <>
                  <Title> {__('Order')} #{id} </Title>
                  <IonGrid className="okx-box-header-grid">
                    <>
                      {this.drawContent(__, order, orderType, basketInstance, orderCompletePage)}
                      <Spacer size={2} />
                      <Subtitle className='bold'>{__('Items Details')}</Subtitle>
                      <OrderContent basketInstance={basketInstance} type="orderHistory" />
                    </>
                  </IonGrid>
                  {/* </Box> */}
                  {orderType !== 'Click & Collect' &&
                    ['PAID', 'PARSED', 'NEW', 'new'].indexOf(order.status) !== -1 &&
                    this.state.isCancel &&
                    hasCancelOrder ? (
                    <IonButton
                      expand="block"
                      fill="clear"
                      className="link underlined"
                      color="secondary"
                      onClick={() => this.handleCancelOrderModal(true)}
                    >
                      {__('Cancel this order')}
                    </IonButton>
                  ) : null}
                </>
              ) : (
                <>
                  <div className="order-status">{__(status)}</div>
                  {this.drawContentTitle(__, Basket.getDeliveryOption(), id, orderTypeTitle)}
                  {isOldLayoutOfConfirmationScreens ? (
                    <div className="order-status-payment-method">
                      <IonCol size="5" className="mr-10">
                        <SmallText >
                          {__('Payment Method')}:
                        </SmallText>
                      </IonCol>
                      <IonCol>
                        <SmallText color="primary" className="thiner-text primary-regular">
                          {visaEndingDrawText(paymentMetod)}
                        </SmallText>
                      </IonCol>
                    </div>
                  ) : null}
                  <Spacer size={1} />
                  <Box>
                    <BoxHeader>
                      {this.drawContent(__, order, orderType, basketInstance, orderCompletePage)}
                    </BoxHeader>
                    <Spacer size={2} />
                    <StrongText className='roboto-slab big-text'>{__('Items Ordered')}</StrongText>
                    <OrderContent basketInstance={basketInstance} type="orderHistory" />
                    <AmountPaid order={order} cards={cards} />
                  </Box>
                </>
              )}
            </div>
          </div>
        </Layout>
        <IonAlert
          isOpen={this.props.cancelOrderModal === true}
          onDidDismiss={() => this.handleCancelOrderModal(false)}
          header={__('Confirm')}
          message={__('Do you want to cancel this order?')}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: __('Remove'),
              handler: () => this.cancelOrder(),
            },
          ]}
        />
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const orders = store.orders;
  const { cancelOrderModal } = orders;
  const { restaurants } = store.restaurants;
  let lastOrder = null;
  const orderHistory = orders.orderHistory;
  if (orderHistory && orderHistory.length > 0) {
    lastOrder = orderHistory[0];
  }
  return {
    cards: orders.cards || [],
    lastOrder,
    restaurants: restaurants || [],
    cancelOrderModal,
  };
};

export default connect(mapStateToProps)(withTranslation(HistoryDetails));
