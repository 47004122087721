import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import Mobiscroll from '../../components/mobiscroll';
import Basket from '../../lib/basket';
import {
  Title,
  FieldError,
  Spacer,
  Sectiontitle,
  NormalText,
  StrongText,
  SmallText,
} from '../../components/common';
import {
  IonButton,
  IonItem,
  IonInput,
  IonList,
  IonRadioGroup,
  IonLabel,
  IonRadio,
  IonCheckbox,
  IonIcon,
} from '@ionic/react';
import { isDefined, checkForDeliveryOption, isWebConfig, deepCopy, isObject, parseAllergenData, forwardTo, goBack } from '../../lib/utils';
import moment from '../../lib/moment';
import {
  setCommonModal,
  getRestaurants,
  getIkentooMenu,
  getIkentooMenusForLocation,
  setDeliveryOption,
  passedTheOrder,
  showToast,
} from '../../store/actions';
import { SET_RESTAURANT_PROP, SHOW_TOAST } from '../../store/constants';
import NoData from '../../components/noData';
import { getMenusForSelectedTime, formatDataForTime, checkSnoozedTimes } from '../clickAndCollect';
import './index.css';
import { getConfig } from '../../appConfig';
import api from '../../lib/api';
import { Capacitor } from '@capacitor/core';
import { arrowForwardOutline } from 'ionicons/icons';
const isWeb = () => Capacitor.getPlatform() === 'web';

class OrderToTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRestaurant:
        props.location.state && props.location.state.selectedRestaurant
          ? this.props.restaurants.find((restaurant) => restaurant.id === props.location.state.selectedRestaurant.id)
          : null, error: '',
      isLocationAllowed: false,
      tableNumber:
        props.location.state && props.location.state.selectedRestaurant && props.location.state.sameDay
          ? props.location.state.selectedRestaurant.table_name : '',
      showTableNumber: false,
      continueButtonClicked: false,
      selectedTown: null
    };
  }


  componentDidMount() {
    const { restaurants } = this.props;
    Basket.setOrderType('table');
    if (this.props?.location?.state?.selectedRestaurant) {
      const selectedRestaurant = restaurants.find((restaurant) => restaurant.id === this.props?.location?.state?.selectedRestaurant?.restaurant_id)
      if (selectedRestaurant.is_published && selectedRestaurant.can_table_order) {
        this.setState({ selectedRestaurant }, () => {
          Basket.setRestaurant(
            this.state.selectedRestaurant
          );
          this.setState({ showTableNumber: true, pickTime: moment(Date.now()).format('HH:mm') })
          Basket.setServicePercentage(0);
          Basket.setCollectionTime(null);
        })
      } else {
        forwardTo('/history', { tab: 'order' });
        this.props.dispatch(
          showToast(
            this.props.__('This restaurant is currently not accepting orders'),
            'warning',
          ),
        );
      }

    }

    const deliveryOption = checkForDeliveryOption(
      Basket.getDeliveryOption(),
      '/order-to-table',
    );
    if (deliveryOption) {
      this.props.dispatch(getRestaurants());
      this.props.dispatch(setDeliveryOption(deliveryOption));
    }
    if (Basket.getMenu()) {
      this.setState({ selectedIkentooMenu: Basket.getMenu() });
    }
    if (isWeb()) {
      this.position();
    }
  }

  componentDidUpdate(prevProps) {
    checkForDeliveryOption(this.props.deliveryOption, '/order-to-table');
    if (this.props.ikentooMenusForLocation.length !== prevProps.ikentooMenusForLocation.length) {
      if (this.props.ikentooMenusForLocation[0]) {
        this.setState({ selectedIkentooMenu: this.props.ikentooMenusForLocation[0].ikentooMenuId });
      }
    }
  }

  selectRestaurant = (selectedRestaurant) => {
    const { restaurants, profile } = this.props;
    this.setState(
      { selectedRestaurant, pickTime: moment(Date.now()).format('HH:mm'), showTableNumber: true },
      () => {
        Basket.reset(profile.cardToken);
        Basket.setRestaurant(
          restaurants.find((restaurant) => restaurant.id === selectedRestaurant.id),
        );
        if (Basket.getRestaurant()) {
          if (getConfig().general.hasServiceCharge) {
            if (Basket.getRestaurantServiceCharge()) {
              const service_charge_original = Basket.getRestaurantServiceCharge();
              Basket.setServicePercentage(parseInt(service_charge_original[1] * 100));
            } else {
              const defaultServiceCharge = JSON.parse(getConfig().general.defaultServiceCharge);
              Basket.setServicePercentage(parseInt(defaultServiceCharge[1] * 100));
            }
          } else {
            Basket.setServicePercentage(0);
          }
        }
        Basket.setCollectionTime(null);
        Basket.setOrderType('table');
      },
    );
  };

  position = async () => {
    await navigator.geolocation.getCurrentPosition(
      () => {
        this.setState({
          isLocationAllowed: true,
        });
      },
      (err) => console.log(err),
    );
  };

  formatDataForSelect = (stores, selectedTown) => {
    const { myLocation } = this.props;
    let formatedStores = [];
    let filteredStores = stores;
    if(selectedTown) {
      filteredStores = filteredStores.filter((store)=> store.town === selectedTown);
    }
    filteredStores.forEach((store) => {
      const currentDT = moment();
      let minDT = currentDT;
      if (store && isDefined(store.order_slot_lead_time)) {
        minDT.add(store.order_slot_lead_time, 'minutes');
      }
      const snoozedTimes = checkSnoozedTimes(store, 'table');

      const timeData = formatDataForTime(store, minDT, store.id, false, true, snoozedTimes);
      store.opened = timeData.length === 1 && timeData[0].text.toLowerCase() === 'closed' ? 'Closed' : 'Open';
      if (store.is_published && store.can_table_order) {
        formatedStores.push(store);
      }
    });
    if (this.state.isLocationAllowed || (myLocation?.latitude && myLocation?.longitude)) {
      formatedStores.sort(function (a, b) {
        return a.distance < b.distance ? -1 : a.distance > b.distance ? 1 : 0;
      });
    } else {
      formatedStores.sort(function (a, b) {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });
    }
    return formatedStores;
  };

  getMenuForReorder = (menus, reorderItems) => {
    let menuForReorder = [];
    for (let i = 0; i < menus.length; i++) {
      let foundItems = 0;
      const categoryItems = menus[i].menuEntry || menus[i].menuEntryGroups || null;
      let items = Basket.flattenMenuItems(deepCopy(categoryItems));
      menus[i].flattenMenuItems = items;
      if (items.length > 0) {
        for (let j = 0; j < reorderItems.length; j++) {
          let foundItem = items.find((i) => i.sku === reorderItems[j].item.sku);
          if (foundItem) {
            foundItems = foundItems + 1;
          }
        }
      }
      menus[i].foundItems = foundItems;
      menuForReorder.push(menus[i]);
    }
    menuForReorder.sort((a, b) => b.foundItems - a.foundItems);
    if (menuForReorder[0] && menuForReorder[0].foundItems > 0) {
      return this.filterMenu(menuForReorder[0])
    } else {
      return false;
    }
  };
  chooseMenusForLocation = async () => {
    const { restaurants, dispatch } = this.props;
    const { selectedRestaurant, pickTime, tableNumber } = this.state;
    this.setState({ continueButtonClicked: true }, async () => {
      Basket.setPassedOrder(true);
      if (selectedRestaurant && pickTime) {
        const choosenRestaurant = restaurants.find(
          (restaurant) => restaurant.id === selectedRestaurant.id,
        );
        const businessLocationId = choosenRestaurant.business_location_id;
        let cutoffTimeRes = moment().unix();
        Basket.setCutoffTime(cutoffTimeRes);
        Basket.setTableNumber(tableNumber);
        if (this.props.location.state && this.props.location.state.selectedRestaurant) {
          let ikentooMenusForLocation = await api.getIkentooMenusForLocation(businessLocationId);
          ikentooMenusForLocation = getMenusForSelectedTime(
            ikentooMenusForLocation,
            pickTime,
            choosenRestaurant.table_json_time_selector,
          );
          if (ikentooMenusForLocation && isObject(ikentooMenusForLocation[0])) {
            let ikentooMenus = [];
            try {
              for (let i = 0; i < ikentooMenusForLocation.length; i++) {
                ikentooMenus.push(
                  await api.getIkenooMenu(ikentooMenusForLocation[i].ikentooMenuId, businessLocationId),
                );
              }
            } catch (error) {
              this.setState({ error: 'Get restaurant menu error.' });
            }
            const reorderItems = this.props.location.state.selectedRestaurant.items;
            const menu = this.getMenuForReorder(ikentooMenus, reorderItems);
            if (menu) {
              dispatch({
                type: SET_RESTAURANT_PROP,
                key: 'ikentooMenu',
                value: menu,
              });
              if (menu.flattenMenuItems.length > 0) {
                let validationStatus = { notBasketEmpty: false, validationError: false };
                reorderItems.map((newBasketItem, index) => {
                  let foundItem = menu.flattenMenuItems.find((i) => i.sku === newBasketItem.item.sku);
                  if (foundItem && Basket.isProductEnabled(foundItem)) {
                    Basket.addToBasket(newBasketItem);
                    let item = newBasketItem.item;
                    let allergens = this.props.allergens;
                    let profile = this.props.profile;
                    let newArr = parseAllergenData(profile, item, allergens);
                    let allergensCodes =
                      newBasketItem.item?.itemRichData?.allergenCodes?.length > 0
                        ? newBasketItem.item.itemRichData.allergenCodes
                        : [];
                    if (allergensCodes.length > 0) {
                      let allergensData = [{ allergens: newArr }, { sku: newBasketItem.item.sku }];
                      Basket.setAllergen(allergensData);
                    }
                    validationStatus.notBasketEmpty = true;
                  } else {
                    validationStatus.validationError = true;
                  }
                });
                if (validationStatus.notBasketEmpty) {
                  if (validationStatus.validationError) {
                    dispatch(
                      showToast(
                        'Some items were not added to your basket as they are currently unavailable',
                        'warning',
                      ),
                    );
                    this.setState({ continueButtonClicked: false })
                  }
                  forwardTo('/order-summary', { skipBackToThePreviousPage: false });
                } else {
                  dispatch(
                    showToast(
                      'Some items were not added to your basket as they are currently unavailable',
                      'warning',
                    ),
                  );
                  this.setState({ continueButtonClicked: false })
                }
              }
            } else {
              dispatch(showToast('Menu not found', 'warning'));
              this.setState({ continueButtonClicked: false })
            }
          }
          else {
            dispatch(
              showToast(
                'This service is currently unavailable!',
                'warning',
              ),
            );
            this.setState({ continueButtonClicked: false })
          }
        } else {
          dispatch(
            getIkentooMenusForLocation(businessLocationId, {
              pickTime,
              json_time_selector: choosenRestaurant ? choosenRestaurant.table_json_time_selector : [],
            }),
          );
          this.setState({ continueButtonClicked: false })
        }
      } else if (!selectedRestaurant) {
        this.setState({ error: 'Please select location' });
        this.setState({ continueButtonClicked: false })
      } else {
        this.setState({ error: 'Please select pickup time' });
        this.setState({ continueButtonClicked: false })
      }
    })
  };

  changeIkentooMenus = (event) =>
    this.setState({ selectedIkentooMenu: event.detail.value, error: '' }, () => {
      Basket.setMenu(event.detail.value);
    });

  continueOnMenu = () => {
    const { restaurants, dispatch } = this.props;
    const { selectedIkentooMenu, selectedRestaurant } = this.state;
    if (selectedRestaurant && selectedIkentooMenu) {
      const choosenRestaurant = restaurants.find(
        (restaurant) => restaurant.id === selectedRestaurant.id,
      );
      const businessLocationId = choosenRestaurant.business_location_id;
      dispatch(getIkentooMenu(selectedIkentooMenu, businessLocationId));
      dispatch(passedTheOrder(true));
      Basket.setPassedOrder(true);
    } else {
      this.setState({ error: 'Please select location menu' });
    }
  };
  getKitchenStatusColor(status) {
    let color = ''
    switch (status) {
      case 0:
        color = 'success';
        break;
      case 1:
        color = 'warning';
        break;
      case 2:
        color = 'danger'
    }
    return color
  }
  filterIkentooMenuItems = (items) => {
    let filteredItems = [];
    items.forEach((item) => {
      if (!item.menuEntry) {
        // break recursion when arrive to the product
        if (item.sku && Basket.isProductEnabled(item)) {
          filteredItems.push(item);
          return [item];
        } else {
          return [];
        }
      } else {
        const len = (item.menuEntry || []).length;
        if (len > 0) {
          const newFilteredItems = this.filterIkentooMenuItems(item.menuEntry);
          if (newFilteredItems.length > 0) {
            item.menuEntry = newFilteredItems;
            filteredItems.push(item);
          }
        }
      }
    });

    return filteredItems;
  };

  filterMenu = (menu) => {
    if (menu && menu.menuEntryGroups) {
      menu.menuEntryGroups = this.filterIkentooMenuItems(menu.menuEntryGroups);
      return menu;
    }
    return menu;
  };

  getAvaibleTowns = (stores = []) => {
    const towns = [];
    stores.forEach((store)=>{
      if(store.town && store.town !== '' && store.can_table_order){
        towns.push(store.town);
      }
      
    })
    return [...new Set(towns)];
  }

  backHandler = () => {
    const { showTableNumber, selectedTown } = this.state;
    if ( showTableNumber ) {
      this.setState({showTableNumber : false});
    } else if(!showTableNumber && selectedTown){
      this.setState({selectedTown : null});
    } else {
      goBack();
    }
  }

  selectStore = (store) => {
    this.setState({ selectedRestaurant: store });
  };
  render() {
    const {
      __,
      deliveryOption,
      restaurants,
      isChooseMenuModalOpen,
      ikentooMenusForLocation,
    } = this.props;
    const {
      error,
      selectedIkentooMenu,
      pickTime,
      selectedRestaurant,
      showTableNumber,
      tableNumber,
      selectedTown
    } = this.state;
    const stores = restaurants || [];
    const animationMenuClass = isChooseMenuModalOpen ? 'show-up' : '';
    const store = restaurants.find((restaurant) => restaurant.id === selectedRestaurant?.id) || null;
    const menus = getMenusForSelectedTime(
      ikentooMenusForLocation,
      pickTime,
      store ? store.table_json_time_selector : [],
    );
    const formatedStores = this.formatDataForSelect(stores, selectedTown);
    const towns = this.getAvaibleTowns(stores);
    return (
      <Layout
        scrollY={false}
        hideSecondToolbar={true}
        headerTitle={__(deliveryOption ? deliveryOption.label : '')}
        className=" click-collect-page"
        color="transparent"
        backHandler={this.backHandler}
      >
        {!showTableNumber && !selectedTown && (
          <div className="click-collect-layout">
            <div className="click-collect-wrapper">
              <div className="click-collect-dialog-header">
                <Title>{__('Order To Table')}</Title>
                <NormalText >{__('Start an order to be delivered to your table as soon as possible. This service is available for in-store use only')}.</NormalText>
              </div>
              <Spacer />
              <div className="click-collect-locations">
                <IonList>
                  {towns.map((town) => (
                    <div className="click-collect-items" onClick={() => this.setState({ selectedTown: town })}>
                      <IonItem
                        lines="none"
                      >
                        <NormalText className=''>{__(town)}</NormalText>
                      </IonItem>
                      <IonIcon className='arrow-right-icon' mode="ios" icon={arrowForwardOutline} />
                    </div>
                  ))}
                </IonList>
              </div>
            </div>
            <div className="click-collect-button">
              <IonButton
                disabled={!selectedRestaurant}
                expand="block"
                color="secondary"
                onClick={() => this.selectRestaurant(selectedRestaurant)}
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
        )}
        {!showTableNumber && selectedTown && (
          <div className="click-collect-layout">
            <div className="click-collect-wrapper">
              <div className="click-collect-dialog-header">
                <Title>{__('Order To Table')}</Title>
                <NormalText >{__('Start an order to be delivered to your table as soon as possible. This service is available for in-store use only')}.</NormalText>
              </div>
              <Spacer />
              <div className="click-collect-locations">
                <IonList>
                  {formatedStores.map((store) => (
                    <div className="click-collect-items">
                      <IonItem
                        disabled={store.opened === 'Closed'}
                        lines="none"
                        onClick={() => this.selectStore(store)}
                      >
                        <NormalText>{__(store.name)}</NormalText>
                      </IonItem>
                      <IonCheckbox
                        checked={selectedRestaurant && store.id === selectedRestaurant.id}
                        slot="end"
                        color="secondary"
                        onClick={() => this.selectStore(store)}
                        disabled={store.opened === 'Closed'}
                      />
                    </div>
                  ))}
                </IonList>
              </div>
            </div>
            <div className="click-collect-button">
              <IonButton
                disabled={!selectedRestaurant}
                expand="block"
                color="secondary"
                onClick={() => this.selectRestaurant(selectedRestaurant)}
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
        )}
        {showTableNumber && (
          <div className="click-collect-layout">
            <div>
              <Loading transparent>
                <div className="click-collect-wrapper">
                  <div className="click-collect-dialog-header">
                    <Title>{__('Order To Table')}</Title>
                    <NormalText >{__('Start an order to be delivered to your table as soon as possible. This service is available for in-store use only')}.</NormalText>
                  </div>
                <Spacer />
                </div>
                <div className="click-collect-dialog-content">
                <IonLabel>{__('Location')}</IonLabel>
                <div className="click-collect-items" onClick={() => this.setState({showTableNumber: false})}>
                  <IonItem
                    disabled={store.opened === 'Closed'}
                    lines="none"
                  >
                    <NormalText>{__(store.name)}</NormalText>
                  </IonItem>
                </div>
                </div>
                <Spacer size={1} />
                <div className="">
                  <IonLabel>{__('Table number')}</IonLabel>
                  <IonInput
                    className="table-number-input"
                    onIonChange={(e) => this.setState({ tableNumber: e.target.value })}
                    onKeyPress={(e) => {
                      const reqexFormat = tableNumber ? '^[0-9]+$' : '^[+0-9]+$';
                      let regex = new RegExp(reqexFormat);
                      if (regex.test(e.key)) {
                        return true;
                      }
                      e.preventDefault();
                      return false;
                    }}
                    value={tableNumber}
                    maxlength={5}
                    inputmode="numeric"
                    placeholder="Enter your table number"
                  />
                  {error ? (
                    <IonItem>
                      <div tabIndex="-1"></div>
                      <FieldError className="field-error" value={__(error)} />
                    </IonItem>
                  ) : null}
                </div>
                <Spacer size={2} />
              </Loading>
              <div className="click-collect-button">
              <IonButton
                  disabled={tableNumber ? false : true}
                  expand="block"
                  color="secondary"
                  className={this.state.continueButtonClicked ? 'unclicked' : ''}
                  onClick={() => this.chooseMenusForLocation()}
                >
                  {__('Continue')}
                </IonButton>
              </div>
            </div>
          </div>
        )}
        <div className={`click-collect-dialog ${animationMenuClass}`}>
          <div className="click-collect-dialog-layout sc-ion-modal-md">
            <div className="click-collect-dialog-header">
              <h3>{__('Choose menu')}</h3>
            </div>
            <div
              className="click-collect-dialog-closer"
              style={{ position: 'absolute', right: 0, top: 0 }}
              onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
            >
              <ion-icon
                name="close"
                role="img"
                className="md hydrated"
                aria-label="close"
              ></ion-icon>
            </div>
            <div className="click-collect-dialog-content">
              <IonList lines="none">
                <IonRadioGroup onIonChange={this.changeIkentooMenus} value={selectedIkentooMenu}>
                  {menus.length === 0 ? (
                    <NoData />
                  ) : (
                    menus.map((menu) => {
                      const { ikentooMenuId, menuName } = menu;
                      return (
                        <IonItem key={ikentooMenuId} lines="full">
                          <div tabIndex="-1"></div>
                          <IonLabel className="ion-text-wrap">
                            <Sectiontitle>{menuName}</Sectiontitle>
                          </IonLabel>
                          <IonRadio
                            color={isWebConfig() ? 'secondary' : 'white'}
                            slot="start"
                            value={ikentooMenuId}
                          />
                        </IonItem>
                      );
                    })
                  )}
                </IonRadioGroup>
              </IonList>
            </div>
            <div className="click-collect-dialog-action">
              {error ? (
                <IonItem>
                  <div tabIndex="-1"></div>
                  <FieldError className="field-error" value={__(error)} />
                </IonItem>
              ) : null}
              <IonButton
                disabled={pickTime && menus.length > 0 ? false : true}
                expand="block"
                color="secondary"
                className="okx-font-secondary round-button"
                onClick={() => this.continueOnMenu()}
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { deliveryOption } = state.orders;
  const { restaurants, ikentooMenusForLocation } = state.restaurants;
  const { isChooseMenuModalOpen, myLocation } = state.common;
  return {
    deliveryOption,
    restaurants: restaurants || [],
    profile: state.profile.profile,
    isChooseMenuModalOpen: isChooseMenuModalOpen,
    ikentooMenusForLocation: ikentooMenusForLocation || [],
    myLocation: myLocation,
  };
};

export default connect(stateToProps)(withTranslation(OrderToTable));