/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { IonButton, IonItem, IonInput, IonLabel } from '@ionic/react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
// import { close } from 'ionicons/icons'
import { withTranslation } from '../../lib/translate';
import { decodeBase64, deepIsDefined, goBack, isDefined, isEmptyObject, validateForm } from '../../lib/utils';
import { sendVoucherCode, checkDiscount, showToast } from '../../store/actions';
import Layout from '../../components/layout';
import {
  Title, Spacer, FieldError, /*, NormalText, SmallText*/
  NormalText,
  Subtitle
} from '../../components/common';
import plateImage from '../../assets/images/icons/eat.png'
import Basket from '../../lib/basket';
import Modal from '../../components/modal';
import { getConfig } from '../../appConfig';
import './index.css';
import BottomAlert from '../../components/bottomAlert';
import QRCodeCanvas from 'qrcode.react';
import moment from 'moment';

const VoucherItem = ({ reward, id, className, __, onClick, buttonText, onClickButton }) => {
  return (
    <div
      onClick={onClick ? () => onClick(id) : null}
      className={' box-wrapper-black' + (className ? ' ' + className : '')}
    >
      <Title>{reward.name}</Title>
      <NormalText>{reward.small_print}</NormalText>
      <IonButton
        className='cover'
        onClick={onClickButton ? () => onClickButton(id) : null}
        color='gray'
      >
        {buttonText}
      </IonButton>

    </div>
  );
};

class ApplyVouchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discounts: null,
      selectedVoucher: null,
      showModal: null,
      voucherCode: '',
      disabledAppliedButton: false,
      formErrors: {
        voucherCode: '',
      },
      isRedeemCodeModalOpen: false,
    };
    this.formConfig = {
      voucherCode: { type: 'text', required: true },
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(checkDiscount(Basket.parseBasketForCheckVouchers()));
  }


  setVoucher = (voucher) => {
    this.setState({ selectedVoucher: voucher }, () => {
      const { vouchers, applicableVouchers } = this.props;
      const { selectedVoucher } = this.state;
      const appliedVoucher = vouchers.find((voucher) => voucher.id === selectedVoucher.id);
      const applicableVoucher = applicableVouchers.find(
        (appliableVoucher) => appliableVoucher.id === selectedVoucher.id,
      );
      if (Basket.canVoucherBeApplied(appliedVoucher, applicableVoucher)) {
        this.setState({ disabledAppliedButton: true });
        Basket.applyPoints(0)
      } else {
        this.setState({ disabledAppliedButton: false });
      }
    });
  };

  addVoucher = (id) => {
    const { vouchers, applicableVouchers, dispatch, __ } = this.props;
    const appliedVoucher = vouchers.find((voucher) => voucher.id === id);
    const applicableVoucher = applicableVouchers.find(
      (appliableVoucher) => appliableVoucher.id === id,
    );
    if (Basket.canVoucherBeApplied(appliedVoucher, applicableVoucher)) {
      this.setState({ selectedVoucher: null }, () => {
        Basket.addVoucher(appliedVoucher, applicableVoucher);
        goBack();
      });
    } else {
      dispatch(
        showToast(
          __('Your basket is higher then voucher cost. Please add more items in basket'),
          'warning',
        ),
      );
    }
  };

  showModal = (show) => {
    this.setState({ showModal: show });
    this.clearFields();
  };

  clearFields = () => {
    this.setState({ voucherCode: '' });
  };

  sendCode = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { voucherCode } = this.state;
      const data = { code: voucherCode.toUpperCase() };
      this.clearFields();
      dispatch(sendVoucherCode(data));
      this.showModal(false);
    }
  };

  filterVouchers = (vouchers, appliedVouchers) => {
    const { applicableVouchers, availableBalance } = this.props;
    let filteredVouchers = [];
    if (applicableVouchers && applicableVouchers.length > 0) {
      applicableVouchers.forEach((discount) => {
        let findedVoucher = vouchers.find((voucher) => voucher.id === discount.id);
        if (getConfig().vouchersType === 2) {
          if (findedVoucher && availableBalance > findedVoucher.cost) {
            filteredVouchers.push(findedVoucher);
          }
        } else {
          if (findedVoucher) {
            filteredVouchers.push(findedVoucher);
          }
        }
      });
    }
    if (appliedVouchers && appliedVouchers.length > 0) {
      const ids = appliedVouchers.map((i) => i.id);
      return filteredVouchers.filter((filteredVoucher) => ids.indexOf(filteredVoucher.id) === -1);
    }
    return filteredVouchers;
  };

  viewVoucher = (id) => {
    const { vouchers } = this.props;
    this.setState({ selectedVoucher: vouchers.find((v) => v.id === id) });
  };

  render() {
    const { __, vouchers, qr_code } = this.props;
    const { selectedVoucher, voucherCode, showModal, disabledAppliedButton, isRedeemCodeModalOpen } = this.state;
    const appliedVouchers = Basket ? Basket.applied_vouchers : [];
    const filteredVouchers = this.filterVouchers(vouchers, appliedVouchers);
    return (
      <>
        {(!filteredVouchers || filteredVouchers.length === 0) ? (
          <Subtitle color="gray">{__("Your vouchers will appear here")}</Subtitle>
        ) : null}
        {filteredVouchers.length > 0 &&
          filteredVouchers.map((voucher, index) => {
            return (
              <VoucherItem
                key={'vc-' + index}
                {...voucher}
                __={__}
                onClick={this.viewVoucher}
                buttonText="Use Voucher"
                onClickButton={this.addVoucher}
              />
            );
          })}
        <Spacer size={2} />
        {/* <div className="flex-min">{clickupContent}</div> */}
        <IonButton className='bottom-button' color='secondary' onClick={() => this.setState({ isRedeemCodeModalOpen: true })}>
          {__('Redeem Code')}
        </IonButton>
        {isRedeemCodeModalOpen ? (
          <BottomAlert
            onClose={() => this.setState({ isRedeemCodeModalOpen: false })}
            smallTitle={__('Redeem Code')}
            description={__('Enter a valid code below to receive your reward')}
            showPullDownArrow={__(true)}
          >
            <IonItem lines="none" className='voucher-code-input-wrapper'>
              <IonLabel position="floating">{__('Offer Code')}</IonLabel>
              <IonInput
                className='voucher-code-input'
                value={voucherCode}
                onIonChange={(e) => this.setState({ voucherCode: e.target.value })}
                placeholder='Enter the code'
              ></IonInput>
            </IonItem>
            <Spacer size={1} />
            <div className='bottom-alert-buttons'>
              <IonButton
                className='inner-padding one-button'
                expand="block"
                color='white'
                disabled={voucherCode === ''}
                onClick={this.sendCode}
              >
                {__('Submit')}
              </IonButton>
            </div>
          </BottomAlert>
        ) : null}
        {selectedVoucher ? (
          <BottomAlert
            showXButton={true}
            onClose={() => this.setState({ selectedVoucher: null })}
          >
            <Title className='selected-voucher-title'>
              {selectedVoucher.reward.name}
              <NormalText>{selectedVoucher.reward.small_print}</NormalText>
            </Title>
            <Spacer size={1} />
            <>
              {isDefined(qr_code) ? (
                <div className="qr-holder">
                  <QRCodeCanvas value={qr_code} size={150} />
                </div>
              ) : (
                <div className="noQrCode">
                  <h5>{__('NO QR CODE')}</h5>
                </div>
              )}
            </>
            <Spacer size={1} />
            {selectedVoucher.reward.expiry_date ? (
              <div>
                <NormalText>
                  {__('Expiry Date:')}{' '}
                  {moment(selectedVoucher.reward.expiry_date, 'YYYY/MM/DD').format('DD/MM/YY')}
                </NormalText>
              </div>
            ) : null}
            <Spacer size={2} />
            {selectedVoucher.reward.description ? (
              <div dangerouslySetInnerHTML={{ __html: decodeBase64(selectedVoucher.reward.description) }}>
              </div>
            ) : null}
          </BottomAlert>
        ) : null}
        <Modal
          className="apply-voucher-modal"
          isOpen={!!showModal}
          onDidDismiss={() => this.setState({ showModal: null })}
          title={__('Enter code')}
        >
          <div className="flex-row-wrapper">
            <div className="centered flex-row-wrapper">
              <div className=" centered scrollable-y">
                <NormalText>{__('Enter a valid voucher code below to receive your reward')}</NormalText>
                <div className="lefted padded voucher-item">
                  <IonItem style={{ '--min-height': '32px' }}>
                    <IonInput
                      value={voucherCode}
                      onIonChange={(e) => this.setState({ voucherCode: e.target.value })}
                      placeholder={__('Voucher code')}
                    />
                  </IonItem>
                  <FieldError
                    className="field-error"
                    value={__(this.state.formErrors.voucherCode)}
                  />
                </div>
              </div>
            </div>
            <div className="flex-min">
              <IonButton onClick={() => this.sendCode()} expand="full" color="secondary">
                {__('Submit')}
              </IonButton>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    availableBalance: deepIsDefined(store, 'profile.profile.available_balance')
      ? store.profile.profile.available_balance
      : 0,
    basketUpadated: store.orders.basketUpdated,
    vouchers: store.profile.vouchers || null,
    applicableVouchers: store.profile.applicableVouchers || null,
    qr_code: store.profile.profile.qr_code,
  };
};

export default connect(mapStateToProps)(withTranslation(ApplyVouchers));
