import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IonIcon, IonGrid, IonCol, IonRow, IonRippleEffect } from '@ionic/react';
import { logoTwitter, logoLinkedin, logoInstagram, globe } from 'ionicons/icons';
import { getSocials } from '../../store/actions';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { Title, SmallText, Spacer, NormalText } from '../../components/common';
import { ucWords, isDefined, isEmptyObject, openExternalLink } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import './index.css';
import NoData from '../../components/noData';
import logoFacebook from '../../assets/images/logo-facebook.svg';

export const parseSocialItem = (key = '', value = '') => {
  let label = key;
  let icon = key;
  let link = value;
  let uppercasedKey = key
    .split('')
    .map((char, i) => (i === 0 ? char.toUpperCase() : char))
    .join('');
  switch (uppercasedKey) {
    case 'Facebook':
      icon = logoFacebook;
      break;
    case 'Twitter':
      icon = logoTwitter;
      break;
    case 'Instagram':
      icon = logoInstagram;
      break;
    case 'Linkedin':
      icon = logoLinkedin;
      break;
    default:
      icon = globe;
      break;
  }
  return {
    link,
    icon,
    label,
  };
}

class Social extends Component {
  componentDidMount() {
    this.props.dispatch(getSocials());
  }

  render() {
    const { __, social } = this.props;
    return (
      <Loading>
        <Layout hideSecondToolbar={true} color="transparent" headerTitle={__('Social Media')}>
          <div className="header-wrapper">
            <Title>{__('Social Media')}</Title>
            <NormalText  >
              {__('Get the latest info and offers by following us on our social media channels')}
            </NormalText>
          </div>
          <Spacer size={2} />
          <div className="frm-wrapper">
            {isDefined(social) && !isEmptyObject(social) ? (
              <IonGrid className="social">
                <IonRow>
                  {Object.keys(social || {}).map((key, index) => {
                    const value = social[key];
                    const { link, icon, label } = parseSocialItem(key, value);
                    const capitalizeLabel = ucWords(label);
                    return (
                      <IonCol key={'soc-item-' + index} size="12">
                        <div
                          className="square ion-activatable"
                          onClick={() => openExternalLink(link)}
                        >
                          <div className="content">
                            <div className="soc-icon">
                              <IonIcon icon={icon} />
                            </div>
                            <SmallText className="soc-label uppercase bold">
                              {__(capitalizeLabel)}
                            </SmallText>
                          </div>
                          <IonRippleEffect></IonRippleEffect>
                        </div>
                      </IonCol>
                    );
                  })}
                </IonRow>
              </IonGrid>
            ) : (
              <NoData label={__('No social networks')} />
            )}
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { social } = state.common;
  return {
    social,
  };
};

export default connect(stateToProps)(withTranslation(Social));
