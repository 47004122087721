import React from 'react';
import { IonCard, IonCardContent, IonButton, IonInput, IonItem, IonLabel } from '@ionic/react';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { Title, Spacer, FieldError, SmallText, StrongText, Subtitle } from '../../components/common';
import { isWebConfig, validateForm } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { sendRefer } from '../../store/actions';
import { getConfig } from '../../appConfig';
import './index.css';
import { connect } from 'react-redux';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';

const isWeb = () => Capacitor.getPlatform() === 'web';

class ReferAFriend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      formErrors: {},
    };
    this.formConfig = {
      email: { type: 'email', required: true },
    };
  }

  handleInput(key, val) {
    this.setState({ [key]: val });
  }

  handleRefer = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { email } = this.state;
      const data = { email: email };
      this.setState({ email: '' });
      dispatch(sendRefer(data));
    }
  };

  render() {
    const { __, profile } = this.props;
    const { email } = this.state;
    const { flags, api_config, shareConfig } = getConfig();
    
    return (
      <Loading transparent>
        <Layout hideSecondToolbar={true} color="transparent" headerTitle={__('Refer a Friend')}>
          <div className="absolute-content" title="Refer a friend"></div>
          <IonCard color="white" class="raf-card">
            <IonCardContent className="flex-row-wrapper">
              <Title>{__('Refer a Friend')}</Title>
              <div>
                <div className="raf-info">
                  {__(
                    `Once your friend has signed up and used the app you will both receive a reward of ${api_config?.refer_a_friend_stamp_power || 10} points!`,
                  )}
                </div>
                <Spacer size={1} />
                {
                  flags?.hasReferCode ? 
                  (
                    <>
                      <IonItem>
                        <Subtitle>{__('Your referral code:')} </Subtitle>
                        <Subtitle className='uppercase refer-code-text'>{profile?.referrer_code ? profile.referrer_code : ''}</Subtitle>
                      </IonItem>
                      <Spacer size={1}/>
                      <div className="raf-info">
                        {__(
                          'Ask your friend to use your referral code when they sign up,and you will both receive your reward.',
                        )}
                      </div>
                      <Spacer size={2}/>
                      { !isWeb() && 
                        <IonButton
                          expand="block"
                          color="secondary"
                          strong={true}
                          onClick={async () => 
                            await Share.share({
                              title: __(shareConfig?.shareTitle || ''),
                              text: `${__(shareConfig?.shareText || '')} ${__(profile?.referrer_code ? profile.referrer_code : '')}`,
                              dialogTitle: __(shareConfig?.shareDialogTitle || ''),
                              url: `${shareConfig?.shareUrl || ''}`
                            })
                          }
                        >
                          {__(shareConfig?.shareButtonText || '')}
                        </IonButton>
                      }
                    </>
                  ) : (
                    <>
                      <IonLabel>{__('Email address')}</IonLabel>
                      <IonItem lines='none'>
                        <div className="inline-input">
                          <IonInput
                            className="refer-fiend-email"
                            onIonChange={(e) => this.handleInput('email', e.target.value)}
                            required
                            placeholder={__("Enter your friend's email address")}
                            type="email"
                            pattern="email"
                            inputmode="email"
                            value={email}
                          />
                        </div>
                      </IonItem>
                      <FieldError className="field-error" value={__(this.state.formErrors.email)} />
                      <Spacer size={2}/>
                      <IonButton
                        expand="block"
                        color="secondary"
                        strong={true}
                        onClick={() => this.handleRefer()}
                      >
                        {__('Send Invitation')}
                      </IonButton>
                    </>
                  )
                  
                }
              </div>
            </IonCardContent>
          </IonCard>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  return {
    profile: state.profile.profile
  };
};

export default connect(stateToProps)(withTranslation(ReferAFriend));
