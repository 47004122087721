import React from 'react';
import { IonIcon } from '@ionic/react';
import Basket from '../../lib/basket';
import { StrongText } from '../common';
import { arrowForwardOutline } from 'ionicons/icons';

const { formatPrice } = Basket;

export const SubscriptionLine = (__, clickHandler, key, name, description, price, className = '') => (
  <div className={`inline-input ${className}`}  onClick={clickHandler} key={key}>
    <div className='subscription-information'>
      <StrongText>{name}</StrongText>
      <div 
        className='subscription-information-description' 
        dangerouslySetInnerHTML={{ __html: description }}>
      </div>
      <div className='subscription-price'>
        <StrongText>{`${formatPrice(price)} ${__('per month')}`}</StrongText>
      </div>
    </div>
    <IonIcon className='arrow-right-icon' mode="ios" icon={arrowForwardOutline} />
  </div>
);
