import React from 'react';
import { withRouter } from 'react-router';
import { IonButton, isPlatform } from '@ionic/react';
import Basket from '../../lib/basket';
import LibStripe from '../../lib/stripe';
import { Capacitor } from '@capacitor/core';
import './index.css';
class DrawPayButton extends React.Component {
  state = {
    gPayBtn: null,
  };

  componentDidMount() {
    this.setGPayBtn(this.props.googlePayClient);
  }

  componentDidUpdate(prevProps) {
    if (this.props.googlePayClient !== prevProps.googlePayClient) {
      this.setGPayBtn(this.props.googlePayClient);
    }
  }

  setGPayBtn = (googlePayClient) => {
    if (googlePayClient) {
      const googlePayButton = googlePayClient.createButton({
        buttonColor: 'black',
        buttonSizeMode: 'fill',
        buttonType: 'long',
        onClick: () => this.handleClick('google'),
      });
      this.setState({ gPayBtn: googlePayButton });
    }
  };

  handleClick = (platform, e) => {
    if (this.props.isSubscription) {
      /**
       * TODO: Implement Google/Apple subscription button
       */
    } else {
      Basket.createOrder(platform);
    }
  };

  render() {
    const { gPayBtn } = this.state;
    return LibStripe.getStripeInstance() && Capacitor.getPlatform() !== 'web' ? (
      !isPlatform('ios') ? (
        <IonButton
          className="google-button"
          onClick={() => this.handleClick('google')}
          expand="block"
        ></IonButton>
      ) : (
        <IonButton
          className="apple-button"
          onClick={() => this.handleClick('apple')}
          expand="block"
          color="black"
        >
          <div className="apple-pay-button apple-pay-button-black">
            <span classNeme="logo"></span>
          </div>
        </IonButton>
      )
    ) : (
      <div
        className="google-pay-btn"
        onClick={(e) => this.handleClick('google', e)}
        dangerouslySetInnerHTML={{ __html: gPayBtn && gPayBtn.innerHTML }}
      ></div>
    );
  }
}

export default withRouter(DrawPayButton);
