import React, { Component } from 'react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import { NormalText, SmallText, Spacer, Subtitle, Title } from '../../components/common';
import { IonButton, IonIcon } from '@ionic/react';
import { goBack, openExternalLink } from '../../lib/utils';
import { getProfile } from '../../store/actions';
import inbox from '../../assets/images/icons/inbox.svg';
import messageBubble from '../../assets/images/message-bubble.svg';
import './index.css';

class Inbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMessage: null
    };
  }

  componentDidMount() {
    if (this.props.auth.loggedIn) {
      this.props.dispatch(getProfile());
    }
  }

  onRefreshHandler = (e) => {
    setTimeout(() => {
      this.props.dispatch(getProfile())
      e.target.complete();
    }, 2000);
  }

  backHandler(selectedMessage) {
    if (selectedMessage) {
      this.setState({ selectedMessage: null })
    } else {
      goBack();
    }
  }

  render() {
    const { __, notificationMessages } = this.props;
    const { selectedMessage } = this.state;
    const { messages } = notificationMessages;
    return (
      <Loading>
        <Layout
          backHandler={() => this.backHandler(selectedMessage)}
          hideSecondToolbar={true}
          color="transparent"
          headerTitle={__('Messages')}
          scrollY={true}
          onRefreshHandler={this.onRefreshHandler}
        >
          {selectedMessage ? (
            <>
              <Title>{selectedMessage.heading}</Title>
              <Spacer />
              {selectedMessage.show_image ? (
                <>
                  <img src={selectedMessage.image} />
                  <Spacer />
                </>
              ) : null}

              <Subtitle>{selectedMessage.heading}</Subtitle>
              <Spacer size={1} />
              <div dangerouslySetInnerHTML={{ __html: selectedMessage.content_html }}></div>
              {selectedMessage.show_cta ?
                (
                  <IonButton className='bottom-button' color='secondary' onClick={() => openExternalLink(selectedMessage.cta_url)} >
                    {__(selectedMessage.button_text || "")}
                  </IonButton>
                ) : null
              }
            </>
          ) : (
            <>
              <Title>{__('Messages')}</Title>
              {
                messages && messages.length > 0 ?
                  <>
                    <Spacer />
                    {
                      messages.map((message) => (
                        <div className="message-wrapper" onClick={() => this.setState({ selectedMessage: message })}>
                          
                          <IonIcon
                            icon={messageBubble}
                            className={`new-message-icon ${message.is_read ? "" : "new-message-icon-dotted" }`}
                          />
                          <div>
                            <Subtitle>
                              {message.heading}
                            </Subtitle>
                            <SmallText>{message.date}</SmallText>
                            <div dangerouslySetInnerHTML={{ __html: message.content_html }}></div>
                          </div>
                        </div>

                      ))
                    }
                  </>
                  : (
                    <div className='flex-row-holder centered'>
                      <img src={inbox} className="no-data-img" />
                      <NormalText>{__("Your messages will appear hire.")}</NormalText>
                      <NormalText>{__("Please check back later.")}</NormalText>
                    </div>
                  )
              }
            </>
          )}
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { navConfig } = state.common;
  const { profile, auth } = state.profile;
  let notificationMessages = {
    count: 0,
    unread_count: 0,
    messages: []
  };
  if (profile?.notification_messages) {
    notificationMessages = profile.notification_messages;
  } 
  return {
    profile: profile,
    auth: auth,
    notificationMessages: notificationMessages,
    navConfig: navConfig
  };
};

export default connect(stateToProps)(withTranslation(Inbox));
