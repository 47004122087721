import React from 'react';
import { connect } from 'react-redux';
import {
  IonButton,
  IonTextarea,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonAlert,
  IonCheckbox,
  IonInput,
} from '@ionic/react';
import { withRouter } from 'react-router';
import { starOutline, star } from 'ionicons/icons';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { sendFeedback, setCommonModal } from '../../store/actions';
import { FieldError, Title, SmallText, Spacer, NormalText, StrongText } from '../../components/common';
import { validateForm, isDefined } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Mobiscroll from '../../components/mobiscroll';
import './index.css';
import BottomAlert from '../../components/bottomAlert';

const { SelectOption } = Mobiscroll;

class Feedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.resetState(),
    };
    this.formConfig = {
      food: { type: 'integer', required: true, invalidValue: 0 },
      service: { type: 'integer', required: true, invalidValue: 0 },
      selectedRestaurant: { type: 'select', required: true, invalidValue: null },
    };
  }

  resetState = () => ({
    food: 0,
    service: 0,
    customerService: true,
    techSupport: false,
    commentService: '',
    commentTech: '',
    selectedRestaurant: null,
    formErrors: {},
  });

  createStarSet(size, name) {
    return <>{[...Array(size).keys()].map((i) => this.createStar(name, i + 1))}</>;
  }

  createStar = (name, value) => {
    if (this.state[name] >= value) {
      return (
        <IonIcon
          key={value}
          icon={star}
          className="star"
          color="primary"
          onClick={() => this.setValue(name, value)}
        />
      );
    } else {
      return (
        <IonIcon
          key={value}
          icon={starOutline}
          className="star"
          color="primary"
          onClick={() => this.setValue(name, value)}
        />
      );
    }
  };

  handleFeedback = () => {
    let formErrors;
    if (this.state.customerService) {
      formErrors = validateForm(this.formConfig, this.state);
    } else {
      formErrors = [];
    }
    this.setState({ formErrors });

    if (Object.keys(formErrors).length === 0) {
      const {
        food,
        service,
        commentService,
        commentTech,
        selectedRestaurant,
        techSupport,
        customerService,
      } = this.state;
      const feedback = {
        food,
        service,
        commentService,
        commentTech,
        selectedRestaurant,
        techSupport,
        customerService,
      };
      this.props.dispatch(sendFeedback(feedback));
      // reset state
      this.setState({ ...this.resetState() });
    }
  };
  setCheckboxValue(field, value) {
    const decheckedKey = field === 'customerService' ? 'techSupport' : 'customerService';
    this.setState({ [field]: value, [decheckedKey]: !value });
  }
  selectRestaurant = (event, data) => {
    const selectedRestaurantId = data.getVal();
    this.setState({ selectedRestaurant: selectedRestaurantId });
    if (selectedRestaurantId !== null) {
      let formErrors = { ...this.state.formErrors };
      formErrors.selectedRestaurant = null;
      this.setState({ formErrors });
    }
  };

  setValue(field, value) {
    this.setState({ [field]: value });
  }

  formatDataForSelect = (stores) => {
    const { __ } = this.props;
    let arrForSelect = [];
    stores.forEach((store) => {
      if (isDefined(store.is_published)) {
        if (store.is_published) {
          arrForSelect.push({ text: store.name, value: store.id });
        }
      }
    });
    return [{ text: __('Select Restaurant'), value: null }, ...arrForSelect];
  };

  handleChange = (event) => {
    this.setValue(event.detail.value, true);
    this.setState({
      radioGroup: event.detail.value,
    });
  };

  render() {
    const { __, isFeedbackModalOpen, restaurants } = this.props;
    const { techSupport, commentService, commentTech, formErrors, customerService } = this.state;
    const stores = restaurants.sort((a, b) => {
      if (isDefined(a.name) && isDefined(b.name)) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
      return 0;
    });

    return (
      <Loading transparent>
        <Layout color="transparent" hideSecondToolbar={true} headerTitle={__('Feedback')}>
          <div>
            <div className="heading-wrapper">
              <Title>{__('Leave feedback')}</Title>
            </div>
            <Spacer size={1} />
            <div className='flex-space-between tab-buttons'>
              <IonButton expand='block' fill={customerService ? 'solid' : 'outline'} color='secondary' onClick={() => this.setCheckboxValue('customerService', true)}>
                {__('Customer Service')}
              </IonButton>
              <IonButton expand='block' fill={techSupport ? 'solid' : 'outline'} color='secondary' onClick={() => this.setCheckboxValue('techSupport', true)}>
                {__('Tech Support')}
              </IonButton>
            </div>

            {techSupport ? (
              <div className="top-medium pad10 tech-box-holder">
                <Spacer size={1} />
                <StrongText className="feedback-label roboto-slab">{__('Comments')}</StrongText>
                <div>
                  <IonInput
                    className="thiner-text"
                    value={commentTech}
                    onIonChange={(event) => this.setValue('commentTech', event.detail.value)}
                  ></IonInput>
                </div>
                <br />
              </div>
            ) : (
              <>
                <Spacer size={1} />
                <StrongText className="feedback-label roboto-slab">{__('Comments')}</StrongText>
                <div className="commentService">
                  <IonInput
                    className="thiner-text"
                    value={commentService}
                    onIonChange={(event) => this.setValue('commentService', event.detail.value)}
                  ></IonInput>
                </div>

                <div className='select-location-wrapper dropdown-field'>
                  <StrongText
                    className={`select-picker-label`}
                    htmlFor="demo-non-form"
                  >
                    {__('Location')}
                  </StrongText>
                  <SelectOption
                    className="select-picker-input--feedback "
                    display="center"
                    onSet={(e, a) => this.selectRestaurant(e, a)}
                    data={this.formatDataForSelect(stores)}
                    label="Location"
                    value={this.state.selectedRestaurant}
                    inputStyle="box"
                    placeholder={__('Select Location')}
                    setText={__('OK')}
                    cancelText={__('Cancel')}
                  />
                </div>


                {formErrors.selectedRestaurant ? (
                  <FieldError className="field-error pad10" value={__(formErrors.selectedRestaurant)} />
                ) : null}
                <div>
                  <StrongText className="feedback-label roboto-slab">{__('Rate our food')}</StrongText>
                  <div className="flex-col-holder border-bottom-input">
                    {this.createStarSet(5, 'food')}
                  </div>
                </div>
                {this.state.formErrors.food ? (
                  <FieldError
                    className="field-error pad5l"
                    value={__(this.state.formErrors.food)}
                  />
                ) : null}

                <div>
                  <StrongText className="feedback-label roboto-slab">{__('Rate our service')}</StrongText>
                  <div className="flex-col-holder border-bottom-input">
                    {this.createStarSet(5, 'service')}
                  </div>
                </div>
                {this.state.formErrors.service ? (
                  <FieldError
                    className="field-error pad5l"
                    value={__(this.state.formErrors.service)}
                  />
                ) : null}
              </>
            )}
          </div>
          <IonButton className='submit-feadback-button' expand="block" color="secondary" onClick={this.handleFeedback}>
            {__('Submit')}
          </IonButton>
        </Layout>
        {isFeedbackModalOpen ? (
          <BottomAlert
            onClose={() => this.props.dispatch(setCommonModal('isFeedbackModalOpen', false))}
            title={__('Thanks')}
            description={__('We really appreciate you taking the time to let us know how we did.')}
          >
            <div className='bottom-alert-buttons'>
              <IonButton
                onClick={() => this.props.dispatch(setCommonModal('isFeedbackModalOpen', false))}
                className='inner-padding'
                expand="block"
                color='gray'
              >
                {__('Ok')}
              </IonButton>
            </div>
          </BottomAlert>
        ) : null}
      </Loading>
    );
  }
}
const stateToProps = (store) => {
  const { appVersion, isFeedbackModalOpen } = store.common;
  const { restaurants } = store.restaurants;
  return {
    appVersion,
    isFeedbackModalOpen,
    restaurants: restaurants || [],
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Feedback)));
