import { isWebConfig } from './lib/utils';
import Dashboard from './screens/dashboard';
import Loyalty from './screens/loyalty';
import Vouchers from './screens/vouchers';
import Account from './screens/account';
import ReferAFriend from './screens/referAFriend';
import Feedback from './screens/feedback';
import History from './screens/history';
import HistoryDetails from './screens/historyDetails';
import Locations from './screens/locations';
import Social from './screens/social';
import Login from './screens/login';
import Register from './screens/register';
import ResetPassword from './screens/resetPassword';
import Terms from './screens/terms';
import Privacy from './screens/privacy';
import Faq from './screens/faq';
// import Order from './screens/order'
import ItemDetails from './screens/itemDetails';
import DeliveryOptions from './screens/delivery-options';
import Delivery from './screens/delivery';
import DeliveryAddressCheck from './screens/delivery-address-check';
import DeliveryAddressAdd from './screens/delivery-address-add';
import DeliveryTime from './screens/delivery-time';
import PickUpPoint from './screens/pick-up-point';
import PickUpPointCheck from './screens/pick-up-point-check';
import ClickAndCollect from './screens/clickAndCollect';
import Cards from './screens/cards';
import CardAdd from './screens/cardAdd';
import OrderSummary from './screens/orderSummary';
import ApplyPoints from './screens/applyPoints';
import Checkout from './screens/checkout';
import OrderCompleted from './screens/orderCompleted';
import ContactDetails from './screens/contactDetails';
import OrderToTable from './screens/orderToTable';
import ApplyVouchers from './screens/applyVouchers';
import ApplyAllergens from './screens/allergens';
import { ServiceCharge } from './screens/service-charge';
import MyMembership from './screens/myMembership';
import MembershipCompleted from './screens/membershipCompleted';
import CheckoutMembership from './screens/checkoutMembership';
import CardAddMembersip from './screens/cardAddMembership';
import ChooseSubscriptionMenu from './screens/chooseSubscriptionMenu';
import ChooseSubscriptionType from './screens/chooseSubscriptionType';
import ChooseSubscription from './screens/chooseSubscription';
import TermsAndConditions from './screens/terms-subscription-package';
import { getConfig } from './appConfig';
import { getSingleDeliveryOption } from './lib/utils';
import ApplyLoyalty from './screens/applyLoyalty';
import api from './lib/api';
import ShareSubscription from './screens/shareSubscription';
import Inbox from './screens/inbox';
import home from './assets/images/home-icon.svg';
import loyalty from './assets/images/icons/loyalty.svg';
import myAccount from './assets/images/icons/user.svg';
import feedback from './assets/images/icons/feedback.svg';
import history from './assets/images/icons/history.svg';
import restaurants from './assets/images/icons/locations.svg';
import social from './assets/images/icons/social-media.svg';
import order from './assets/images/icons/order.svg';
import subscription from './assets/images/icons/club.svg';
import login from './assets/images/icons/logout.svg';
import logout from './assets/images/icons/logout.svg';
import settings from './assets/images/settings-icon.svg';
import referAFriendIcon from './assets/images/icons/refer.svg';
import menu from './assets/images/icons/menu.svg';
import vocuher from './assets/images/icons/vouchers.svg';
import inbox from './assets/images/icons/inbox.svg';
const Order = require('./screens/order' + (isWebConfig() ? 'Web' : '')).default;

let navConfig;

export const initRouter = async (config) => {
  const { appType, flags, membership } = config;
  const { hasCampaignManager, hasOrdering, hasLoyalty, hasMembership, referAFriend, hasInbox } = appType;
  const hasMultiPersonMembership = membership.hasMultiPersonMembership;
  const singleDeliveryOption = await getSingleDeliveryOption();
  navConfig = {
    routes: [
      isWebConfig()
        ? []
        : {
          label: 'Home',
          path: '/dashboard',
          component: Dashboard,
          icon: home,
          exact: true,
          default: true,
          notInDrawer: true
        },
      {
        label: 'Menu',
        path: '/order',
        component: Order,
        icon: menu,
        exact: true,
        default: false,
        notInDrawer: true
      },
      {
        label: 'Apply Loyalty',
        path: '/apply-loyalty',
        component: ApplyLoyalty,
        icon: menu,
        protected: true,
        notInDrawer: true

      },
      ...(hasOrdering
        ? [
          {
            label: 'Start New Order',
            path: '/delivery-options',
            component: DeliveryOptions,
            icon: order,
            protected: false,
            default: isWebConfig(),
            notInDrawer: !!singleDeliveryOption,
          },
        ]
        : []),
      ...(hasMembership ? [
        {
          label: 'Subscriptions',
          path: '/get-membership',
          component: ChooseSubscriptionMenu,
          icon: subscription,
          protected: true,
        },
        {
          label: 'Membership',
          path: '/membership',
          component: ChooseSubscription,
          icon: home,
          notInDrawer: true,
          protected: true,
        },
        {
          label: 'Share Membership',
          path: '/share-membership',
          component: ShareSubscription,
          icon: home,
          notInDrawer: true,
          protected: true,
        },
        hasMultiPersonMembership &&
          {
            label: 'Membership',
            path: '/membership-type',
            component: ChooseSubscriptionType,
            icon: home,
            notInDrawer: true,
            protected: true,
          }
      ]
        : []),
      {
        label: 'TermsAndConditions',
        path: '/membership-terms',
        component: TermsAndConditions,
        notInDrawer: true,
        protected: true,
      },
      {
        label: 'Checkout Membership',
        path: '/checkout-membership',
        component: CheckoutMembership,
        notInDrawer: true,
        protected: true,
      },
      {
        label: 'MyMembership',
        path: '/my-membership',
        component: MyMembership,
        notInDrawer: true,
        protected: true,
      },
      {
        label: 'Membership Completed',
        path: '/membership-completed',
        component: MembershipCompleted,
        notInDrawer: true,
        protected: true,
      },
      {
        label: 'Add card Membership',
        path: '/add-card-membership',
        component: CardAddMembersip,
        notInDrawer: true,
        protected: true,
      },
      ...(hasOrdering
        ? [
          {
            label: singleDeliveryOption ? singleDeliveryOption.label : '',
            path: '/click-and-collect',
            component: ClickAndCollect,
            icon: settings,
            protected: false,
            notInDrawer: !singleDeliveryOption,
          },
          {
            label: singleDeliveryOption ? singleDeliveryOption.label : '',
            path: '/order-to-table',
            component: OrderToTable,
            protected: false,
            notInDrawer: true,
          },
        ]
        : []),
      ...(hasLoyalty
        ? [
          {
            label: 'Loyalty',
            path: '/loyalty',
            component: Loyalty,
            icon: loyalty,
            protected: true,
            state: { tab: 'points' },
          },
        ]
        : []),
      ...(hasCampaignManager
        ? [
          {
            label: 'Vouchers',
            path: '/vouchers',
            component: Vouchers,
            icon: vocuher,
            protected: true,
          },
        ]
        : []),
      ...(referAFriend
        ? [
          {
            label: 'Refer a Friend',
            path: '/refer-a-friend',
            component: ReferAFriend,
            icon: referAFriendIcon,
            protected: true,
          },
        ]
        : []),
      { label: 'History', path: '/history', component: History, icon: history, protected: true },
      {
        label: 'Leave Feedback',
        path: '/feedback',
        component: Feedback,
        icon: feedback,
        protected: true,
      },
      {
        label: 'Locations',
        path: '/locations',
        component: Locations,
        icon: restaurants,
        protected: false,
      },
      ...(hasOrdering
        ? [
          {
            label: 'Checkout',
            path: '/checkout',
            component: Checkout,
            icon: settings,
            notInDrawer: true,
            protected: true,
          },
        ]
        : []),
      ...(hasOrdering
        ? [
          {
            label: 'Apply Points',
            path: '/apply-points',
            component: ApplyPoints,
            icon: settings,
            notInDrawer: true,
            protected: true,
          },
        ]
        : []),
      ...(hasOrdering
        ? [
          {
            label: 'Apply Points',
            path: '/allergens',
            component: ApplyAllergens,
            icon: settings,
            notInDrawer: true,
            protected: true,
          },
        ]
        : []),
      ...(hasOrdering
        ? [
          {
            label: 'Service Charge',
            path: '/service-charge',
            component: ServiceCharge,
            notInDrawer: true,
            protected: true,
          },
        ]
        : []),
      ...(hasOrdering
        ? [
          {
            label: 'Apply Points',
            path: '/allergens',
            component: ApplyAllergens,
            icon: settings,
            notInDrawer: true,
            protected: true,
          },
        ]
        : []),
      { label: 'Social Media', path: '/social', component: Social, icon: social, protected: false, notInDrawer: true },
      {
        label: 'Contact Details',
        path: '/contact-details',
        component: ContactDetails,
        protected: true,
        notInDrawer: true,
      },
      {
        label: 'Apply Vouchers',
        path: '/apply-vouchers',
        component: ApplyVouchers,
        notInDrawer: true,
        protected: true,
      },
      /* {
        label: 'Our Trees',
        path: 'https://ecologi.com/92degreesuk',
        icon: trees,
        protected: false,
        isLink: true,
      }, */
      { label: 'My Account', path: '/account', component: Account, icon: myAccount, protected: true , notInDrawer: true },
      ...(hasInbox ? [{label: 'Messages', path: '/inbox', component: Inbox,icon: inbox, protected: true }] : []),
    ],
    authRoutes: [
      { label: 'Login', path: '/login', component: Login, icon: logout, fn: 'login', notInDrawer: true },
      { label: 'Logout', path: '/logout', icon: login, fn: 'logout', notInDrawer: true },
      { path: '/contact-details', component: ContactDetails },
    ],
    additionalRoutes: [
      { label: 'Terms & Conditions', path: '/terms', component: Terms },
      { label: 'Privacy Policy', path: '/privacy', component: Privacy },
      { label: 'Faq & Support', path: '/faq', component: Faq },
    ],
    notInMenuRoutes: [
      { path: '/register', component: Register },
      { path: '/reset-password', component: ResetPassword },
      ...(hasOrdering ? [{ path: '/item-details', component: ItemDetails }] : []),
      ...(hasOrdering ? [{ path: '/order', component: Order }] : []),
      ...(hasOrdering ? [{ path: '/cards', component: Cards }] : []),
      ...(hasOrdering ? [{ path: '/card-add', component: CardAdd }] : []),
      ...(hasOrdering ? [{ path: '/history-details', component: HistoryDetails }] : []),
      ...(hasOrdering ? [{ path: '/order-summary', component: OrderSummary }] : []),
      ...(hasOrdering ? [{ path: '/order-completed', component: OrderCompleted }] : []),
      ...(hasOrdering ? [{ path: '/delivery', component: Delivery }] : []),
      ...(hasOrdering ? [{ path: '/delivery-address-check', component: DeliveryAddressCheck }] : []),
      ...(hasOrdering ? [{ path: '/delivery-address-add', component: DeliveryAddressAdd }] : []),
      ...(hasOrdering ? [{ path: '/delivery-time', component: DeliveryTime }] : []),
      ...(hasOrdering ? [{ path: '/pick-up-point', component: PickUpPoint }] : []),
      ...(hasOrdering ? [{ path: '/pick-up-point-check', component: PickUpPointCheck }] : []),
    ],
  };
  return navConfig;
};

export default navConfig;
