import React from 'react';
import { IonButton, /*, isPlatform */ IonIcon } from '@ionic/react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import Modal from '../../components/modal';
import { Title, Spacer, NormalText, SmallText, StrongText } from '../../components/common';
import { isDefined, formatNumber } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { forwardTo } from '../../lib/utils';
import QRCodeCanvas from 'qrcode.react';
import moment from '../../lib/moment';
import { lockClosed } from 'ionicons/icons';
import './index.css';
import plateImage from '../../assets/images/icons/eat.png'

const RewardItem = ({ reward, id, className, __, action, available_balance }) => {
  const rewardCard = (
    <>
      <div className='reward'>
        {available_balance < reward.cost ? (
          <div className="reward-cover">
            <IonIcon icon={lockClosed} color="white" />
          </div>
        ) : null}
        <div className='voucher-card-wrapper'>
          <div className='voucher-card-content'>
            <div className='voucher-title-wrapper'>
              <Title className='ellipsis'>{reward.name}</Title>
              <NormalText >{reward.small_print}</NormalText>
            </div>
            <img src={plateImage} alt=' ' />
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265 134.291">
            <g id="Group_2194" data-name="Group 2194" transform="translate(228 -3659)">
              <g id="Group_1988" data-name="Group 1988" transform="translate(-303 3140)">
                <g id="Group_1985" data-name="Group 1985" transform="translate(75 519)">
                  <path id="Path_5431" data-name="Path 5431" d="M340,532.429V519H75v13.491c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v9.076c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v9.076c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v9.076c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062V625.6c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v13.491H340V639.862a7.162,7.162,0,0,1,0-14.324v-8.953a7.162,7.162,0,0,1,0-14.324v-8.953a7.162,7.162,0,1,1,0-14.324V570.03a7.162,7.162,0,0,1,0-14.324v-8.953a7.162,7.162,0,1,1,0-14.324Z" transform="translate(-75 -519)" />
                </g>
                <g id="Group_1987" data-name="Group 1987" transform="translate(75 519)">
                  <g id="Group_1986" data-name="Group 1986" opacity="0.1">
                    <path id="Path_5432" data-name="Path 5432" d="M340,532.429V519H75v13.491c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v9.076c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v9.076c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v9.076c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062V625.6c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v13.491H340V639.862a7.162,7.162,0,0,1,0-14.324v-8.953a7.162,7.162,0,0,1,0-14.324v-8.953a7.162,7.162,0,1,1,0-14.324V570.03a7.162,7.162,0,0,1,0-14.324v-8.953a7.162,7.162,0,1,1,0-14.324Z" transform="translate(-75 -519)" />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        {action ?
          <div className="voucher-bar">
            <div className="ellipsis">
              <p className="ellipsis">{__(reward.name)}</p>
            </div>
            <div>
              {/* <IonButton fill="outline" size="small" onClick={() => action(id)}>{ __('View')}</IonButton> */}
              <IonButton color='secondary' size="small" onClick={() => action(id)}>
                {__('Redeem')}
                {__(` ${isDefined(reward?.cost) ? reward?.cost : reward?.reward?.cost} PTS`)}
              </IonButton>
            </div>
          </div>
          : null}


      </div>
    </>
  );

  return (
    <div className={'reward' + (className ? ' ' + className : '')}>
      <>{rewardCard}</>
    </div>
  );
};

class UnlockRewardItmes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReward: null,
    };
  }

  viewReward = (id) => {
    const { rewards } = this.props;
    this.setState({ selectedReward: rewards.find((r) => r.id === id) });
  };

  render() {
    const { __, qr_code, available_balance, rewards } = this.props;
    const { selectedReward } = this.state;
    return (
      <Loading transparent>
        <Layout color headerTitle={__('{Vouchers}')} scrollY={false} hideBack={true}>
          <div className="absolute-content flex-row-wrapper">
            <div className="scrollable-y">
              <div className="flex-min ion-text-center">
                <div className="big-number">{formatNumber(available_balance)}</div>
                <Title className="uppercase">{__('Loyalty Points')}</Title>

                <Spacer size={3} />
              </div>
              {(rewards || []).map((reward, index) => {
                return (
                  <RewardItem
                    key={'rw-' + index}
                    {...reward}
                    __={__}
                    available_balance={available_balance}
                    action={() => this.viewReward(reward.id)}
                  />
                );
              })}
              <Spacer size={2} />
            </div>
          </div>
        </Layout>

        <Modal
          className="reward-modal"
          isOpen={selectedReward !== null}
          onDidDismiss={() => this.setState({ selectedReward: null })}
          title={__('Voucher Info')}
        >
          {selectedReward?.reward || selectedReward?.reward?.reward ? (
            <div className="centered">

              <Spacer size={2} />
              <div className="reward-modal-content">
                {selectedReward?.reward?.name || selectedReward?.reward?.reward?.name ? (
                  <>
                    <Title>
                      {selectedReward?.reward?.name || selectedReward?.reward?.reward?.name}
                    </Title>
                    <Spacer />
                  </>
                ) : null}
                {selectedReward?.reward?.small_print ||
                  selectedReward?.reward.reward?.small_print ? (
                  <div>
                    <StrongText>
                      {selectedReward?.reward?.small_print ||
                        selectedReward?.reward?.reward?.small_print}
                    </StrongText>
                  </div>
                ) : null}
                {selectedReward?.reward?.expiry_date ||
                  selectedReward?.reward?.reward?.expiry_date ? (
                  <div>
                    <NormalText className="bold">
                      {__('Expires:')}{' '}
                      {moment(
                        selectedReward?.reward?.expiry_date ||
                        selectedReward?.reward?.reward?.expiry_date,
                        'YYYY/MM/DD',
                      ).format('DD/MM/YY')}
                    </NormalText>
                  </div>
                ) : null}
                <Spacer size={1} />
                <div onClick={() => forwardTo('/terms')}>
                  <StrongText >{__('Terms & Conditions')}</StrongText>
                </div>
                <Spacer size={1} />
                {selectedReward?.reward?.description ||
                  selectedReward?.reward?.reward?.description ? (
                  <div>
                    <SmallText>
                      {__(
                        selectedReward?.reward?.description ||
                        selectedReward?.reward?.reward?.description,
                      )}
                    </SmallText>
                  </div>
                ) : null}
                <>
                  <Spacer />
                  {isDefined(qr_code) ? (
                    <div className="qr-holder">
                      <QRCodeCanvas value={qr_code} size={200} />
                    </div>
                  ) : (
                    <div className="noQrCode">
                      <h5>{__('NO QR CODE')}</h5>
                    </div>
                  )}
                  <Spacer />
                </>
              </div>
            </div>
          ) : null}
        </Modal>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  return {
    vouchers: state.profile.vouchers || [],
    qr_code: state.profile.profile.qr_code,
  };
};

export default connect(stateToProps)(withTranslation(UnlockRewardItmes));
