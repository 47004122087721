import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { forwardTo } from '../../lib/utils';
import { setMenuSubscriptionFlow } from '../../store/subscription/actions';
import { setOrderTypeMembership } from '../../store/orders/actions';
import { getConfig } from '../../appConfig';

const ChooseSubscriptionMenu = ({
  active_subscription,
  setMenuSubscriptionFlow,
  setOrderTypeMembership,
}) => {
  const hasMultiPersonMembership = getConfig().membership.hasMultiPersonMembership;
  const disableBuyingMembership = getConfig().membership?.disableBuyingMembership;
  let page = '/membership';
  if(disableBuyingMembership){
    page = '/membership';
  }else if (active_subscription && active_subscription.id){
    page = '/my-membership';
  }else if (hasMultiPersonMembership){
    page = '/membership-type';
  }
  
  useEffect(() => {
    setMenuSubscriptionFlow();
    setOrderTypeMembership();
    forwardTo(page);
  }, [page]);
  return <></>;
};

const stateToProps = (store) => ({
  active_subscription: store.profile.profile.active_subscription,
});

const dispatchToProps = {
  setMenuSubscriptionFlow,
  setOrderTypeMembership,
};

export default connect(stateToProps, dispatchToProps)(ChooseSubscriptionMenu);
