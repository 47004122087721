import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { NormalText, SmallText, Spacer, StrongText, Subtitle, Title } from '../../components/common';
import { IonAlert, IonButton, IonItem, IonList } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import './index.css';
import {
  getSubscriptions,
  unsubscribe,
  reactivateSubscription,
  checkSubscriptionPin,
} from '../../store/subscription/actions';
import { sprintf, forwardTo, getDefaultRoute } from '../../lib/utils';
import Basket from '../../lib/basket';
import moment from 'moment';
import { getConfig } from '../../appConfig';
import { Capacitor } from '@capacitor/core';
import { showToast } from '../../store/actions';
import { Share } from '@capacitor/share';

const isWeb = () => Capacitor.getPlatform() === 'web';
const { formatPrice } = Basket;

const MyMembership = ({
  __,
  subscriptions,
  active_subscription,
  getSubscriptions,
  unsubscribe,
  reactivateSubscription,
  navConfig,
  profile,
  restaurants
}) => {
  useEffect(() => {
    getSubscriptions();
  }, []);
  const dispatch = useDispatch()
  const [showSwitchMembershipAlert, setShowSwitchMembershipAlert] = useState(false);
  const [showCancelMembershipAlert, setShowCancelMembershipAlert] = useState(false);
  const [showReactivatingMembershipAlert, setShowReactivatingMembershipAlert] = useState(false);
  const [showMembershipPinAlert, setShowMembershipPinAlert] = useState(false);
  const [pinIsValidAlert, setPinIsValidAlert] = useState(false);
  const [pinIsInvalidAlert, setPinIsInvalidAlert] = useState(false);
  const [showEnterPinAlert, setShowEnterPinAlert] = useState(false);

  const handleClick = (active_subscription) => () => {
    if (active_subscription.cancellation_pending) {
      setShowReactivatingMembershipAlert(true);
    } else {
      setShowCancelMembershipAlert(true);
    }
  };

  const cancelMembershipAction = () => {
    setShowCancelMembershipAlert(false);
    unsubscribe();
  };

  const reactivateMembershipAction = () => {
    setShowReactivatingMembershipAlert(false);
    reactivateSubscription();
  };

  const submitPin = (pin) => {
    if (pin !== '') {
      setPinIsValidAlert(false)
      dispatch(checkSubscriptionPin(pin, showPinStatusAlert));
    } else {
      dispatch(showToast(('Please enter valid Subscription PIN!'), 'warning'))
    }
  }

  const showPinStatusAlert = (status) => {
    if (status) {
      setPinIsValidAlert(true);
    } else {
      setPinIsInvalidAlert(true);
    }
  }

  const days_left = moment(active_subscription.next_renewal_date).diff(moment(), 'days');
  const backHandler = () => {
    const defaultRoute = getDefaultRoute(navConfig);
    forwardTo(defaultRoute.path);
  }
  const { flags, shareConfig, general } = getConfig();
  const subsriptionStartDate = moment(active_subscription.start_date);
  /* const treesPlantedMonthly = general.treesPlantedMonthly;
  const monthsDifference = moment().diff(subsriptionStartDate, 'months', false) + 1; */
  const homeLocation = restaurants.find( (restaurant)=> restaurant.id === active_subscription.home_location_id);
  return (
    <Loading transparent>
      <Layout backHandler={backHandler} hideSecondToolbar={true} color="transparent" headerTitle={__('My Membership')}>
        <div className="absolute-wrapper flex-row-wrapper scrollable-y">
          <div className="flex-row-wrapper my-membership-content">
            <div className="scrollable-y ">
              {active_subscription?.numberOfPeople > 1 ?
                (
                  <>
                    <Title>{__(`${active_subscription.numberOfPeople} Person Subscription`)}</Title>
                    <Spacer size={1} />
                    <Subtitle>{__('Join the exclusive 92° Club')}</Subtitle>
                  </>
                ) : (
                  <>
                    <Title>{__("My Subscription")}</Title>
                    <Spacer size={1} />
                    <Subtitle>{__(active_subscription.package)}</Subtitle>
                  </>
                )
              }
              {active_subscription?.description ? (
                <div
                  dangerouslySetInnerHTML={{ __html: active_subscription?.description }}
                />
              ) : null}
              <Spacer size={2} />
              <div className='subscription-price bold'>
                <Subtitle>{`${formatPrice(active_subscription?.package_cost / 100)} ${__('per month')}`}</Subtitle>
                {active_subscription.cancellation_pending ? (
                  <NormalText>{`${__('Plan will end on')}: ${active_subscription.next_renewal_date}`}</NormalText>
                ) : (
                  <>
                    <NormalText>{`${__('Next renews on')}: ${active_subscription.next_renewal_date}`}</NormalText>
                  </>
                )}
              </div>
              {active_subscription.home_location_id? (
                <>
                  <NormalText>{`${__('Your home location is')}:`}</NormalText>
                  <Spacer size={1} />
                  <StrongText>{homeLocation.name}</StrongText>
                </>
              ): null}
              {active_subscription.is_multi_users ? (
                <>
                  <div className='centered'>
                    <IonButton
                      onClick={() => forwardTo('/share-membership')}
                      expand="block"
                      color='primary'
                      className={'checkout-btn'}
                    >
                      {__('Share this subscription')}
                    </IonButton>
                    <IonButton
                      color='white'
                      className="underlined"
                      onClick={() => setShowMembershipPinAlert(true)}
                    >
                      {__('View Subscription PIN')}
                    </IonButton>
                  </div>
                  <div className='centered'>
                    <IonButton
                      color='white'
                      className={`underlined centered `}
                      onClick={() =>
                        setShowEnterPinAlert(true)
                      }
                    >
                      {__('Enter share PIN')}
                    </IonButton>
                  </div>
                  {
                    flags?.hasReferCode &&
                    (
                      <>
                        <Title>{__('Refer a Friend')}</Title>
                        <IonItem>
                          <Subtitle>{__('Your referral code:')} </Subtitle>
                          <Subtitle className='uppercase refer-code-text'>{profile?.referrer_code ? profile.referrer_code : ''}</Subtitle>
                        </IonItem>
                        <Spacer size={1} />
                        <div className="raf-info">
                          {__(
                            'Ask your friend to use your referral code when they sign up,and you will both receive your reward.',
                          )}
                          <Spacer size={2} />
                          {!isWeb() &&
                            <IonButton
                              expand="block"
                              color="secondary"
                              strong={true}
                              onClick={async () =>
                                await Share.share({
                                  title: __(shareConfig?.shareTitle || ''),
                                  text: `${__(shareConfig?.shareText || '')} ${__(profile?.referrer_code ? profile.referrer_code : '')}`,
                                  dialogTitle: __(shareConfig?.shareDialogTitle || ''),
                                  url: `${shareConfig?.shareUrl || ''}`
                                })
                              }
                            >
                              {__(shareConfig?.shareButtonText || '')}
                            </IonButton>
                          }
                        </div>
                      </>
                    )
                  }
                </>
              ) : null
              }
            </div>
            <div className="flex-min">
              <IonButton
                color='secondary'
                disabled={active_subscription === null}
                onClick={handleClick(active_subscription)}
                expand="block"
                fill='clear'
                className={
                  'cancel-subscription ' + (active_subscription.cancellation_pending ? 'greyed' : '')
                }
              >
                {active_subscription.cancellation_pending
                  ? __('Cancellation Pending - Reactivate?')
                  : __('Cancel my subscription')}
              </IonButton>
            </div>
          </div>
        </div>

        <IonAlert
          isOpen={showSwitchMembershipAlert}
          header={__('Confirm Change')}
          message={__(
            'Are you sure you wish to change your subscription? Your new package will start at the next scheduled renewal date.',
          )}
          buttons={[
            { text: __('Cancel'), handler: () => setShowSwitchMembershipAlert(false) },
            { text: __('OK'), handler: () => setShowSwitchMembershipAlert(false) },
          ]}
        />
        <IonAlert
          isOpen={showCancelMembershipAlert}
          header={__('Are you sure')}
          message={__('By cancelling your subscription it will not renew on the scheduled date.')}
          buttons={[
            { text: __('Back'), handler: () => setShowCancelMembershipAlert(false) },
            { text: __('OK'), handler: cancelMembershipAction },
          ]}
        />
        <IonAlert
          isOpen={showReactivatingMembershipAlert}
          header={__('Are you sure')}
          message={__(
            'By reactivating your subscription it will renew on the scheduled renewal date.',
          )}
          buttons={[
            { text: __('Back'), handler: () => setShowReactivatingMembershipAlert(false) },
            { text: __('OK'), handler: reactivateMembershipAction },
          ]}
        />
        <IonAlert
          isOpen={showMembershipPinAlert}
          onDidDismiss={() => setShowMembershipPinAlert(false)}
          header={__('Subscription Package PIN')}
          message={`
            <div>
              ${__('Invited users can use the following PIN to access the benefits of the subscription:')}
              <h2 class=class='alert-title'> ${active_subscription.subscription_code} </h2>
            </div>`}
          cssClass='membership-pin-alert'
          buttons={[
            {
              text: __('Share'),
              handler: () => forwardTo('/share-membership')
            },
            {
              text: __('OK'),
              handler: () => setShowMembershipPinAlert(false)
            }
          ]}
        />
        <IonAlert
          isOpen={showEnterPinAlert}
          onDidDismiss={() => setShowEnterPinAlert(false)}
          header={__('Enter Subscription PIN')}
          message={__('If you have been invited to join a shared subscription, enter the PIN below:')}
          cssClass='enter-pin-alert'
          inputs={[
            {
              placeholder: 'Enter PIN'
            }
          ]}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary'
            },
            {
              text: __('Submit'),
              role: 'submit',
              cssClass: 'secondary',
              disabled: true,
              handler: (pin) => {
                submitPin(pin['0'] || '');
              }
            },
          ]}
        />
        <IonAlert
          isOpen={pinIsValidAlert}
          header={__('Success')}
          message={__('Your PIN has been accepted. You will find your shared vouchers in your voucher wallet.')}
          buttons={[
            {
              text: __('Take me there'),
              role: 'submit',
              cssClass: 'secondary',
              handler: () => {
                forwardTo('/vouchers')
              }
            },
          ]}
        />
        <IonAlert
          isOpen={pinIsInvalidAlert}
          onDidDismiss={() => setPinIsInvalidAlert(false)}
          header={__('PIN Error')}
          message={__('The PIN is not recognised or your account in not authorised to use it.')}
          buttons={[
            {
              text: __('OK'),
              role: 'cancel',
              cssClass: 'secondary'
            }
          ]}
        />
      </Layout>
    </Loading>
  );
};

const mapStateToProps = (store) => ({
  subscriptions: store.subscription.subscriptions,
  active_subscription: store.profile.profile.active_subscription,
  navConfig: store.common.navConfig,
  profile: store.profile.profile,
  restaurants: store.restaurants.restaurants || [],
});

const mapDispatchToProps = {
  getSubscriptions,
  unsubscribe,
  reactivateSubscription,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(MyMembership));
