import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { IonAlert, IonButton, IonIcon, IonItem } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { getSubscriptions } from '../../store/subscription/actions';
import { setSelectedSubscription, setSubscriptionReferCode } from '../../store/orders/actions';
import { forwardTo, goBack, isWebConfig } from '../../lib/utils';
import { SubscriptionLine } from '../../components/subscription-item';
import { NormalText, Spacer, StrongText, Subtitle, Title } from '../../components/common';
import { getConfig } from '../../appConfig';
import { checkReferCode } from '../../store/actions';
import Mobiscroll from '../../components/mobiscroll';
import { chevronDownOutline } from 'ionicons/icons';
import Basket from '../../lib/basket';
import { useLocation } from 'react-router-dom';

const { SelectOption } = Mobiscroll;
const { formatPrice } = Basket;

const ChooseSubscription = ({
  __,
  subscriptions,
  selectedSubscription,
  activeSubscription,
  getSubscriptions,
  setSelectedSubscription,
  checkReferCode,
  setSubscriptionReferCode,
  profile,
  numberOfPeople,
  restaurants
}) => {
  const location= useLocation();
  const [referralCode, setReferralCode] = useState();
  const [allowedLocations, setAllowedLocations] = useState([]);
  const [selectedSubscriptionRaw, setSelectedSubscriptionRaw] = useState(null);
  const [homeLocationId, setHomeLocationId] = useState(selectedSubscription?.homeLocationId || null)
  const { hasReferCode } = getConfig().flags;
  const [invalidCodeAlert, setinvalidCodeAlert] = useState(false);
  const hasMultiPersonMembership = getConfig().membership?.hasMultiPersonMembership;
  const disableBuyingMembership = getConfig().membership?.disableBuyingMembership;

  useEffect(() => {
    getSubscriptions();
    if(location.state?.fromTerms && selectedSubscription && subscriptions){
      const rawSubscription = subscriptions.find( subscription => subscription.id === selectedSubscription.id)
      setSelectedSubscriptionRaw(rawSubscription);
    }
  }, []);

  useEffect(() => {
    if(selectedSubscriptionRaw && selectedSubscriptionRaw?.allowed_locations && selectedSubscriptionRaw.allowed_locations.length > 0){
      const filterdRestaurants = [
        {
          text: 'Select your home location',
          value: 0,
        }
      ];
      restaurants.map((restaurant) => {
        if (selectedSubscriptionRaw.allowed_locations.includes(restaurant.business_location_id)) {
          filterdRestaurants.push(
            {
              text: restaurant.name,
              value: restaurant.id,
            }
          );
        };
      });
      setAllowedLocations(filterdRestaurants);
    }
  }, [selectedSubscriptionRaw]);
  
  if (!disableBuyingMembership && activeSubscription && subscriptions.find(subscription => subscription.id === activeSubscription.id)) {
    forwardTo('/my-membership');
  }

  const items = subscriptions.map((e, idx) => {

    if (numberOfPeople === e.number_of_users) {
      const price = e.monthly_cost_inc_vat / 100;
      return SubscriptionLine(
        __,
        () => {
          setSelectedSubscription({ id: e.id, idx, numberOfPeople });
          setSelectedSubscriptionRaw(e);
        },
        idx,
        e.name,
        e.short_description,
        price,
      );
    }
  });

  const isCodeValidCb = (isValid = false) => {
    if (isValid) {
      setSubscriptionReferCode(referralCode);
      forwardTo('/membership-terms');
    } else {
      setinvalidCodeAlert(true);
    }

  }

  const handleMembership = () => {
    if (referralCode) {
      if (profile.referrer_code === referralCode) {
        setinvalidCodeAlert(true);
      } else {
        checkReferCode(referralCode, isCodeValidCb);
      }
    } else {
      forwardTo('/membership-terms');
    }
  };

  const backHandler = () => {
    setSelectedSubscription(null);
    setSelectedSubscriptionRaw(null);
  }

  return (
    <Loading transparent>
      <Layout backHandler={backHandler} color='transparent' scrollY={false} headerTitle={__('MembershipChooser')} hideSecondToolbar={true} showHamburger={!selectedSubscription}>
        <div className='absolute-content scrollable-y'>
          <div className='subscription-content'>
            {
              disableBuyingMembership ? (
                <div className='subscription-disabled-wrapper'>
                  <Title>{__("92° Subscription")}</Title>
                  <StrongText>{__('Join the exclusive 92° Club')}</StrongText>
                  <div className='subscription-disabled-div'>
                    <Subtitle className='bold' >
                      {__("We aren't currently accepting new subscribers")}
                    </Subtitle>

                    <Subtitle className={`subscription-disabled-message${isWebConfig() && '-web'} regular-text`} color='light'>
                      {__("Please keep an eye on your inbox for updates and don't forget the other awesome features of your 92° App")}
                    </Subtitle>
                    <Spacer size={1} />
                  </div>

                </div>
              ) : selectedSubscriptionRaw ?
                (
                  <>
                    {numberOfPeople > 1 ?
                      (
                        <>
                          <Title>{__(`${numberOfPeople} Person Subscription`)}</Title>
                          <Spacer size={1} />
                          <Subtitle>{__('Join the exclusive 92° Club')}</Subtitle>
                        </>
                      ) : (
                        <>
                          <Title>{__("Subscribe")}</Title>
                          <Spacer size={1} />
                          <Subtitle>{selectedSubscriptionRaw.name}</Subtitle>
                        </>
                      )
                    }
                    {selectedSubscriptionRaw?.description ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: selectedSubscriptionRaw?.description }}
                      />
                    ) : null}
                    <Spacer size={2} />
                    <div className='subscription-price'>
                      <Subtitle>{`${formatPrice(selectedSubscriptionRaw?.monthly_cost_inc_vat / 100)} ${__('per month')}`}</Subtitle>
                      <NormalText>{__('Cancel at any time')}</NormalText>
                    </div>
                    {/* {hasReferCode &&
                        <IonItem lines="none" className='subscription-referral-input'>
                          <IonLabel className='lefted' position="floating">
                            {__('Enter Referral Code (optional)')}
                          </IonLabel>
                          <IonInput
                            className='lefted subscription-referral-input uppercase'
                            onIonChange={(e) => {
                              let refCode = e.target.value || '';
                              setReferralCode(refCode.toUpperCase())
                            }}
                            required={false}
                            type="text"
                            pattern="text"
                            inputmode="text"
                            lines="none"
                            value={referralCode}
                          ></IonInput>
                        </IonItem>
                      } */}
                    {allowedLocations.length > 0 ? (
                      <>
                        <NormalText>{__("Home location")}</NormalText>
                        <IonItem>
                          <SelectOption
                            className=""
                            display="center"
                            onSet={(value, selectedData) => {
                              setHomeLocationId(selectedData.getVal());
                              setSelectedSubscription({ ...selectedSubscription, homeLocationId: selectedData.getVal() });
                            }}
                            data={allowedLocations}
                            label="Location"
                            value={homeLocationId}
                            inputStyle="box"
                            placeholder={__('Select your home location')}
                            setText={__('OK')}
                            cancelText={__('Cancel')}
                          />
                          <IonIcon slot="end" icon={chevronDownOutline} />
                        </IonItem>
                        <Spacer size={2}/>
                      </>
                    ) : null}
                    <IonButton
                      disabled={selectedSubscription === null || (allowedLocations.length > 0 && !homeLocationId)}
                      onClick={() => handleMembership()}
                      expand="block"
                      color='primary'
                      className={'checkout-btn'}
                    >
                      {__('Continue')}
                    </IonButton>
                  </>
                ) : (
                  <>
                    {numberOfPeople > 1 ?
                      (
                        <Title>{__(`${numberOfPeople} Person Subscription`)}</Title>
                      ) : (
                        <Title>{__("Subscribe")}</Title>
                      )
                    }
                    <Spacer size={1} />
                    <NormalText>{__('Select a subscription plan from the options below:')}</NormalText>
                    <Spacer size={3} />
                    <div className='subscription-list'>
                      {items}
                    </div>
                  </>
                )
            }
          </div>
        </div>
        <IonAlert
          isOpen={invalidCodeAlert}
          onDidDismiss={() => setinvalidCodeAlert(false)}
          header={__('Referral Code invalid')}
          message={__('Sorry the code you entered is not valid. You must not have used a referral code in the past.')}
          buttons={[
            {
              text: __('Ok'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => setinvalidCodeAlert(false),
            },
          ]}
        />
      </Layout>
    </Loading>
  );
};

const mapStateToProps = (store) => ({
  subscriptions: store.subscription.subscriptions,
  selectedSubscription: store.orders.selectedSubscription,
  activeSubscription: store.profile.profile.active_subscription,
  profile: store.profile.profile,
  numberOfPeople: store.subscription.numberOfPeople || 1,
  restaurants: store.restaurants.restaurants || [],
});

const mapDispatchToProps = {
  setSelectedSubscription,
  getSubscriptions,
  checkReferCode,
  setSubscriptionReferCode
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(ChooseSubscription));
