import React from 'react';
import MapContainer from '../../components/map';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { withRouter } from 'react-router';
import './index.css';

const Location = ({ __ }) => (
  <Layout hideSecondToolbar={true} headerTitle={__('Location')} scrollY={false} contentClassName="map-page" noPadding={true} color='transparent'>
    <MapContainer />
  </Layout>
);
export default withRouter(withTranslation(Location));
