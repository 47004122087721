import React from 'react';
import {
  IonButton, IonItem, IonInput, /*, isPlatform */
  IonText,
  IonLabel
} from '@ionic/react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import Clickup from '../../components/clickup';
import Modal from '../../components/modal';
import { Title, Spacer, NormalText, SmallText, FieldError, StrongText, Subtitle } from '../../components/common';
import { validateForm, isDefined, isEmptyObject, decodeBase64 } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { getVouchers, sendVoucherCode } from '../../store/actions';
import { forwardTo } from '../../lib/utils';
import QRCodeCanvas from 'qrcode.react';
import moment from '../../lib/moment';
import './index.css';
import BottomAlert from '../../components/bottomAlert';

const VoucherItem = ({ reward, id, className, __, action }) => {
  return (
    <div
      className={' box-wrapper-black flex-space-between voucher' + (className ? ' ' + className : '')}
    >
      <div>
        <Title>{reward.name}</Title>
        <NormalText>{reward.small_print}</NormalText>
      </div>
      <NormalText className='underlined' onClick={action ? () => action(id) : null} >{__('View')}</NormalText>

    </div>
  );
};
class Vouchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      voucherCode: '',
      selectedVoucher: null,
      isRedeemCodeModalOpen: false,
      formErrors: {
        voucherCode: '',
      },
    };
    this.formConfig = {
      voucherCode: { type: 'text', required: true },
    };
  }

  componentDidMount() {
    if (
      isDefined(this.props.location.state) &&
      isDefined(this.props.location.state.openVoucherModal)
    ) {
      setTimeout(() => {
        this.viewVoucher(this.props.location.state.openVoucherModal);
      }, 500);
    }
  }

  viewVoucher = (id) => {
    const { vouchers } = this.props;
    this.setState({ selectedVoucher: vouchers.find((v) => v.id === id) });
  };

  redeemVoucher = () => {
    const { voucherCode } = this.state;
    if (voucherCode !== '') {
      //console.log('Redeem voucher, code: "' + this.state.voucherCode + '"')
    }
  };

  clearFields = () => {
    this.setState({ voucherCode: '' });
  };

  sendCode = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { voucherCode } = this.state;
      const data = { code: voucherCode.toUpperCase() };
      this.clearFields();
      dispatch(sendVoucherCode(data));
    }
  };

  handlerOfVoucherCode = (e) => {
    const val = e.target.value.toUpperCase();
    this.setState({ voucherCode: val });
  };
  onRefreshHandler = (e) => {
    setTimeout(() => {
      this.props.dispatch(getVouchers())

      e.target.complete();
    }, 2000);
  }
  render() {
    const { __, vouchers, qr_code } = this.props;
    const { voucherCode, selectedVoucher, isRedeemCodeModalOpen } = this.state;
    let voucherRes = (vouchers || []).filter((data) => {
      if (data.type === 1 && data.subscription_code === null) {
        return data;
      }
    });
    let vouchersFromSubscription = {};
    (vouchers || []).forEach((data) => {
      if (data.type === 1 && data.subscription_code) {
        if (!vouchersFromSubscription[data.subscription_code]) {
          vouchersFromSubscription[data.subscription_code] = [];
        }
        vouchersFromSubscription[data.subscription_code].push(data);
      }
    });
    return (
      <Loading transparent>
        <Layout withRefresher={false} onRefreshHandler={this.onRefreshHandler} color='transparent' hideSecondToolbar={true} headerTitle={__('Vouchers')} scrollY={false}>
          <div className="absolute-content flex-row-wrapper">
            <div className="flex-min">
              <Title>{__('Voucher Wallet')}</Title>
            </div>
            <div className="scrollable-y">
              {isEmptyObject(vouchersFromSubscription) && (!voucherRes || voucherRes.length === 0) ? (
                <Subtitle color="gray">{__("Your vouchers will appear here")}</Subtitle>
              ) : null}
              {voucherRes &&
                voucherRes.map((voucher, index) => {
                  return (
                    <VoucherItem
                      key={'vc-' + index}
                      {...voucher}
                      __={__}
                      action={this.viewVoucher}
                    />
                  );
                })}
              {!isEmptyObject(vouchersFromSubscription) && Object.entries(vouchersFromSubscription).map((vouchers, index) => {
                const [key, value] = vouchers;
                return (
                  <div key={index}>
                    <Title className='centered'>{__(`Your Shared Vouchers - PIN ${key}`)}</Title>
                    {
                      value.map((voucher, index) => {
                        return (
                          <VoucherItem
                            key={'vc-' + index}
                            {...voucher}
                            __={__}
                            action={this.viewVoucher}
                          />
                        );
                      })
                    }
                  </div>
                )
              })}
              <Spacer size={2} />
            </div>
          </div>
          {isRedeemCodeModalOpen ? (
            <BottomAlert
              onClose={() => this.setState({ isRedeemCodeModalOpen: false })}
              smallTitle={__('Redeem Code')}
              description={__('Enter a valid code below to receive your reward')}
            >
              <IonItem lines="none" className='voucher-code-input-wrapper'>
                <IonLabel position="floating">{__('Offer Code')}</IonLabel>
                <IonInput
                  className='voucher-code-input'
                  value={voucherCode}
                  onIonChange={(e) => this.handlerOfVoucherCode(e)}
                  placeholder='Enter the code'
                ></IonInput>
              </IonItem>
              <Spacer size={1} />
              <div className='bottom-alert-buttons'>
                <IonButton
                  className='inner-padding'
                  expand="block"
                  color='white'
                  disabled={voucherCode === ''}
                  onClick={this.sendCode}
                >
                  {__('Submit')}
                </IonButton>
              </div>
            </BottomAlert>
          ) : null}
          {selectedVoucher ? (
            <BottomAlert
              onClose={() => this.setState({ selectedVoucher: null })}
              showXButton={true}
            >
              <Title className='selected-voucher-title'>
                {selectedVoucher.reward.name}
                <NormalText>{selectedVoucher.reward.small_print}</NormalText>
              </Title>
              <Spacer size={1} />
              <>
                {isDefined(qr_code) ? (
                  <div className="qr-holder">
                    <QRCodeCanvas value={qr_code} size={150} />
                  </div>
                ) : (
                  <div className="noQrCode">
                    <h5>{__('NO QR CODE')}</h5>
                  </div>
                )}
              </>
              <Spacer size={1} />
              {selectedVoucher.reward.expiry_date ? (
                <div>
                  <NormalText>
                    {__('Expiry Date:')}{' '}
                    {moment(selectedVoucher.reward.expiry_date, 'YYYY/MM/DD').format('DD/MM/YY')}
                  </NormalText>
                </div>
              ) : null}
              <Spacer size={2} />
              {selectedVoucher.reward.description ? (
                <div dangerouslySetInnerHTML={{ __html: decodeBase64(selectedVoucher.reward.description) }}>
                </div>
              ) : null}
            </BottomAlert>
          ) : null}
          <IonButton className='bottom-button' color='secondary' onClick={() => this.setState({ isRedeemCodeModalOpen: true })}>
            {__('Redeem Code')}
          </IonButton>
        </Layout>


      </Loading>
    );
  }
}

const stateToProps = (state) => {
  return {
    vouchers: state.profile.vouchers || [],
    qr_code: state.profile.profile.qr_code,
  };
};

export default connect(stateToProps)(withTranslation(Vouchers));
