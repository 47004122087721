import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { getSubscriptions } from '../../store/subscription/actions';
import { setSelectedSubscription, setSubscriptionReferCode } from '../../store/orders/actions';
import { checkReferCode, showToast } from '../../store/actions';
import { useDispatch } from "react-redux";
import { IonButton, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonRow } from '@ionic/react';
import { NormalText, Spacer, Title } from '../../components/common';
import { INVITE_USER, RESEND_SUBSCRIPTION_INVITE_EMAIL } from '../../store/constants';
import moment from 'moment';
import { trashOutline } from 'ionicons/icons';
import user from '../../assets/images/icons/user.svg';

const DATE_FORMAT = 'DD/MM/YYYY';

const ShareSubscription = ({
  __,
  subscriptions,
  activeSubscription,
  getSubscriptions,
  profile
}) => {
  useEffect(() => {
    getSubscriptions();
  }, []);
  const dispatch = useDispatch()
  const [email, setEmail] = useState('');
  const linkedUsers = activeSubscription?.linked_users || [];
  const numberOfUsers = Number(activeSubscription?.number_of_users || 0) - 1;
  const limitOfUsersIsReached = linkedUsers.length >= numberOfUsers;
  return (
    <Loading transparent>
      <Layout color='transparent' scrollY={false} headerTitle={__('Share Subscription')} hideSecondToolbar={true} >
        <div className='absolute-content wrapper '>
          <div>
            <Title>{__('Share Subscription')}</Title>
            <NormalText>
              {__('This package can be accessed by up to')}
            </NormalText>
            <NormalText tag="strong" className="bold mr-5">
              {` ${activeSubscription.number_of_users} ${__('people')}`}
            </NormalText>
          </div>
          <div className='middle scrollable-y'>
            <IonRow className='flex-list invite-row'>
              <div className='flex-list'>
                <IonIcon
                  icon={user}
                  size="large"
                  color="secondary"
                  className='user-icon'
                />
                <div className='invite-informations'>
                  <NormalText>
                    {profile.email}
                  </NormalText>
                  <NormalText className='success-text'>
                    {__('Me')}
                  </NormalText>
                </div>
              </div>
              <div className='invite-buttons'>
                <IonButton
                  color='success'
                >
                  {__('Account holder')}
                </IonButton>
              </div>
            </IonRow>
            {linkedUsers.map((invitedUser, index) => (
              <IonItemSliding className='invite-row' key={index}>
                <IonItem key={index} lines="none" color='white' >
                  <div className='flex-list invite-row'>
                    <div className='flex-list' >
                      <div>
                        <IonIcon
                          icon={user}
                          size="large"
                          color="secondary"
                          className='user-icon'
                        />
                      </div>
                      <div className='invite-informations'>
                        <NormalText>
                          {invitedUser.invited_user_email}
                        </NormalText>
                        {invitedUser.is_accepted ?
                          (
                            <NormalText className='success-text' >
                              {__('Active')}
                            </NormalText>
                          ) : (
                            <NormalText>
                              {`${__('Date invited')}: ${moment(invitedUser.invited_date).format(DATE_FORMAT)}`}
                            </NormalText>
                          )
                        }
                      </div>
                    </div>
                    <div className='invite-buttons'>
                      {invitedUser.is_accepted ? (
                        <IonIcon 
                            color='danger'
                            onClick={() => dispatch({ type: INVITE_USER, value: invitedUser.invited_user_email, remove_user: true })}
                            icon={trashOutline} />
                      ) : (
                        <IonButton
                          onClick={() => dispatch({ type: RESEND_SUBSCRIPTION_INVITE_EMAIL, value: invitedUser.invited_user_email })}
                        >
                          {__('Resend invite')}
                        </IonButton>
                      )}
                    </div>
                  </div>
                </IonItem>
                <IonItemOptions side="end">
                  <div className='flex-list invite-buttons '>
                    <IonButton
                      color='danger'
                      //disabled={invitedUser.is_accepted}
                      onClick={() => dispatch({ type: INVITE_USER, value: invitedUser.invited_user_email, remove_user: true })}
                    >
                      {__('Delete')}
                    </IonButton>
                  </div>
                </IonItemOptions>
              </IonItemSliding>
            ))}
          </div>
          <div>
            <Spacer />
            <IonItem>
              <IonInput
                onIonChange={(e) => setEmail(e.target.value)}
                clearInput
                required={true}
                type="email"
                pattern="email"
                inputmode="email"
                value={email}
                placeholder={__('Email address')}
              />
            </IonItem>
            <Spacer />
            <IonButton
              disabled={email === '' || limitOfUsersIsReached}
              onClick={() => {
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                  dispatch(showToast(__('Email is not valid!'), 'warning'));
                }else{
                  dispatch({ type: INVITE_USER, value: email });
                }
              }}
              expand="block"
              color='primary'
              className={'checkout-btn'}
            >
              {__('Send Invite')}
            </IonButton>
            <div className='centered'>
              <Spacer />
              <NormalText>
                {`${__("Invited users can use the following PIN to access the benefits of the subscription:")}`}
              </NormalText>
              <NormalText tag="strong" className="bold mr-5">
                {` ${activeSubscription.subscription_code}`}
              </NormalText>
            </div>
            {limitOfUsersIsReached &&
              <div className='centered'>
                <Spacer />
                <NormalText 
                  className='danger-color ion-padding'
                >
                  {__("You've reached your user limit")}
                </NormalText>
              </div>
            }
          </div>

        </div>
      </Layout>
    </Loading>
  );
};

const mapStateToProps = (store) => ({
  subscriptions: store.subscription.subscriptions,
  selectedSubscription: store.orders.selectedSubscription,
  activeSubscription: store.profile.profile.active_subscription,
  profile: store.profile.profile
});

const mapDispatchToProps = {
  setSelectedSubscription,
  getSubscriptions,
  checkReferCode,
  setSubscriptionReferCode
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(ShareSubscription));
