import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { ApplyPoints } from '../applyPoints';
import ApplyVouchers from '../applyVouchers';
import { IonButton } from '@ionic/react';
import './index.css';

class ApplyLoyalty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPoints: true
    };
  }
  render() {
    const { __ } = this.props;
    const { showPoints } = this.state;

    return (
      <Layout headerTitle={__('History')} hideSecondToolbar={true} color="transparent" scrollY={false}>
        <div className="absolute-content">
          <div className='flex-space-between tab-buttons'>
            <IonButton expand='block' fill={showPoints ? 'solid' : 'outline'} color='secondary' className='inner-padding' onClick={() => this.setState({ showPoints: true })}>
              {__('Points')}
            </IonButton>
            <IonButton expand='block' fill={!showPoints ? 'solid' : 'outline'} color='secondary' className='inner-padding' onClick={() => this.setState({ showPoints: false })}>
              {__('Vouchers')}
            </IonButton>
          </div>

          <div className='apply-loyalty-wrapper'>
            {showPoints ? (
              <ApplyPoints />
            ) : (
              <ApplyVouchers />
            )
            }
          </div>
        </div>
      </Layout>
    );

  }
}

const stateToProps = (state) => {
  return {
    transactionHistory: state.orders.history || [],
    orderHistory: state.orders.orderHistory || [],
  };
};

export default withRouter(withTranslation(connect(stateToProps)(ApplyLoyalty)));
