import React from 'react';
import { withRouter } from 'react-router';
import {
  IonContent,
  IonRefresher,
  IonRefresherContent,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { getConfig } from '../../appConfig';
import Header from '../../components/header';
import { getRouteClassName, isWebConfig } from '../../lib/utils';
import './index.css';
import StaticHeader from '../staticHeader';

const defaultHeaderTitle = getConfig().general.clientName;

const Layout = ({
  history,
  children,
  headerTitle,
  hideSecondToolbar,
  hideBack,
  color,
  poster,
  blank,
  noPadding,
  contentClassName,
  scrollY,
  backHandler,
  navConfig,
  withRefresher,
  onRefreshHandler,
  hasLoyaltyPoints,
  showHamburger,
  hideRightButton
}) => {
  useIonViewWillEnter(() => {
    // eslint-disable-next-line no-console
    console.log('1. WillEnter event fired');
  });
  useIonViewDidEnter(() => {
    // eslint-disable-next-line no-console
    console.log('2. DidEnter event fired');
  });
  useIonViewWillLeave((a, b, c) => {
    // eslint-disable-next-line no-console
    console.log('3. WillLeave event fired', a, b, c);
  });
  useIonViewDidLeave((a, b, c) => {
    // eslint-disable-next-line no-console
    console.log('4. DidLeave event fired', a, b, c);
  });
  const routeClassName = getRouteClassName(history.location.pathname, navConfig);
  return (
    <>

      {blank ? null : (
        <>
          {hideSecondToolbar ?
            <StaticHeader hasLoyaltyPoints={hasLoyaltyPoints} backHandler={backHandler} showHamburger={showHamburger} hideRightButton={hideRightButton} />
            : (
              <>
                <StaticHeader
                  hasLoyaltyPoints={hasLoyaltyPoints}
                  title={headerTitle || defaultHeaderTitle}
                  hideBack={hideBack}
                  backHandler={backHandler}
                  showHamburger={showHamburger}
                  hideRightButton={hideRightButton}
                />
                <Header
                  title={headerTitle || defaultHeaderTitle}
                  hideBack={hideBack}
                  backHandler={backHandler}
                />
              </>
            )}
        </>
      )}
      <IonContent
        scrollY={scrollY !== undefined ? scrollY : true}
        color={color || 'white'}
        className={routeClassName + (contentClassName ? ' ' + contentClassName : '')}
      >
        {(withRefresher && !isWebConfig()) &&
          <IonRefresher pullFactor='0.8' onIonRefresh={(e) => onRefreshHandler(e)} slot='fixed'>
            <IonRefresherContent pullingIcon='chevron-down-circle-outline' ></IonRefresherContent>
          </IonRefresher>
        }
        {poster ? <div className={'poster ' + poster} /> : null}
        <div className={noPadding ? 'no-padding' : 'ion-padding'}>
          {(withRefresher && isWebConfig()) &&
            <IonRefresher pullFactor='0.8' onIonRefresh={(e) => onRefreshHandler(e)} slot='fixed'>
              <IonRefresherContent {...(!isWebConfig() ? { pullingIcon: 'chevron-down-circle-outline' } : {})} ></IonRefresherContent>
            </IonRefresher>
          }
          {children}</div>
      </IonContent>
    </>
  );
};

export default withRouter(Layout);
