import React from 'react';
import { IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent, IonButton } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import { withRouter } from 'react-router';
// import { arrowForward } from 'ionicons/icons'
import { NormalText, SmallText, Spacer, StrongText, Subtitle, Title } from '../../components/common';
import NoDataSecond from '../../components/noDataSecond';
import Basket from '../../lib/basket';
import { getConfig } from '../../appConfig';
import { forwardTo, isDefined } from '../../lib/utils';
import moment from 'moment';
import NoData from '../../components/noData';

const doRefresh = (event, getTransactionHistory) => {
  setTimeout(() => {
    event.detail.complete();
    getTransactionHistory();
  }, 1000);
};

const getDeliveryLabel = (deliveryLabel) => {
  switch (deliveryLabel) {
    case 'Scheduled Delivery':
      return 'Delivery Order';
    case 'Order for Delivery':
      return 'Delivery Order';
    case 'Click & Collect':
      return 'Click And Collect Order';
    default:
      return deliveryLabel;
  }
};
const orderAgain = (past_orders, option) => {
  // only for order to table we need to check if it is the same day
  const offset = moment().utcOffset()
  const createdDate = moment(past_orders.created_at).utcOffset(offset)
  const sameDay = moment(createdDate.format('Y-M-D')).isSame(moment().format('Y-M-D'))

  Basket.reset();
  Basket.setDeliveryOption(option);
  forwardTo(option.route, { selectedRestaurant: past_orders, sameDay });
};
const HistoryTab = withRouter(
  ({ __, transactionHistory, getTransactionHistory, history, ...rest }) => {
    const type = rest.type || 'order';

    const handleRowClick = (item) => {
      if (type === 'order') {
        history.push('./history-details', { order: item });
      } else {
        // history.push('./history-details')
      }
    };
    const delivery = isDefined(getConfig().delivery) ? getConfig().delivery : [];

    return (
      <>
        <IonRefresher slot="fixed" onIonRefresh={(e) => doRefresh(e, getTransactionHistory)}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <Spacer size={1}/>
        <div className="history-content">
          {(transactionHistory || []).length === 0 ? (
            <NormalText>{__("Looks like you haven't earned any loyalty yet. Once you do updates will appear here.")}</NormalText>
          ) : (
            <IonGrid>
              {transactionHistory.map((i, index) => {
                const { stamp_power, transaction_date, location_name, label, item } = i;
                // const splitedLabel = label.split('#').splice(1).join('')
                const labelLocationName = label.split('#').splice(0).join('');
                let orderLabel = Basket.getOrderType(i.item);
                let option = (delivery || []).find((d) => d.id.includes(orderLabel));
                let status = item && item.status ? item.status : '';
                let orderId = i && i.item ? ' #' + i.item.id : '';
                const itemDateFormat = "DD MMMM YYYY [at] hh:mm a";
                return status === 'CREATED' ||
                  status === 'created' ||
                  status === 'new' ||
                  status === 'NEW' ? null : (
                  <IonRow key={index} className="history-item box-wrapper">
                    <IonCol size={type === 'order' ? '8' : '6'} onClick={() => handleRowClick(item)}>
                      <NormalText
                        color="primary"
                        className={`ellipsis block ${type === 'order' && 'order-location-name'
                          } history-small-text `}
                      >
                        {type === 'order' ? labelLocationName : location_name}
                      </NormalText>
                      <Subtitle className=''>
                        {type === 'order' ? (option ? getDeliveryLabel(option.label): '') : __(label)}
                      </Subtitle>
                      <NormalText color="primary" className="history-small-text">
                        {item
                          ? Basket.getDate(transaction_date, itemDateFormat).format('DD MMM YYYY h:mm a')
                          : Basket.getDate(transaction_date).format('DD MMM YYYY h:mm a')}
                      </NormalText>
                    </IonCol>
                    {type === 'order' ? (
                      option ?
                        <IonCol
                          style={{ alignSelf: 'end', textAlign: 'end' }}
                          className="order-button-col-width"
                        >
                          <NormalText className='underlined' onClick={() => orderAgain(item, option)} >{__('Reorder')}</NormalText>
                        </IonCol>
                        :
                        null
                    ) : (
                      <IonCol style={{ alignSelf: 'end', textAlign: 'end' }}>
                        <Title className='order-earned-points'>
                          {stamp_power < 0 ? '' : '+'}
                          {stamp_power} {__('PTS')}
                        </Title>
                      </IonCol>
                    )}
                  </IonRow>
                );
              })}
            </IonGrid>
          )}
        </div>
      </>
    );
  },
);

export default withTranslation(HistoryTab);
